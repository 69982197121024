import React, { useContext, useState, useEffect } from "react";
import { AccountContext } from "../events/Account";
import FieldCard from "./FieldCard";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DeviceCard from "./DeviceCard";
import NoDrawn from "../assets/nodrawn.svg";
import { Link } from "react-router-dom";
import "./Fields.css";
import { useTranslation } from "react-i18next";
import { AppContext } from "../pages/Layout";

function Fields() {
  const { t, i18n } = useTranslation();
  const { apiKey, user_id } = useContext(AccountContext);
  const { fieldsApp, setFieldsApp, aclFieldsApp, setACLFieldsApp } =
    useContext(AppContext);
  const [fields, setFields] = useState([]);
  const [ACLFields, setACLFields] = useState([]);
  const [userACL, setUserACL] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lonelyDevices, setLonelyDevices] = useState([]);
  const [offset, setOffset] = useState(0);

  const [filteredFields, setFilteredFields] = useState([]);
  const [filterType, setFilterType] = useState(""); // "noDevice", "refill", "full"
  const [searchTerm, setSearchTerm] = useState("");
  const clearFilters = () => {
    setFilterType("");
    setSearchTerm("");
  };
  useEffect(() => {
    const filterFields = () => {
      let filtered = fields;

      // Cihaz durumuna göre filtreleme
      if (filterType) {
        if (filterType === "noDevice") {
          filtered = filtered.filter(
            (field) => !field.devices || field.devices.length === 0
          );
        } else if (filterType === "refill") {
          filtered = filtered.filter((field) => field.status);
        } else if (filterType === "full") {
          filtered = filtered.filter((field) => field.status);
        }
      }

      // İsme göre filtreleme
      if (searchTerm) {
        filtered = filtered.filter((field) =>
          field.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      setFilteredFields((prev) => filtered);
    };

    filterFields();
  }, [fields, filterType, searchTerm]);

  useEffect(() => {
    const element = document.getElementById("myElement13");
    let pos = element.offsetTop;
    const onScroll = () => setOffset(pos);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);

      const requestOptionsGET = {
        method: "GET",
        headers: myHeaders,
      };

      // Kullanıcı verilerini alma
      const userResponse = await fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
        requestOptionsGET
      );

      const userData = await userResponse.json();
      console.log("userData", userData);
      const alldevice = userData.body.devices && userData.body.devices;
      const activeFields = userData.body.fields?.filter(
        (field) => field.deleted_at === undefined
      );

      // Kullanıcı ACL'sini ayarlama işlemi için
      const asd = await GetUserACL(userData.body.ACL);

      // Kullanıcıya ait ocak cihazlarını alma
      const userOcakDeviceResponse = await fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/userOcakDevice`,
        requestOptionsGET
      );
      const userOcakDeviceData = await userOcakDeviceResponse.json();
      //console.log("userOcakDeviceData",userOcakDeviceData);

      // Kullanıcıya ait izci cihazlarını alma
      const userIzciDeviceResponse = await fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/userIzciDevice`,
        requestOptionsGET
      );
      const userIzciDeviceData = await userIzciDeviceResponse.json();
      //console.log("userIzciDeviceData",userIzciDeviceData);

      // Tüm cihazları birleştirme işlemi
      var allDevices = [...userOcakDeviceData.body, ...userIzciDeviceData.body];

      // Tüm alanlarda cihazları güncelleme işlemi
      const updatedFields = activeFields?.map((field) => {
        if (field.devices) {
          field.devices = field.devices?.map((deviceId) =>
            allDevices.find((device) => {
              if (device.id === deviceId) {
                device.fieldName = field.name;
                return device;
              }
            })
          );
        }
        return field;
      });

      setFields(updatedFields);
      setFieldsApp(updatedFields);
      setLoading(false);
    };
    if (fieldsApp?.length > 0) {
      setFields(fieldsApp);
      setLoading(false);
    } else {
      fetchData();
    }
  }, [apiKey, user_id]);

  const GetUserACL = async (acl) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      acl = acl?.filter((field) => field.deleted_at === undefined);
      var arr = {
        ACL: acl,
      };
      var requestOptionsGET = {
        method: "GET",
        headers: myHeaders,
      };
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(arr),
      };
      //console.log("arrrrr",arr);
      const response = await fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/acl`,
        requestOptions
      );
      const result = await response.json();

      const allaclfield = result.body?.filter(
        (field) => field.deleted_at === undefined
      );
      //console.log("allacl filed",allaclfield);
      const promises = allaclfield?.map(async (aclfield) => {
        const responseOcak = await fetch(
          `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${aclfield.ACL[0].ownerid}/userOcakDevice`,
          requestOptionsGET
        );
        const resultOcak = await responseOcak.json();
        const allocak = resultOcak.body;

        const responseIzci = await fetch(
          `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${aclfield.ACL[0].ownerid}/userIzciDevice`,
          requestOptionsGET
        );
        const resultIzci = await responseIzci.json();
        const allizci = resultIzci.body;

        const updatedaclfield = processedFieldsFunc(
          allaclfield,
          allocak,
          allizci
        );

        //console.log("Updated ACL Field:", updatedaclfield);
        setACLFields(updatedaclfield);
        setACLFieldsApp(updatedaclfield);
      });

      await Promise.all(promises);
      //console.log('Tüm asenkron işlemler tamamlandı.');
    } catch (error) {
      console.error("Request error", error.message);
    }
  };

  function processedFieldsFunc(allaclfield, allocak, allizci) {
    const updatedACLField = allaclfield?.map((aclfield) => {
      if (aclfield.devices) {
        aclfield.devices.forEach((device, index) => {
          const foundOcak = allocak.find((o) => o.id === device);
          const foundIzci = allizci.find((o) => o.id === device);

          if (foundOcak) {
            aclfield.devices[index] = foundOcak;
          } else if (foundIzci) {
            aclfield.devices[index] = foundIzci;
          }
        });
      }
      setLoading((prev) => false);
      return aclfield;
    });

    //console.log("Updated ACL Field:", updatedACLField);
    return updatedACLField;
  }
  //console.log("bak acl fields",ACLFields);
  //console.log("bak  fields",fields);

  return (
    <SkeletonTheme baseColor="#d9d9d9" highlightColor="#fff">
      <div className=" max-sm:bg-[#f8f9fa] ">
        <div
          id="myElement13"
          className={` myElement13 max-sm:sticky  top-0   max-sm:w-full  lg:bg-none  px-5 z-10 max-sm:bg-[#f8f9fa] max-sm:pt-10 pt-6 pb-3 transition-all duration-300  ease-in-out delay-75`}
        >
          <p className="text-base text-textDark font-semibold -ml-5">
            {t("fieldsFields")}
          </p>
          <div id="bigselam" className="-ml-5">
            <p
              className="text-textLight selam text-xs whitespace-nowrap"
              id="selam"
            >
              {t("monitor")}
            </p>
            {/* <div className="w-full pt-2 h-8  text-textDark text-xs font-bold flex flex-row gap-2">
              <span className="bg-white px-2 py-1 rounded-md shadow-miniMap">
                {" "}
                {t("nodevice")}
              </span>
              <span className="bg-white px-2 py-1 rounded-md shadow-miniMap">
                {" "}
                optimal
              </span>
              <span className="bg-white px-2 py-1 rounded-md shadow-miniMap">
                {" "}
                {t("refill")}
              </span>
              <span className="bg-white px-2 py-1 rounded-md shadow-miniMap">
                {" "}
                {t("over")}
              </span>
            </div> */}
          </div>
          <div className="w-full h-8  mt-1 flex flex-row gap-2 -ml-5 whitespace-nowrap">
            <div
              className={`h-full text-xs px-2 font-semibold items-center content-center shadow-miniMap rounded-lg cursor-pointer ${
                filterType === "noDevice"
                  ? "bg-blue-500 text-white shadow-none"
                  : "bg-white text-textDark"
              }`}
              onClick={() =>
                filterType === "noDevice"
                  ? clearFilters()
                  : setFilterType("noDevice")
              }
            >
              no device
            </div>
            <div
              className={`h-full text-xs px-2 font-semibold items-center content-center shadow-miniMap rounded-lg cursor-pointer ${
                filterType === "refill"
                  ? "bg-blue-500 text-white shadow-none"
                  : "bg-white text-textDark"
              }`}
              onClick={() =>
                filterType === "refill"
                  ? clearFilters()
                  : setFilterType("refill")
              }
            >
              refill
            </div>
            <div
              className={`h-full text-xs px-2 font-semibold items-center content-center shadow-miniMap rounded-lg cursor-pointer ${
                filterType === "full"
                  ? "bg-blue-500 text-white shadow-none"
                  : "bg-white text-textDark"
              }`}
              onClick={() =>
                filterType === "full" ? clearFilters() : setFilterType("full")
              }
            >
              full
            </div>
            <div className="flex flex-row bg-white w-fit h-full text-xs px-2 text-textDark font-semibold  shadow-miniMap rounded-lg justify-center items-center">
              <p className="text-sm mr-1">
                <ion-icon name="search-outline"></ion-icon>
              </p>
              <input
                className="w-20 h-full focus:outline-none outline-none focus:max-sm:w-32 focus:w-60 transition-all"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {filterType && (
              <div
                className="bg-white h-full text-lg px-2 text-textDark font-semibold  shadow-miniMap rounded-lg cursor-pointer flex justify-center items-center"
                onClick={clearFilters}
              >
                <ion-icon name="close-outline"></ion-icon>
              </div>
            )}
          </div>
        </div>
        {loading ? (
          <div className=" w-full flex max-sm:flex-col flex-row gap-4 ">
            <div className="max-sm:w-full w-1/2 ">
              <Skeleton width={"90%"} height={120} />
            </div>
            <div className="max-sm:w-full w-1/2 ">
              <Skeleton width={"90%"} height={120} />
            </div>
          </div>
        ) : (
          <div className="flex flex-row   gap-8 lg:gap-x-12  max-sm:justify-center   content-start flex-wrap  h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-transparent ">
            {filteredFields?.length > 0
              ? filteredFields?.map((e) => (
                  <FieldCard key={e.name} data={e} allfields={fields} />
                ))
              : ""}
            {ACLFields?.length > 0
              ? ACLFields?.map((e) => {
                  // console.log("bak acl fields",ACLFields);
                  return <FieldCard key={e.name} data={e} allfields={fields} />;
                })
              : ""}
            {(fields == undefined || fields?.length == 0) &&
              ACLFields?.length == 0 && (
                <div className="w-full border-2 border-dashed p-6 flex flex-col   z-0 rounded-t-md items-center justify-center ">
                  <img src={NoDrawn} className="w-20  mb-5" />
                  <p className="text-base font-semibold text-textDark">
                    {t("fieldsNoDrawn")}
                  </p>
                  <p className="text-xs font-normal w-3/4 text-center text-textLight">
                    {t("fieldsDefineField")}
                  </p>
                  <Link
                    to="/setupField"
                    className="text-xs font-semibold p-3 bg-blue-500 text-white mt-2 rounded-md"
                  >
                    {t("newField")}
                  </Link>
                </div>
              )}
          </div>
        )}
        {/* <p className=' text-4xl pt-10 text-black font-mediumpt-3 '>Devices</p>
      <div className='flex flex-row  h-auto gap-8 max-sm:justify-center max-sm:mb-28 flex-wrap mb-10'>
        {fields?.map((e) => {
          return e.isDevice ? <FieldCard key={e.id} data={e} /> : '';
        })}
      </div> */}
      </div>
    </SkeletonTheme>
  );
}

export default Fields;
