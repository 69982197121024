import React, { useEffect, useState, useContext } from "react";
import ReactEcharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import izciFull from "../assets/izciFull.svg";
import izci90 from "../assets/izci90.svg";
import { useTranslation } from "react-i18next";
import { AccountContext } from "../events/Account";

function DeviceCard({ data, selectedDevice }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [lastData, setLastData] = useState();
  const [tsdata, settsdata] = useState();
  const [full, setfull] = useState(false);
  const [loading, setLoading] = useState(true);

  const [connection, setConnection] = useState(3);
  const [newdata, setnewdata] = useState(false);
  const { apiKey, user_id } = useContext(AccountContext);

  const tempcurrent = [
    lastData?.izci_at
      ? {
          at: Number(lastData.izci_at).toFixed(2),
        }
      : null,
    lastData?.izci_sm_05
      ? {
          mois: Number(lastData.izci_sm_05).toFixed(2),
          temp: Number(lastData.izci_st_05).toFixed(0),
          cm: "05",
        }
      : null,
    lastData?.izci_sm_15
      ? {
          mois: Number(lastData.izci_sm_15).toFixed(2),
          temp: Number(lastData.izci_st_15).toFixed(0),
          cm: "15",
        }
      : null,
    lastData?.izci_sm_25
      ? {
          mois: Number(lastData.izci_sm_25).toFixed(2),
          temp: Number(lastData.izci_st_25).toFixed(0),
          cm: "25",
        }
      : null,
    lastData?.izci_sm_35
      ? {
          mois: Number(lastData.izci_sm_35).toFixed(2),
          temp: Number(lastData.izci_st_35).toFixed(0),
          cm: "35",
        }
      : null,
    lastData?.izci_sm_45
      ? {
          mois: Number(lastData.izci_sm_45).toFixed(2),
          temp: Number(lastData.izci_st_45).toFixed(0),
          cm: "45",
        }
      : null,
    lastData?.izci_sm_55
      ? {
          mois: Number(lastData.izci_sm_55).toFixed(2),
          temp: lastData?.izci_st_55 && Number(lastData.izci_st_55).toFixed(0),
          cm: "55",
        }
      : null,
    lastData?.izci_sm_65
      ? {
          mois: Number(lastData.izci_sm_65).toFixed(2),
          temp: lastData?.izci_st_65 && Number(lastData.izci_st_65).toFixed(0),
          cm: "65",
        }
      : null,
    lastData?.izci_sm_75
      ? {
          mois: Number(lastData.izci_sm_75).toFixed(2),
          temp: lastData?.izci_st_75 && Number(lastData.izci_st_75).toFixed(0),
          cm: "75",
        }
      : null,
    lastData?.izci_sm_85
      ? {
          mois: Number(lastData.izci_sm_85).toFixed(2),
          temp: lastData?.izci_st_85 && Number(lastData.izci_st_85).toFixed(0),
          cm: "85",
        }
      : null,
    // { mois: 47, temp: 23, cm: '65' },
    // { mois: 45, temp: 23, cm: '75' },
    // { mois: 40, temp: 22, cm: '85' },
  ];
  useEffect(() => {
    // fetch(
    //   `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=last&izcilength=${data.izci_length}`,
    //   {
    //     method: "GET",
    //   }
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     // console.log(result);
    //     const resultfilter = JSON.parse(result.body);
    //     // console.log("last measure data : ", resultfilter);
    //     setLastData(resultfilter);
    //   })
    //   .catch((err) => {
    //     // console.log("lasdatafetcherr : ", err);
    //   });
    // fetch(
    //   `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=izci_sm_05&timeResolution=1h`,
    //   {
    //     method: "GET",
    //   }
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     // console.log(result);
    //     const resultfilter = result.body;
    //     var obj = [];
    //     result.body.map((e) => {
    //       obj.push(JSON.parse(e));
    //     });
    //     settsdata(obj);

    //     // yaz(resultfilter.at);

    //     // // console.log('funcyaz: ', yaz(resultfilter.at));
    //     // settsdata(resultfilter);
    //   })

    //   .then(() => setLoading(false))
    //   .catch((err) => {
    //     // console.log("devicecard err :", err);
    //   });

    ///

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=last&izcilength=${data.izci_length}`,
          {
            method: "GET",
          }
        );
        const result = await response.json();
        // İkinci fetch işleminin sonucunu işle
        const resultfilter = JSON.parse(result.body);
        console.log("izci last measure data : ", resultfilter);
        setLastData(resultfilter);
        setLoading(false);
      } catch (error) {
        console.log("izci error", error);
        setLoading(false);
      }
    };

    // fetchData fonksiyonunu çağır
    if (connection == 3) {
      fetchData();
    }

    // WebSocket bağlantısını oluşturun
    var socket = new WebSocket(
      `wss://zh906rwmy8.execute-api.eu-west-1.amazonaws.com/production/?user_id=${user_id}&devices=[${data.id}]`
    );

    // Bağlantı başarıyla kurulduğunda
    socket.addEventListener("open", (event) => {
      console.log("izci WebSocket bağlantısı başarıyla kuruldu.", event);
    });
    const pongInterval = setInterval(() => {
      socket.send("ping");
    }, 30000); // Her 30 saniyede bir
    // Mesaj alındığında
    socket.addEventListener("message", (event) => {
      setnewdata((prev) => true);
      console.log("izci WebSocketten gelen mesaj:", event);
      var dat = JSON.parse(event.data);
      console.log("izci dat dat dat 1:", dat);
      var parseddata = { ...dat.data, ...dat.data.izci_packet };
      console.log("izci dat dat dat:", parseddata);
      console.log("izci tsdata", tsdata);

      setLastData((prev) => parseddata);
    });

    // Bağlantı kapandığında
    socket.addEventListener("close", (event) => {
      console.log("izci WebSocket bağlantısı kapatıldı.", event);
      clearInterval(pongInterval);

      setTimeout(() => {
        setConnection((prev) => !prev);
      }, 5000);
    });

    // Hata oluştuğunda
    socket.addEventListener("error", (event) => {
      console.error("izci WebSocket hatası:", event);
    });
    console.log("izci websocet kapalı triggered", socket.readyState);
  }, []);

  function yaz(attrname) {
    var time = [];
    var value = [];
    var lasttime;
    tsdata?.map((data) => {
      // if (Date.parse(data.time) - lasttime < -7200000) {
      //   value.push([Date.parse(data.time) + 3200000, null]);
      // }
      var mdate = new Date(data.time);
      mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
      value.push([mdate, Number(data.ts).toFixed(2)]);

      lasttime = Date.parse(data.time);
    });
    return value;
  }

  const DrawStackedTS = () => {
    var graphData1 = yaz("sm5");
    return StackedLine([graphData1], ["sm5"]);
  };
  function formatDate(date) {
    var mdate = new Date(date);
    console.log("date", mdate);
    mdate.setMinutes(mdate.getMinutes());
    // return `       ${mdate.getHours()}:${
    //   mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    // }
    //  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    // `;
    return `${timeDifference(mdate)},       ${mdate.getHours()}:${
      mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    }
     
    `;
  }
  function timeDifference(previous) {
    var current = new Date().getTime();
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ` ${t("minutes")} ${t("ago")}`;
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ` ${t("hours")} ${t("ago")}`;
    } else if (elapsed < msPerMonth) {
      return " " + Math.round(elapsed / msPerDay) + ` ${t("days")} ${t("ago")}`;
    } else if (elapsed < msPerYear) {
      return (
        " " + Math.round(elapsed / msPerMonth) + ` ${t("months")} ${t("ago")}`
      );
    } else {
      return (
        " " + Math.round(elapsed / msPerYear) + ` ${t("years")} ${t("ago")}`
      );
    }
  }

  const navigateFunction = (id) => {
    var pathname = window.location.pathname;
    var sonIndis = pathname.lastIndexOf("/"); // Son "/" işaretinin indisini buluyoruz
    var yeniDizge = pathname.substring(sonIndis + 1); // Substring metodu ile istenen kısmı alıyoruz
    if (yeniDizge !== id) {
      navigate(`${id}`, {
        state: { ...data, tsdata },
      });
    }
  };

  return (
    <div className="hover:cursor-pointer devicecardcontainer min-w-[20rem]   lg:w-1/4  md:w-1/2   max-sm:p-0  max-sm:w-full mb-8">
      {loading ? (
        <div className=" w-full flex max-sm:flex-col flex-row gap-4">
          <SkeletonTheme baseColor="#d9d9d9" highlightColor="#fff">
            <Skeleton width={"60%"} height={30} />
            <Skeleton width={"100%"} height={400} />
          </SkeletonTheme>
        </div>
      ) : (
        <>
          {
            // console.log("lastDataDeviceCard:", data)
          }
          <div
            className="relative bg-white rounded-2xl p-5  shadow-myShadow1"
            onClick={() => {
              navigateFunction(`${data.id.slice(-4)}`, {
                state: { ...data, tsdata },
              });
            }}
          >
            <div className="absolute right-6  leftside flex flex-row items-start  top-0">
              <div
                className={`${
                  data.id.slice(-4) == "FEAF" || data.id.slice(-4) == "00FC"
                    ? "bg-[#F201AF]"
                    : data.id.slice(-4) == "0322"
                    ? "bg-[#FFA800]"
                    : ""
                }   h-28 mb-8 w-14  rounded-bl-[1.25rem] text-white font-bold `}
              >
                <div className="flex flex-col items-center justify-center pt-12">
                  <p className="text-sm">
                    {data.id.slice(-4) == "0322"
                      ? t("overshort")
                      : data.id.slice(-4) == "FEAF" ||
                        data.id.slice(-4) == "00FC"
                      ? t("refillshort")
                      : ""}{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full  ">
              {selectedDevice ? (
                ""
              ) : (
                <p className="text-lg font-semibold text-textDark">
                  {data.id.slice(-4)}
                </p>
              )}
              <p
                className={`${
                  lastData?.status == "on" ? "text-white " : "text-blue-500"
                }   text-xs font-semibold `}
              >
                {data?.fieldName}
              </p>
              <div className=" flex flex-row flex-wrap gap-5  ">
                <div className="w-full  h-full">
                  <div className="flex flex-row w-full  ">
                    <div>
                      <p className=" flex flex-col text-sm text-textLight">
                        {t("fieldsLastMeasurement")}
                        <p className="">
                          {lastData?.received_date &&
                            formatDate(Number(lastData?.received_date))}
                        </p>
                      </p>
                    </div>
                    <span
                      onClick={(event) => {
                        event.stopPropagation();
                        window.open(
                          "https://maps.google.com?q=" +
                            data?.coords?.lat +
                            "," +
                            data?.coords?.lon
                        );
                      }}
                      className="absolute right-24 top-10 text-lg ml-16 bg-white rounded-md  text-textDark  shadow-miniMap w-9 h-9 text-center "
                    >
                      <p className="pt-2 ">
                        <ion-icon name="map-outline"></ion-icon>
                      </p>
                    </span>
                  </div>
                  <div className="cubukcontainer flex flex-row justify-center overflow-clip ">
                    <div
                      className={`${
                        data.izci_length == "60"
                          ? "h-[18.5rem] "
                          : "h-[9.5rem] "
                      }  w-[4.3rem]  `}
                    >
                      {data?.izci_length == 90 ? (
                        <img src={izci90} className="w-full   " />
                      ) : (
                        <img src={izciFull} className="w-full   " />
                      )}
                    </div>
                    <div className="pt-4 ">
                      <div className=" pt-4">
                        <p className="text-sm  text-textLight">
                        {t("batteryVoltage")} <span className="font-semibold text-textDark">{lastData?.izci_battery_voltage &&
                            lastData?.izci_battery_voltage}</span>
                        </p>
                        {/* <p className="font-semibold text-sm text-blue-500">
                          {t("active")}
                        </p> */}
                        <div className="flex flex-row center items-center    mb-4">
                          <p className="text-sm text-textLight mr-2 ">
                            {t("ambienttemp")}
                          </p>
                          <p className="text-2xl font-semibold text-textDark whitespace-nowrap">
                            {lastData?.izci_at ? lastData.izci_at : ""} °
                          </p>
                        </div>
                      </div>

                      <div className="-ml-12">
                        <div className="flex flex-row justify-between  text-textLight text-sm">
                          <p className="invisible">""""""""""</p>
                          <p>{t("soilmois")} %</p>
                          <p>{t("soiltemp")} °</p>
                        </div>
                        {tempcurrent.map((e) => {
                          return (
                            e !== null &&
                            e.cm && (
                              <div
                                key={Math.random()}
                                className="flex flex-row justify-around text-sm h-7  "
                              >
                                <p className="text-textLight  ">
                                  {e.cm ? `${e.cm}cm` : ""}
                                </p>
                                <p className="font-semibold text-textDark -ml-4">
                                  {e.mois}
                                </p>
                                <p className="font-semibold text-textDark ">
                                  {e.temp}
                                </p>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className=" w-full h-1/6   ">
                  <p className="relative z-20 text-lg font-bold -mb-10  pl-3 ">
                    Soil Moisture{" "}
                  </p>
                  <div className="z-10  ">
                    {DrawStackedTS(tsdata.ts, "SM05")}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default DeviceCard;

function StackedLine(rawdatas, names) {
  let option = {
    // title: {
    //   show: false,
    //   text: ' asd',
    //   display: 'none',
    // },

    responsive: true,
    tooltip: {
      trigger: "axis",
    },
    // legend: {},
    toolbox: {
      show: false,
      feature: {
        dataZoom: {
          yAxisIndex: "none",
        },
        dataView: { readOnly: false },
        magicType: { type: ["line", "bar"] },
        restore: {},
        saveAsImage: {},
      },
    },
    grid: [
      {
        left: "10%",
        right: "0%",
        width: "90%",
        height: "35%",
      },
    ],
    axisLine: {
      show: false,
    },
    xAxis: {
      type: "time",
      show: false,
      boundaryGap: false,
      splitLine: { show: false },
      splitArea: {
        show: false,
      },
      axisLine: { show: false },
    },
    yAxis: {
      show: false,
      splitLine: { show: false },
      splitArea: {
        show: false,
      },
      axisLine: { show: false },
      type: "value",
      max: function (value) {
        return value.max + 0;
      },
      min: function (value) {
        return value.min - 0;
      },
    },

    series: [],
    seriesCnt: "3",
    backgroundColor: "rgba(255,255,255,1)",
    titleColor: "#000000",
    subtitleColor: "#505050",
    textColorShow: false,
    textColor: "#333",
    markTextColor: "#ffffff",
    color: ["#2a10ac", "#00b7fb", "#df07dc"],
    borderColor: "#ccc",
    borderWidth: "0",
    visualMapColor: ["#8a7ca8", "#e098c7", "#cceffa"],
    legendTextColor: "#000000",
    kColor: "#e098c7",
    kColor0: "transparent",
    kBorderColor: "#e098c7",
    kBorderColor0: "#8fd3e8",
    kBorderWidth: "2",
    lineWidth: "1",
    symbolSize: "0",
    symbol: "emptyRoundRect",
    symbolBorderWidth: "0",
    lineSmooth: false,
    graphLineWidth: 1,
    graphLineColor: "#aaaaaa",
    mapLabelColor: "#000",
    mapLabelColorE: "#ffffff",
    mapBorderColor: "#444",
    mapBorderColorE: "#444",
    mapBorderWidth: 0.5,
    mapBorderWidthE: 1,
    mapAreaColor: "#eee",
    mapAreaColorE: "#e098c7",
    axes: [
      {
        type: "all",
        name: "通用坐标轴",
        axisLineShow: true,
        axisLineColor: "#000000",
        axisTickShow: false,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#000000",
        splitLineShow: false,
        splitLineColor: ["#eeeeee", "#333333"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
      {
        type: "category",
        name: "类目坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: false,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "value",
        name: "数值坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "log",
        name: "对数坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "time",
        name: "时间坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
    ],
    axisSeperateSetting: false,
    toolboxColor: "#4100ff",
    toolboxEmphasisColor: "#000000",
    tooltipAxisColor: "#0013ff",
    tooltipAxisWidth: "3",
    timelineLineColor: "#8fd3e8",
    timelineLineWidth: 1,
    timelineItemColor: "#ff0000",
    timelineItemColorE: "#8fd3e8",
    timelineCheckColor: "#8fd3e8",
    timelineCheckBorderColor: "#8a7ca8",
    timelineItemBorderWidth: 1,
    timelineControlColor: "#8fd3e8",
    timelineControlBorderColor: "#8fd3e8",
    timelineControlBorderWidth: 0.5,
    timelineLabelColor: "#8fd3e8",
    datazoomBackgroundColor: "rgba(0,0,0,0)",
    datazoomDataColor: "rgba(255,255,255,0.3)",
    datazoomFillColor: "rgba(167,183,204,0.4)",
    datazoomHandleColor: "#a7b7cc",
    datazoomHandleWidth: "100",
    datazoomLabelColor: "#333",
  };
  rawdatas.map((e, index) => {
    option.series.push({
      name: names[index],
      type: "line",
      symbol: "none",
      data: e,
      lineStyle: {
        width: 4,
      },
    });
  });
  return <ReactEcharts option={option} className="!h-32 z-10 !w-11/12" />;
}
