import React, { useState, useContext } from "react";
import { AccountContext } from "../events/Account";
import Logo from "../assets/suyabakan_logo_svg.svg";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginControl, setLoginControl] = useState("");
  const { authenticate } = useContext(AccountContext);
  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  };
  const navigate = useNavigate();
  const onSubmit = (event) => {
    event.preventDefault();
    authenticate(email, password)
      .then((data) => {
        console.log("Logged in!", data);
        localStorage.setItem("apiKey", data.idToken.payload["custom:apiKey"]);
        console.log("apiKey: ", localStorage.getItem("apiKey"));
        setLoading(false);
        navigate("/");
        setLoginControl("onSuccess");
      })
      .catch((err) => {
        switch (err.code) {
          case "UserNotConfirmedException":
            setLoginControl("UserNotConfirmedException");
            break;
          case "NotAuthorizedException":
            setLoginControl("NotAuthorizedException");
            break;
          case "InvalidParameterException":
            setLoginControl("InvalidParameterException");
            break;
        }
        console.log("catch e :", err);
        setLoading(false);
      });
  };

  return (
    <div className="p-5 h-full w-screen relative flex flex-col bg-[#F8F9FA] ">
      <div className="fixed p-5 z-0 top-0 h-[60vh] max-sm:h-[30rem] w-full  left-0  justify-center    bg-cover bg-no-repeat  rounded-2xl ">
        <div className="w-full h-full bg-bgoverview13 brightness-[.5] contrast-[.75] bg-bottom bg-cover rounded-2xl "></div>
      </div>
      <div className="relative z-10 w-full h-60 px-6 py-10 flex flex-col items-center justify-center ">
        <select
          className="fixed right-0 top-0  m-10 block float-right  text-white bg-white/0"
          onChange={(e) => changeLanguageHandler(e.target.value)}
          defaultValue={i18n?.language}
        >
          <option value="en-EN">EN</option>
          <option value="tr-TR">TR</option>
        </select>
        <p className="text-4xl font-semibold text-white text-center mb-4 ">
          {t("welcome")}!
        </p>
        <p className="text-sm  text-white text-center ">
          {t("manageandoptimize")}
        </p>
      </div>
      <div className=" relative z-10 w-[87%] mx-auto shadow-myShadow1   lg:w-[25rem] bg-white backdrop-blur-lg  rounded-2xl ">
        <img src={Logo} className=" mx-auto my-8 w-28  " />
        {/* <h3 className="pt-4 text-2xl text-center clear-both">
          {t("welcome")} &#128075;
        </h3> */}
        <form
          onSubmit={(e) => {
            setLoading(true);
            onSubmit(e);
          }}
          className="px-5  pb-8 mb-4  rounded"
        >
          <div className="mb-4">
            {/* <label
                    className='block mb-2 text-sm font-bold text-gray-700'
                    htmlFor='email'
                  >
                    {t('email')}
                  </label> */}
            <input
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className="w-full px-3 py-3 text-sm  text-textDark border border-textLight/50 rounded-lg  appearance-none focus:outline-none "
              type="email"
              placeholder={`${t("email")}`}
            />
          </div>
          <div className="mb-4">
            {/* <label
                    className='block mb-2 text-sm font-bold text-gray-700'
                    htmlFor='password'
                  >
                    {t('password')}
                  </label> */}
            <input
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              className="w-full px-3 py-3 text-sm  text-textDark border border-textLight/50 rounded-lg  appearance-none focus:outline-none"
              type="password"
              placeholder={`${t("password")}`}
            />
          </div>
          <div className="mb-6 text-center">
            {loginControl == "NotAuthorizedException" ? (
              <p className="text-sm text-red-600">{t("incorrect")}</p>
            ) : loginControl == "UserNotConfirmedException" ? (
              <p className="text-sm text-red-600">{t("notConfirmed")}</p>
            ) : (
              loginControl == "InvalidParameterException" && (
                <p className="text-sm text-red-600">{t("empty")}</p>
              )
            )}
            <button
              className="w-full text-sm px-4 py-3 uppercase font-semibold text-white bg-gradient-to-r from-[#62CBF8] to-[#3157F5] rounded-lg  focus:outline-none focus:shadow-outline"
              type="submit"
              disabled={loading ? true : false}
            >
              {loading == true ? (
                <div className="  bg-opacity-5 ">
                  <div className="m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-6 w-6"></div>
                </div>
              ) : (
                `${t("login")}`
              )}
            </button>
          </div>
          <hr className="mb-6 border-t" />
          <div className="text-center">
            <Link
              className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
              to="/signup"
            >
              {t("createAnAccount")}
            </Link>
          </div>
          <div className="text-center">
            <Link
              className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
              to="/forgotPassword"
            >
              {t("forgotPassword")}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
