import React, { useContext, useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  Popup,
  useMapEvent,
  FeatureGroup,
  Circle,
  Polygon,
  AttributionControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { map } from "leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import SetupFieldImg from "../assets/setupFieldImg.png";
import { useNavigate } from "react-router-dom";
import Refill from "../assets/refill.svg";
import Optimal from "../assets/optimal.svg";
import Full from "../assets/full.svg";
import Izci from "../assets/Izci2.svg";
import NoInstalled from "../assets/noinstalleddevice.svg";
import NoDrawn from "../assets/nodrawn.svg";
import { useTranslation } from "react-i18next";
import { AppContext } from "../pages/Layout";
const markerIzciIcon = new L.Icon({
  iconUrl: require("../assets/softpinkmarker.png"),
  iconSize: [20, 20],
  iconAnchor: [10, 10], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});
const markerOcakIcon = new L.Icon({
  iconUrl: require("../assets/softorangemarker.png"),
  iconSize: [20, 20],
  iconAnchor: [10, 10], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

function FarmCard({ farm }) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [fieldTown, setFieldTown] = useState();
  const [devicesCount, setDevicesCount] = useState(0);
  const { farmsTownApp, setFarmsTownApp } = useContext(AppContext);
  var position = [];

  console.log("FarmCardJS : ", farm);
  farm?.map((data) => {
    var fieldposition = [];
    data?.geometry?.length > 0 &&
      data.geometry.map((e) => {
        fieldposition.push([e.lat, e.lng]);
      });
    position.push(fieldposition);
  });

  const setGeometry = (geometry) => {
    var fieldposition = [];
    console.log("geometry", geometry);

    geometry?.map((e) => {
      fieldposition.push([e.lat, e.lng]);
    });
    console.log("fieldposition", fieldposition);
    return fieldposition;
  };
  function calculateAverageCenter(farm) {
    let totalLng = 0;
    let totalLat = 0;
    let centerCount = 0;

    farm.forEach((e) => {
      if (e.center && e.center.lng && e.center.lat) {
        totalLng += e.center.lng;
        totalLat += e.center.lat;
        centerCount++;
      }
    });

    if (centerCount === 0) {
      return null; // Merkez nokta bulunamadı
    }

    const averageLng = totalLng / centerCount;
    const averageLat = totalLat / centerCount;

    return [averageLat, averageLng];
  }
  useEffect(() => {
    farm?.map((data) => {
      if (data.devices) {
        setDevicesCount((prev) => prev + data.devices?.length);
      }
    });
    const requestOptionsnom = {
      method: "GET",
    };
    const fetch1 = async () => { fetch(
      `https://nominatim.openstreetmap.org/search?q=${farm[0]?.center?.lat},${farm[0]?.center?.lng}&addressdetails=1&format=json`,
      requestOptionsnom
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("coord nomi:", result);
        var tempFarmsTown = farmsTownApp;
        var tempSelectedFarmName = farm[0]?.farmName;
        var tempSelectedFarmTown = `${result[0].address.town}, ${result[0].address.province}`;
        if (tempFarmsTown?.find((e) => e.farmName === tempSelectedFarmName)) {
        } else {
          tempFarmsTown.push({
            farmName: tempSelectedFarmName,
            town: tempSelectedFarmTown,
          });
          setFarmsTownApp(tempFarmsTown);
        }
        setFieldTown(
          `${result[0].address.town}, ${result[0].address.province} `
        );
      })
      .catch((err) => console.log("err : ", err));
    }
    if(farmsTownApp.find((e) => e.farmName === farm[0]?.farmName)){
      setFieldTown(farmsTownApp.find((e) => e.farmName === farm[0]?.farmName).town);
    }else{
      fetch1();
    }
  }, []);
  return (
    <div
      className="hover:cursor-pointer relative  w-full     h-auto max-sm:w-full bg-white shadow-myShadow1 rounded-2xl"
      onClick={() => {
        navigate(`/farm/${farm[0].farmName ? farm[0].farmName : ``}`, {
          state: farm,
        });
      }}
    >
      <div className="relative rounded-xl   ">
        <div className="absolute bottom-0 content-end w-full h-3/4 z-50 bg-gradient-to-t from-black/90 rounded-xl to-black/0 px-3 pt-6">
          <p className="  flex flex-row justify-between leading-none">
            {farm ? (
              <p className="text-base lg:text-base font-semibold text-white leading-none">
                {farm[0].farmName}
              </p>
            ) : (
              ""
            )}
          </p>
          <div className="flex flex-col justify-between text-sm lg:text-xs mb-4">
            <p className="text-gray-300 text-xs  leading-none mb-2">
              {fieldTown}
            </p>
            <div className="flex flex-row gap-3">
              <div className="flex flex-row text-sm text-white justify-center items-center gap-1">
                <ion-icon name="thermometer-outline"></ion-icon>

                <p className="text-gray-300 text-xs  ">{devicesCount}</p>
              </div>
              <div className="flex flex-row text-sm text-white justify-center items-center gap-1">
                <ion-icon name="leaf"></ion-icon>

                <p className="text-gray-300 text-xs  ">no crop</p>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full z-[9] h-40 rounded-xl shadow-miniMap ">
          <MapContainer
            center={calculateAverageCenter(farm)}
            zoom={14}
            scrollWheelZoom={false}
            className="w-full h-full rounded-xl z-0"
            dragging={false}
            zoomControl={false}
            attributionControl={false}
          >
            <TileLayer
              url="https://mt1.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"
              // http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}
            />
            <AttributionControl position="bottomright" prefix={"Leaflet"} />
            {farm.map((data) => {
              if (data.geometry_type === "polygon") {
                return (
                  <Polygon
                    key={data.id} // her elemanın key propu olması önemli
                    positions={setGeometry(data.geometry)}
                    color="#ffffff"
                    fillColor="#ffffff"
                    weight={1}
                  />
                );
              } else {
                return null; // Polygon değilse null döndürmek uygun olabilir
              }
            })}
            {farm.map((data) => {
              return data.geometry_type === "circle" ? (
                <Circle
                  key={data.id}
                  center={data.center}
                  radius={data.radius}
                  weight={1}
                  color="#ffffff"
                  fillColor="#ffffff"
                />
              ) : null;
            })}
            {/* {farm.map((data) => {
              return data.devices?.[0]?.coords ? (
                <Marker
                  key={data.devices[0].id}
                  position={[
                    data.devices[0].coords?.lat,
                    data.devices[0].coords?.lon,
                  ]}
                  icon={
                    data.devices[0].type === "izci"
                      ? markerIzciIcon
                      : markerOcakIcon
                  }
                >
                  <Popup autoClose={false}>
                    A pretty CSS3 popup. <br /> Easily customizable.
                  </Popup>
                </Marker>
              ) : null;
            })} */}
          </MapContainer>
        </div>
      </div>
    </div>
  );
}

export default FarmCard;
