import React, { useContext } from 'react';
import { AccountContext } from '../events/Account';
import Navbar from '../components/Navbar';
import Timeseries from './TimeSeries';
const Setting = () => {
  const { getSession } = useContext(AccountContext);

  return (
    <div>
      <Navbar />
      <Timeseries />
    </div>
  );
};

export default Setting;
