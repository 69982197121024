import React, { createContext, useEffect, useRef, useState } from "react";

import { Outlet, Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import HamburgerButton from "../components/HamburgerButton";
import PullToRefresh from "react-simple-pull-to-refresh";
const AppContext = createContext();

const Layout = () => {
  const [scrollTop, setScrollTop] = useState("");
  const [userApp, setUserApp] = useState("");
  const [farmsApp, setFarmsApp] = useState([]);
  const [fieldsApp, setFieldsApp] = useState([]);
  const [aclFieldsApp, setAclFieldsApp] = useState([]);
  const [userOcakApp, setUserOcakApp] = useState();
  const [userVanaApp, setUserVanaApp] = useState();
  const [farmNameApp, setFarmNameApp] = useState();
  const [userIzciApp, setUserIzciApp] = useState();
  const [farmsTownApp, setFarmsTownApp] = useState([]);
  const handleScroll = (event) => {
    const element = document.getElementById("myElement13");
    let pos = element.getBoundingClientRect().y;
    if (pos == 0) {
      {
        element.classList.add("stickyHeader");
      }
    } else if (element.classList.contains("stickyHeader")) {
      element.classList.remove("stickyHeader");
    }
    // console.log("scroll", scrollTop);
  };
    useEffect(() => {
      console.log("ctxapp userApp:", userApp);
      console.log("ctxapp farmsApp:", farmsApp);
      console.log("ctxapp fieldsApp:", fieldsApp);
      console.log("ctxapp aclFieldsApp:", aclFieldsApp);
      console.log("ctxapp userOcakApp:", userOcakApp);
      console.log("ctxapp userVanaApp:", userVanaApp);
      console.log("ctxapp farmNameApp:", farmNameApp);
      console.log("ctxapp userIzciApp:", userIzciApp);
      console.log("ctxapp farmsTownApp:", farmsTownApp);
    }, [scrollTop, userApp, farmsApp, fieldsApp, aclFieldsApp, userOcakApp, userVanaApp, farmNameApp, userIzciApp]);
  return (
    <div className="relative select-none w-screen h-screen max-w-[1920px] mx-auto overflow-clip ">
      <AppContext.Provider
        value={{
          userApp,
          setUserApp,
          farmsApp,
          setFarmsApp,
          userOcakApp,
          setUserOcakApp,
          userVanaApp,
          setUserVanaApp,
          farmNameApp,
          setFarmNameApp,
          userIzciApp,
          setUserIzciApp,
          fieldsApp,
          setFieldsApp,
          aclFieldsApp,
          setAclFieldsApp,
          farmsTownApp,
          setFarmsTownApp,
        }}
      >
        <div className="fixed z-20 left-10">
          <HamburgerButton />
        </div>
        <div className="relative z-0 w-screen ">
          <Navbar />
        </div>
        <div
          onClick={() => {
            var navi = document.getElementById("mobilBody");
            var navi2 = document.getElementById("sheetBody");

            if (navi.classList?.contains("mobilSmallBody")) {
              navi.classList.remove("mobilSmallBody");
            }
            if (navi2.classList?.contains("bottomsheet")) {
              navi2.classList.remove("bottomsheet");
            }
          }}
          className="relative max-sm:bg-[#F8F9FA] max-lg:bg-[#F8F9FA] bg-none   w-screen h-screen  overflow-y-scroll scrollbar-none scrollbar-thumb-slate-300 scrollbar-track-transparent  z-10 lg:w-[calc(100%_-_15rem)]  lg:ml-[15rem]  "
          id="mobilBody"
          // onScroll={handleScroll}
        >
          {/* <div className='topspace fixed top-0  w-14 h-10 bg-[#F8F9FA]   z-40'></div> */}
          <Outlet />
        </div>
      </AppContext.Provider>
    </div>
  );
};

export  {Layout, AppContext};
