import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { Account } from './events/Account';
import './events/i18n';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Account>
    <App />
  </Account>
);
