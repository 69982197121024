import React, { useState, useEffect, useRef, useContext } from "react";
import SetupFieldImg from "../assets/setupFieldImg.png";
import { useNavigate } from "react-router-dom";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  Popup,
  useMapEvent,
  FeatureGroup,
  Circle,
  AttributionControl,
  Polygon,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { map } from "leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import { AccountContext } from "../events/Account";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "react-feather";
const NOMINATIM_BASE_URL = "https://nominatim.openstreetmap.org/search?";
function ResetCenterView(props) {
  const { selectPosition } = props;
  const map = useMap();

  if (selectPosition) {
    map.setView(
      L.latLng(selectPosition?.lat, selectPosition?.lon),
      map.getZoom(),
      {
        animate: true,
      }
    );
  }

  return null;
}
function SetupField() {
  const { t, i18n } = useTranslation();
  const { apiKey, user_id } = useContext(AccountContext);
  const navigate = useNavigate();
  const [FieldName, setFieldName] = useState();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState();
  const [step, setStep] = useState(1);
  const [position, setPosition] = useState([39.933298, 32.859756]);
  const [searchPosition, setSearchPosition] = useState();
  const [fieldGeometry, setFieldGeometry] = useState([]);
  const [fieldGeometryCenter, setFieldGeometryCenter] = useState({});
  const [fieldZoom, setFieldZoom] = useState();
  const [circleRadius, setCircleRadius] = useState();
  const [fieldGeometryType, setFieldGeometryType] = useState();
  const [farmName, setFarmName] = useState();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [farms, setFarms] = useState([]);
  const [fields, setFields] = useState([]);
  const [ACLFields, setACLFields] = useState([]);
  var geometry = [];
  var geometryCenter = {};
  var geometryType;
  var leafletId;
  var zoom;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("apikey", apiKey);
        myHeaders.append("userid", user_id);

        const requestOptionsGET = {
          method: "GET",
          headers: myHeaders,
        };

        // Kullanıcı verilerini alma
        const userDataResponse = await fetch(
          `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
          requestOptionsGET
        );
        const userData = await userDataResponse.json();

        const activeFields = userData.body.fields.filter(
          (field) => field.deleted_at === undefined
        );
        GetUserACL(userData.body.ACL);
        setFields((prev) => activeFields);
        GetUserFarm(activeFields);
        // setFields(updatedFields);
        // setLoading(false);
      } catch (error) {
        console.log("error", error);

        // Hata durumunda kullanıcıya bilgilendirme yapılabilir
      }
    };

    fetchData();
  }, [apiKey, user_id]);
  const GetUserACL = async (acl) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);

      var arr = {
        ACL: acl,
      };
      var requestOptionsGET = {
        method: "GET",
        headers: myHeaders,
      };
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(arr),
      };

      const response = await fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/acl`,
        requestOptions
      );
      const result = await response.json();

      const allaclfield = result.body;

      setACLFields(allaclfield);
    } catch (error) {
      console.error("Request error", error.message);
    }
  };
  const GetUserFarm = (allfield) => {
    const Farms = {};

    // Veriyi "farmName" özelliğine göre grupla
    allfield.forEach((item) => {
      if (item.farmName) {
        if (!Farms[item.farmName]) {
          Farms[item.farmName] = [item];
        } else {
          Farms[item.farmName].push(item);
        }
      }
    });
    console.log("Setup Device FArms:", Farms);
    setFarms((prev) => Farms);
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const requestOptions = {
        method: "GET",
      };
      fetch(
        `https://nominatim.openstreetmap.org/search?q=${search}&addressdetails=1&format=json`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          console.log(JSON.parse(result));
          const data = JSON.parse(result);
          setSearchResult(data.slice(0, 5));
        })
        .catch((err) => console.log("err : ", err));
      setSearchResult();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [search]);
  function changePosition(lat, lon) {
    //

    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("dynamo userapi get: ", result);
      })
      .catch((error) => console.log("error", error));

    //
    setSearchPosition({ lat: lat, lon: lon });
    setSearchResult(null);
  }
  const _onEdited = (e) => {
    if (geometryType == "polygon") {
      console.log(`_onEdited : `, e);
      console.log(`leafletid : `, leafletId);
      console.log("geometri before edit: ", geometry);
      console.log(`_onEdited : `, e.layers._layers[leafletId]._latlngs[0]);
      geometryCenter = e.layers._layers[leafletId].getCenter();
      zoom = e.sourceTarget._zoom;
      setFieldGeometry(e.layers._layers[leafletId]._latlngs[0]);
      setFieldGeometryCenter(e.layers._layers[leafletId].getCenter());
      setFieldZoom(e.sourceTarget._zoom);
    }
    if (geometryType == "circle") {
      console.log(`_onEdited : `, e);
      console.log(`leafletid : `, leafletId);
      console.log("geometri before edit: ", geometry);
      console.log(`_onEdited : `, e.layers._layers[leafletId]._latlng);
      geometryCenter = e.layers._layers[leafletId]._latlng;
      zoom = e.sourceTarget._zoom;
      setFieldGeometryCenter(e.layers._layers[leafletId]._latlng);
      setCircleRadius(e.layers._layers[leafletId]._mRadius);
      setFieldZoom(e.sourceTarget._zoom);
    }
  };

  const _onCreated = (e) => {
    if (e.layerType == "polygon") {
      console.log("_onCreated : ", e);
      console.log("_onCreated : ", e.layer._latlngs[0]);
      console.log("leaflet id created:", e.layer._leaflet_id);
      console.log("zoom : ", e.sourceTarget._zoom);
      geometry = e.layer._latlngs[0];
      geometryCenter = e.layer.getCenter();
      leafletId = e.layer._leaflet_id;
      zoom = e.sourceTarget._zoom;
      geometryType = e.layerType;
      setFieldGeometryType(e.layerType);
      setFieldGeometry(e.layer._latlngs[0]);
      setFieldGeometryCenter(e.layer.getCenter());
      setFieldZoom(e.sourceTarget._zoom);

      setSearchPosition({
        lat: e.layer.getCenter().lat,
        lon: e.layer.getCenter().lng,
      });
    }
    if (e.layerType == "circle") {
      console.log("_onCreated : ", e);
      console.log("_onCreated : ", e.layer._latlng);
      console.log("leaflet id created:", e.layer._leaflet_id);
      console.log("zoom : ", e.sourceTarget._zoom);
      geometry = e.layer._latlng;
      geometryCenter = e.layer._latlng;
      leafletId = e.layer._leaflet_id;
      zoom = e.sourceTarget._zoom;
      geometryType = e.layerType;
      setFieldGeometryType(e.layerType);
      setCircleRadius(e.layer._mRadius);
      setFieldGeometryCenter(e.layer._latlng);
      setFieldZoom(e.sourceTarget._zoom);
      setSearchPosition({
        lat: e.layer._latlng.lat,
        lon: e.layer._latlng.lng,
      });
    }
  };

  const _onDeleted = (e) => {
    console.log("_onDeleted : ", e);
    geometryType = false;
  };

  const _onDrawStart = (e) => {
    console.log("_onDrawStart : ", e);
  };

  const GeometrySubmit = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    // var allfield = [];
    var requestOptionsGET = {
      method: "GET",
      headers: myHeaders,
    };

    // fetch(
    //   `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
    //   requestOptionsGET
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     console.log(result);
    //     allfield = result.body.fields ? result.body.fields : [];
    //     postField(allfield);
    //   })
    //   .catch((error) => console.log('error', error));
    postField();
    function postField() {
      const requestOptionsnom = {
        method: "GET",
      };
      // fetch(
      //   `https://nominatim.openstreetmap.org/search?q=${fieldGeometryCenter.lat},${fieldGeometryCenter.lng}&addressdetails=1&format=json`,
      //   requestOptionsnom
      // )
      //   .then((response) => response.text())
      //   .then((result) => {
      //     console.log(JSON.parse('coord nomi:', result));
      //     const data = JSON.parse(result);
      //   })
      //   .catch((err) => console.log('err : ', err));
      var current = Date.now();
      var newField = {};
      if (fieldGeometryType == "polygon") {
        newField.name = FieldName;
        newField.center = fieldGeometryCenter;
        newField.zoom = fieldZoom;
        newField.geometry_type = "polygon";
        newField.geometry = fieldGeometry;
        newField.id = uuidv4();
        newField.created_at = current;
        farmName && (newField.farmName = farmName);
      }
      if (fieldGeometryType == "circle") {
        newField.name = FieldName;
        newField.center = fieldGeometryCenter;
        newField.zoom = fieldZoom;
        newField.geometry_type = "circle";
        newField.radius = circleRadius;
        newField.id = uuidv4();
        newField.created_at = current;
        farmName && (newField.farmName = farmName);
      }
      if (fieldGeometryType !== "circle" && fieldGeometryType !== "polygon") {
        newField.name = FieldName;
        newField.zoom = fieldZoom;
        newField.id = uuidv4();
        newField.created_at = current;
        farmName && (newField.farmName = farmName);
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(newField),
      };
      console.log(newField);
      fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/field`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          setStep(3);
        })
        .catch((error) => console.log("error", error));
    }
  };
  console.log("farmnaene", farmName);
  useEffect(() => {
    setFarmName(farmName);
  }, [farmName]);
  const SetGeometryPolygon = (data) => {
    var polygongeometry = [];
    data?.geometry?.length > 0 &&
      data.geometry.map((e) => {
        polygongeometry.push([e.lat, e.lng]);
      });
    console.log("polygongeometry", polygongeometry);
    return polygongeometry;
  };
  return (
    <div className="relative h-screen  max-sm:z-[51]">
      {step == 1 && (
        <div className="flex flex-col justify-center items-center bg-white h-screen gap-6">
          <div className="flex flex-col justify-center items-center ">
            <img src={SetupFieldImg} className="w-20" />
            <p className="text-2xl text-textDark">{t("setupGiveName")}</p>
          </div>
          {/* <input
            value={FieldName}
            onChange={(event) => setFieldName(event.target.value)}
            className=" w-80  h-16 bg-white px-3  text-lg leading-tight text-textDark border-t-2  rounded-lg shadow-miniMap appearance-none focus:outline-none "
            type="text"
            placeholder={`${t("setupYonca")}..`}
          /> */}
          <TextField
            className="w-80"
            id="user-email"
            placeholder={`${t("setupYonca")}..`}
            type="text"
            value={FieldName}
            onChange={(event) => setFieldName(event.target.value)}
          />
          <p className=" w-80 text-textDark -mb-5">{t("setupAttach")}</p>
          <Select
            className="w-80 "
            value={farmName}
            onChange={(e) => setFarmName((prev) => e.target.value)}
          >
            {Object.keys(farms)?.map((e) => {
              return (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              );
            })}
            {farmName && !Object.keys(farms).includes(farmName) && (
              <MenuItem value={farmName}>{farmName}</MenuItem>
            )}

            <div className="px-3 pt-3">
              <Button
                className="w-full "
                variant="text"
                onClick={() => setCreateModalOpen((prev) => true)}
              >
                + {t("setupCreateFarm")}
              </Button>
            </div>
          </Select>

          <button
            className="ml-[17rem]   p-3"
            onClick={() => setStep(2)}
            disabled={FieldName ? false :true}
          >
            <p className={`text-xl ${FieldName ? "text-green-600" :"text-slate-400"}`}>{t("next")}</p>
            <></>
          </button>
        </div>
      )}
      {step == 2 && (
        <div className="flex flex-col justify-center items-center  bg-white h-screen pt-16 max-sm:pt-4">
          <div className=" h-20 flex flex-row items-center p-3 gap-2 rounded-2xl">
            <img src={SetupFieldImg} className="w-20 h-20" />
            <p className="text-lg text-textLight ">
              {t("setupDrawTool")}{" "}
              <span className="text-textDark font-semibold">{FieldName}</span>{" "}
              {t("setupDrawTool2")}
            </p>
          </div>

          <div className="w-full h-full rounded-2xl  ">
            <div className="absolute w-72 max-sm:w-56 h-10  z-10  right-0 mt-3 mr-16">
              <input
                value={search}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
                className=" p-3 border-b-black w-full h-full bg-neutral-100 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                type="text"
                placeholder={`${t("search")} ..`}
              />
              {searchResult?.map((e) => {
                return (
                  <div
                    key={e.place_id}
                    className=" bg-white p-3"
                    onClick={() => {
                      changePosition(Number(e.lat), Number(e.lon));
                    }}
                  >
                    {" "}
                    {e.display_name}{" "}
                  </div>
                );
              })}
            </div>
            <MapContainer
              center={position}
              zoom={9}
              scrollWheelZoom={true}
              className="w-full h-full z-0 rounded-t-2xl "
              attributionControl={false}
            >
              <FeatureGroup>
                <EditControl
                  onDrawStart={_onDrawStart}
                  position="topright"
                  onEdited={_onEdited}
                  onCreated={_onCreated}
                  onDeleted={_onDeleted}
                  draw={{
                    rectangle: false,
                    circlemarker: false,
                    polyline: false,
                    circle: true,
                    polygon: {
                      showArea: true,
                      showLength: false,
                    },
                    marker: false,
                  }}
                />
              </FeatureGroup>
              <TileLayer url="https://mt1.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}" />
              <AttributionControl position="bottomright" prefix={"Leaflet"} />
              {fields?.map((field) => {
                console.log("setup", field);
                if (field.geometry_type == "polygon") {
                  return (
                    <Polygon
                      positions={SetGeometryPolygon(field)}
                      color="#ffffff"
                      fillColor="#ffffff"
                    />
                  );
                }
              })}
              {/* <Marker position={position}>
                <Popup>
                  A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
              </Marker> */}
              <ResetCenterView selectPosition={searchPosition} />
            </MapContainer>
          </div>
          <div
            className="absolute    bottom-20  py-2 px-14 rounded-lg shadow-miniMap bg-white z-50"
            onClick={() => GeometrySubmit()}
            // onClick={() => setStep(3)}
          >
            <p className="text-xl text-green-600  ">{t("next")}</p>
            <></>
          </div>
        </div>
      )}
      {step == 3 && (
        <div className="flex flex-col justify-center items-center bg-white h-screen gap-0 ">
          <div className="flex flex-col justify-center items-center ">
            <img src={SetupFieldImg} className="w-20" />
            <p className="text-3xl text-textDark">{t("setupFieldReady")}</p>
          </div>
          <p className="text-center border-b-black h-16  px-3 py-2 text-xl  leading-tight text-textLight   ">
            {t("setupSetUpDevice")}
            <div className="justify-center gap-10 flex flex-row mt-5 ">
              <div
                onClick={() => navigate("/setupDevice")}
                className="flex w-20 h-12 px-12 py-7 rounded shadow-miniMap bg-blue-600 text-white  justify-center items-center "
              >
                {t("yes")}
              </div>
              <div
                onClick={() => navigate("/")}
                className="flex w-20 h-12 px-12 py-7 rounded shadow-miniMap  text-textLight f justify-center items-center"
              >
                {t("no")}
              </div>
            </div>
          </p>
        </div>
      )}
      {step == 4 && (
        <div className="flex flex-col justify-center items-center bg-white h-screen gap-6">
          <div className="flex flex-col justify-center items-center ">
            <img src={SetupFieldImg} className="w-20" />
            <div className=" p-3  w-11/12 flex ">
              <p className="text-3xl text-center w-fit  ">
                {t("setupPlaceDevice")}
              </p>
            </div>
          </div>
          <div
            onClick={() => setStep(5)}
            className="flex p-3 h-12 rounded shadow-md bg-green-600 text-white font-bold justify-center items-center "
          >
            {t("setupScanQR")}
          </div>
        </div>
      )}
      {step == 5 && (
        <div className="flex flex-col justify-center items-center bg-white h-screen gap-6">
          <div className="flex flex-col justify-center items-center ">
            <div className=" p-3  w-11/12 flex ">
              <p className="text-3xl text-center w-fit  ">
                {t("setupQRScanner")}
              </p>
            </div>
          </div>
        </div>
      )}
      <Modal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mx-auto  translate-y-3/4  w-1/3 max-sm:w-5/6 bg-white/90 rounded-2xl  backdrop-blur-md  shadow-24 p-6  flex flex-col">
          <p className="text-lg font-semibold text-textDark py-3">
            {t("setupCreateFarmLowercase")}
          </p>
          <TextField
            className="w-full"
            id="user-email"
            label={t("setupFarmName")}
            type="text"
            value={farmName}
            onChange={(event) => setFarmName((prev) => event.target.value)}
          />
          <div className=" mt-6 w-full flex flex-row gap-2 justify-end">
            <Button
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {
                setCreateModalOpen(false);
                setFarmName((prev) => undefined);
              }}
              variant="text"
              sx={{ fontWeight: "600", color: "rgb(69 74 115)" }}
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={() => setCreateModalOpen(false)}
              variant="contained"
              disabled={farmName ? false : true}
              sx={{
                fontWeight: "600",
                background: `${
                  farmName
                    ? "linear-gradient(146deg, #62CBF8 5.53%, #3157F5 91.6%)"
                    : "rgb(69 74 115)"
                }`,
                color: "#FFF !important",
              }}
            >
              {t("setupCreateFarm")}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SetupField;
