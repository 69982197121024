import React, { useContext, useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./DeviceVanaCard.css";
import { Box, Button, MenuItem, Modal, Select, TextField } from "@mui/material";
import Prepare from "../assets/prepare.svg";
import Irrigate from "../assets/irrigate.svg";
import { AccountContext } from "../events/Account";
import PompaSvg from "../assets/pompa.svg"
import { useTranslation } from "react-i18next";
function DevicePompaCard({ data,fieldData }) {
  const {t,i18n} = useTranslation()
  const { apiKey, user_id } = useContext(AccountContext);
  const navigate = useNavigate();
  const [lastData, setLastData] = useState();
  const [tsdata, settsdata] = useState();
  const [loading, setLoading] = useState(true);
  const [pompaStatus, setPompaStatus] = useState(false);
  const [HWNo, seyHWNo] = useState("01");
  const [SancakPacketVersion, setSancakPacketVersion] = useState("001");
  const [SancakPacketVersionOFF, setSancakPacketVersionOFF] = useState("002");
  const [GRDPacketVersion, setGRDPacketVersion] = useState("");
  const [GRDMeasureCount, setGRDMeasureCount] = useState(0);
  const [GRDSleepTimeH, setGRDSleepTimeH] = useState(0);
  const [GRDSleepTimeM, setGRDSleepTimeM] = useState(5);
  const [isCommandModal, setIsCommandModal] = useState(false);
  const [commandLoading, setCommandLoading] = useState(false);
  const [connection, setConnection] = useState(3);
  const [newdata, setnewdata] = useState(false);
  useEffect(() => {
    console.log("id :::", data.id);
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
  
    var requestOptionsGET = {
      method: "GET",
      headers: myHeaders,
    };
  
    const fetchData = async () => {
      try {
        // İlk fetch işlemi
        const response1 = await fetch(
          `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device?deviceId=${data.id}`,
          requestOptionsGET
        )
        const result1 = await response1.json();
        const resultfilter = result1.body;
  
        setLastData(resultfilter);
  
        // İlk fetch işleminin sonucunu kullanarak ikinci fetch işlemi
        const response2 = await fetch(
          `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=pompa&created_at=${data.created_at}`,
          {
            method: "GET",
          }
        )
        const result2 = await response2.json();
        if (result2?.body?.[0]?.status) {
          var lastd = lastData;
          lastd.status = result2.body[0].status;
          setLastData(lastd);
        }
        // İkinci fetch işleminin sonucunu işle
        var obj = result2.body.map((e) => JSON.parse(e));
        console.log("pompa objilk:", obj);
        settsdata(prev=> obj);
        setLoading(false)
      } catch (error) {
        console.log("pompa error", error);
        setLoading(false)
      }
    };
  
    // fetchData fonksiyonunu çağır
    if(connection == 3){
    fetchData();
  }
    // WebSocket bağlantısını oluşturun
    var socket = new WebSocket(`wss://zh906rwmy8.execute-api.eu-west-1.amazonaws.com/production/?user_id=${user_id}&devices=[${data.id}]`);

    // Bağlantı başarıyla kurulduğunda
    socket.addEventListener('open', (event) => {
      console.log('pompa WebSocket bağlantısı başarıyla kuruldu.', event);
    });
    const pongInterval = setInterval(() => {
      socket.send('ping');
    }, 30000); // Her 30 saniyede bir
    // Mesaj alındığında
    socket.addEventListener('message', (event) => {
      setnewdata(prev => true)
      console.log('pompa WebSocketten gelen mesaj:', event);
      var dat = JSON.parse(event.data)
      console.log('pompa dat dat dat 1:', dat);
      var parseddata = {...dat.data, ...dat.data.izci_packet}
      parseddata.status = parseddata.status == 1? "on" : "off"
      console.log('pompa dat dat dat:', parseddata);
      console.log("pompa tsdata",tsdata)
      
      settsdata(prev => [parseddata,...prev])
      setLastData(prev => parseddata)
      

    });

    // Bağlantı kapandığında
    socket.addEventListener('close', (event) => {
      console.log('pompa WebSocket bağlantısı kapatıldı.', event);
      clearInterval(pongInterval);
      
      setTimeout(() => {
        setConnection(prev => !prev)
      }, 5000);
    });

    // Hata oluştuğunda
    socket.addEventListener('error', (event) => {
      console.error('pompa WebSocket hatası:', event);
    });
    console.log("pompa websocet kapalı triggered",socket.readyState); 
    

  }, [connection]);

  function formatDate(date) {
    var mdate = new Date(date);
    mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
    return `       ${mdate.getHours()}:${
      mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    }
     ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    `;
  }
  const CommandFunctionOpenValve = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var tempCont = Number(GRDMeasureCount).toString(16);
    if (tempCont.length == 1) {
      tempCont = `0${tempCont}`;
    }
    var tempSleep = Number(GRDSleepTimeH * 60 + GRDSleepTimeM).toString(16);

    if (tempSleep.length == 1) {
      tempSleep = `000${tempSleep}`;
    } else if (tempSleep.length == 2) {
      tempSleep = `00${tempSleep}`;
    } else if (tempSleep.length == 3) {
      tempSleep = `0${tempSleep}`;
    }
    var body = {
      o: tsdata?.[0]?.ocak_id,
      s: Number(SancakPacketVersion),
      g: Number(HWNo),
      i: data.id,
      d: `${tempSleep.toUpperCase()}`,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    // console.log(body);
    fetch(
      `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/command?id=${data.id}&type=pompa`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setLastData(prev=> {
          var newlast = prev
          newlast.status =  "pending"

          return newlast
        })
        setCommandLoading(false);
      })
      .then(() => setIsCommandModal(false))
      .catch((error) => {
        // console.log("error", error)
      });
  };
  const CommandFunctionCloseValve = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var body = {
      o: tsdata?.[0]?.ocak_id,
      s: Number(SancakPacketVersionOFF),
      g: Number(HWNo),
      i: data.id,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    // console.log(body);
    fetch(
      `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/command?id=${data.id}&type=pompa`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setLastData(prev=> {
          var newlast = prev
          newlast.status =  "pending"

          return newlast
        })
        setCommandLoading(false);
      })
      .then(() => setIsCommandModal(false))
      .catch((error) => {
        // console.log("error", error)
      });
  };
  function timestampToDate(timestamp) {
    var saat = new Date(timestamp).getHours();
    var dakika = new Date(timestamp).getMinutes();
    return `${saat}:${dakika <= 9 ? `0${dakika}` : dakika}`;
  }
  const [isChecked, setIsChecked] = useState(lastData?.status == "on" ? true : false);

  
  const handleCheckboxChange = () => {
    if (lastData?.status != "off") {
      setCommandLoading(true);
      CommandFunctionCloseValve();
    } else {
      setIsCommandModal(true);
    }
    setIsChecked(!isChecked);
    
  };

  return (
    
    <div className="w-48     max-sm:p-0  max-sm:w-[calc(50%-0.5rem)] ">
      {loading || loading || !tsdata?.[0]?.ocak_id ? (
        <div className=" w-full flex max-sm:flex-col flex-row gap-4">
          <div className="w-full ">
            <SkeletonTheme baseColor="#d9d9d9" highlightColor="#fff">
              <Skeleton width={"60%"} height={30} />
              <Skeleton width={"100%"} height={200} />
            </SkeletonTheme>
          </div>
        </div>
      ) : (
        <div className="w-full ">
          <div
            className={`${
              lastData?.status == "on" 
                ? "bg-gradient-to-br from-[#62CBF8] to-[#3157F5] "
                : "bg-white shadow-myShadow3"
            }  rounded-xl p-5 transition duration-300 `}
          >
            <div className="w-full  flex flex-row justify-between">
              <p
                className={`${
                  lastData?.status == "on"  ? "text-white " : "text-textLight"
                }  capitalize `}
              >
                {t(`${lastData?.status}`)}
              </p>
              {/* <div className="w-12 h-6 rounded-full bg-slate-300"></div> */}
              {fieldData?.role !== "viewer" && <div className="">
                <label className="flex cursor-pointer select-none items-center">
                  <div className="relative">
                    <input
                      type="checkbox"
                      checked={lastData?.status == "on"}
                      onChange={handleCheckboxChange}
                      className="sr-only"
                    />
                    <div
                      className={`box block h-6 w-10 rounded-full ${
                        lastData?.status == "on" ? "bg-textDark" : "bg-gray-300"
                      }`}
                    ></div>
                    <div
                      className={`absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-white transition ${
                        lastData?.status == "on" ? "translate-x-full" : ""
                      }`}
                    ></div>
                  </div>
                </label>
              </div>}
            </div>
            <img className={` w-2/3 py-2 max-h-20  `} src={PompaSvg} />
            <p
              className={`${
                lastData?.status == "on" ? "text-white " : "text-textDark"
              }  text-lg w-full break-words  font-semibold  `}
            >
              {data.id.slice(-4)}
            </p>
            <p
              className={`${
                lastData?.status == "on" ? "text-white " : "text-textLight "
              }  text-sm  h-10`}
            >
              {lastData?.status == "on"
                ? `${timestampToDate(
                    Number(lastData?.event_date)
                  )} - ${timestampToDate(
                    Number(lastData?.event_date) +
                      Number(lastData?.watering) * 60000
                  )}`
                : `${t("vanaSetDevice")}`}
            </p>
          </div>
        </div>
      )}

      <Modal
        open={isCommandModal}
        onClose={() => {setIsCommandModal(false);setIsChecked(false);}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="mx-auto translate-y-1/2 w-1/3 max-sm:w-5/6 bg-white/80 rounded-2xl  backdrop-blur-md  shadow-24 p-5  flex flex-col">
          <p className="text-xl w-full text-textDark text-center pt- pb-4">
            {data.id.slice(-4)}
          </p>
          <div className="flex flex-col gap-2">
            {/* <TextField
                  id="outlined-basic"
                  label="Ocak Id"
                  value={tsdata?.[0]?.ocak_id}
                  variant="standard"
                />
                <TextField
                  id="outlined-basic"
                  label="Izci Id"
                  value={data.id}
                  variant="standard"
                />
                <TextField
                  id="outlined-basic"
                  label="Sancak Packet Version"
                  variant="standard"
                  value={SancakPacketVersion}
                  onChange={(event) => {
                    setSancakPacketVersion(event.target.value.toString());
                  }}
                /> */}
            {/* <p className="pt-3 font-bold">GRD Commands </p> */}
            <div className="w-full p-4">
              <img src={PompaSvg} className="w-32 mx-auto" />
            </div>
            <div className="text-sm flex flex-row flex-wrap gap-2 justify-start items-end text-textLight">
              <p className="">{t("irrigate")}</p>

              <Select
                variant="standard"
                id="demo-simple-select"
                value={GRDSleepTimeH}
                sx={{
                  width: "3rem",
                  fontWeight: "600",
                  color: "rgb(69 74 115)",
                }}
                onChange={(event) => {
                  setGRDSleepTimeH(event.target.value);
                }}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                {/* <MenuItem value={13}>13</MenuItem>
                  <MenuItem value={14}>14</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={16}>16</MenuItem>
                  <MenuItem value={17}>17</MenuItem>
                  <MenuItem value={18}>18</MenuItem>
                  <MenuItem value={19}>19</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={21}>21</MenuItem>
                  <MenuItem value={22}>22</MenuItem>
                  <MenuItem value={23}>23</MenuItem>
                  <MenuItem value={24}>24</MenuItem> */}
              </Select>
              <p>{t('hours')}</p>
              <Select
                variant="standard"
                id="demo-simple-select"
                value={GRDSleepTimeM}
                sx={{
                  width: "3rem",
                  fontWeight: "600",
                  color: "rgb(69 74 115)",
                }}
                onChange={(event) => {
                  setGRDSleepTimeM(event.target.value);
                }}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={13}>13</MenuItem>
                <MenuItem value={14}>14</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={16}>16</MenuItem>
                <MenuItem value={17}>17</MenuItem>
                <MenuItem value={18}>18</MenuItem>
                <MenuItem value={19}>19</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={21}>21</MenuItem>
                <MenuItem value={22}>22</MenuItem>
                <MenuItem value={23}>23</MenuItem>
                <MenuItem value={24}>24</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={26}>26</MenuItem>
                <MenuItem value={27}>27</MenuItem>
                <MenuItem value={28}>28</MenuItem>
                <MenuItem value={29}>29</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={31}>31</MenuItem>
                <MenuItem value={32}>32</MenuItem>
                <MenuItem value={33}>33</MenuItem>
                <MenuItem value={34}>34</MenuItem>
                <MenuItem value={35}>35</MenuItem>
                <MenuItem value={36}>36</MenuItem>
                <MenuItem value={37}>37</MenuItem>
                <MenuItem value={38}>38</MenuItem>
                <MenuItem value={39}>39</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={41}>41</MenuItem>
                <MenuItem value={42}>42</MenuItem>
                <MenuItem value={43}>43</MenuItem>
                <MenuItem value={44}>44</MenuItem>
                <MenuItem value={45}>45</MenuItem>
                <MenuItem value={46}>46</MenuItem>
                <MenuItem value={47}>47</MenuItem>
                <MenuItem value={48}>48</MenuItem>
                <MenuItem value={49}>49</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={51}>51</MenuItem>
                <MenuItem value={52}>52</MenuItem>
                <MenuItem value={53}>53</MenuItem>
                <MenuItem value={54}>54</MenuItem>
                <MenuItem value={55}>55</MenuItem>
                <MenuItem value={56}>56</MenuItem>
                <MenuItem value={57}>57</MenuItem>
                <MenuItem value={58}>58</MenuItem>
                <MenuItem value={59}>59</MenuItem>
              </Select>
              <p>{t('minutes')}.</p>
            </div>
          </div>
          <div className=" p-4 w-full flex flex-row gap-2 justify-end">
            <Button
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {setIsCommandModal(false); setIsChecked(false)}}
              variant="text"
              sx={{ fontWeight: "600", color: "rgb(69 74 115)" }}
            >
              {t('cancel')}
            </Button>
            <Button
              sx={{
                fontWeight: "600",
                background:
                  "linear-gradient(146deg, #62CBF8 5.53%, #3157F5 91.6%)",
                boxShadow:"none"
              }}
              disabled={
                SancakPacketVersion === "" ||
                GRDSleepTimeM === "" ||
                GRDSleepTimeH === ""
              }
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {
                CommandFunctionOpenValve();
                setCommandLoading(true);
              }}
              startIcon={commandLoading ? "" : ""}
              variant="contained"
            >
              {commandLoading ? (
                <div className=" flex flex-row flex-wrap">
                  <div className=" m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-5 w-5"></div>
                </div>
              ) : (
                t("apply")
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );

}

export default DevicePompaCard;
