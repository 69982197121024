import React, { useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  Popup,
  useMapEvent,
  FeatureGroup,
  Circle,
  Polygon,
  AttributionControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { map } from "leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import SetupFieldImg from "../assets/setupFieldImg.png";
import { useNavigate } from "react-router-dom";
import Refill from "../assets/refill.svg";
import Optimal from "../assets/optimal.svg";
import Full from "../assets/full.svg";
import Izci from "../assets/Izci2.svg";
import NoInstalled from "../assets/noinstalleddevice.svg";
import NoDrawn from "../assets/nodrawn.svg";
import { useTranslation } from "react-i18next";
import Drop from "../assets/dropwhite.svg";
import IzciShort from "../assets/izciShort.png";

const markerIzciIcon = new L.Icon({
  iconUrl: require("../assets/softwhitemarker.png"),
  iconSize: [20, 20],
  iconAnchor: [10, 10], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});
const markerOcakIcon = new L.Icon({
  iconUrl: require("../assets/softwhitemarker.png"),
  iconSize: [20, 20],
  iconAnchor: [10, 10], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

function FieldCard({ data,allfields }) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [fieldTown, setFieldTown] = useState();
  const [sensorCountTotal, setSensorCount] = useState(0);
  const [irrigationCountTotal, setIrrigationCount] = useState(0);

  var position = [];
  var positionset =
    data?.geometry?.length > 0 &&
    data.geometry.map((e) => {
      position.push([e.lat, e.lng]);
    });
  // console.log("fieldcard : ", data);
  useEffect(() => {
    var sensorCount = 0;
    var irrigationCount = 0;
    if (data && data.devices) {
      for (let i = 0; i < data.devices.length; i++) {
        const e = data.devices[i];
        if (e != undefined) {
          if (e.type === "izci" || e.type === "ocak") {
            sensorCount = sensorCount + 1;
          } else if (
            e.type === "pompa" ||
            e.type === "vana" ||
            e.type === "tof"
          ) {
            irrigationCount = irrigationCount + 1;
          }
        }
      }
    }

    setSensorCount((prev) => sensorCount);
    setIrrigationCount((prev) => irrigationCount);
    const requestOptionsnom = {
      method: "GET",
    };
    // fetch(
    //   `https://nominatim.openstreetmap.org/search?q=${data?.center?.lat},${data?.center?.lng}&addressdetails=1&format=json`,
    //   requestOptionsnom
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     console.log("coord nomi:", result);
    //     setFieldTown(
    //       `${result[0].address.town}, ${result[0].address.province} `
    //     );
    //   })
    //   .catch((err) => console.log("err : ", err));
  }, []);
  return (
    <div
      className="hover:cursor-pointer relative px-0 py-0  w-[calc(25%_-_3rem)]  min-w-[20rem]   h-auto max-sm:w-full bg-  rounded-xl"
      onClick={() => {
        navigate(
          `${
            data.name
              ? data.name
              : `${data.isDevice ? data.devices?.[0]?.id : data.name}`
          }`,
          {
            state: {allfields:allfields,selectedfield:data},
          }
        );
      }}
    >
      <div className="bg- rounded-md flex flex-row  ">
        {data.center?.lat ? (
          <div className="relative w-32 z-[9] h-16 rounded-md ">
            <MapContainer
              center={[data.center.lat, data.center.lng]}
              zoom={data.zoom - 2}
              scrollWheelZoom={false}
              className="w-full h-full rounded-xl z-0"
              dragging={false}
              zoomControl={false}
              attributionControl={false}
            >
              <TileLayer
                url="https://mt1.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"
                // http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}
              />
              {/* <AttributionControl position="bottomright" prefix={"Leaflet"} /> */}
              {data.geometry_type == "polygon" && (
                <Polygon
                  positions={position}
                  color="#ffffff"
                  fillColor="#ffffff"
                />
              )}
              {data.geometry_type == "circle" && (
                <Circle
                  center={data.center}
                  radius={data.radius}
                  color="#ffffff"
                  fillColor="#ffffff"
                />
              )}
              {/* {data.devices
              ? data.devices.map((e) => {
                  console.log('data.devices?.map((da)', e);
                  return e?.coords?.lat ? (
                    <Marker
                      key={e.id}
                      position={[e.coords.lat, e.coords.lon]}
                      icon={e.type == 'izci' ? markerIzciIcon : markerOcakIcon}
                    >
                      <Popup autoClose={false}>
                        A pretty CSS3 popup. <br /> Easily customizable.
                      </Popup>
                    </Marker>
                  ) : (
                    ''
                  );
                })
              : ''} */}
              {data.devices?.[0]?.coords ? (
                <Marker
                  key={data.devices[0].id}
                  position={[
                    data.devices[0].coords?.lat,
                    data.devices[0].coords?.lon,
                  ]}
                  icon={
                    data.devices[0].type == "izci"
                      ? markerIzciIcon
                      : markerOcakIcon
                  }
                >
                  <Popup autoClose={false}>
                    A pretty CSS3 popup. <br /> Easily customizable.
                  </Popup>
                </Marker>
              ) : (
                ""
              )}
              {data.devices?.[1]?.coords ? (
                <Marker
                  key={data.devices[1].id}
                  position={[
                    data.devices[1].coords.lat,
                    data.devices[1].coords.lon,
                  ]}
                  icon={
                    data.devices[1].type == "izci"
                      ? markerIzciIcon
                      : markerOcakIcon
                  }
                >
                  <Popup autoClose={false}>
                    A pretty CSS3 popup. <br /> Easily customizable.
                  </Popup>
                </Marker>
              ) : (
                ""
              )}
              {data?.devices?.[2]?.coords ? (
                <Marker
                  key={data.devices[2].id}
                  position={[
                    data?.devices[2]?.coords?.lat,
                    data?.devices[2]?.coords?.lon,
                  ]}
                  icon={markerOcakIcon}
                >
                  <Popup autoClose={false}>
                    A pretty CSS3 popup. <br /> Easily customizable.
                  </Popup>
                </Marker>
              ) : (
                ""
              )}
            </MapContainer>
          </div>
        ) : (
          <div className="relative flex flex-col  max-sm:w-full bg-slate-50 z-[9] h-40   rounded-xl items-center justify-center ">
            <img src={NoDrawn} className="w-5 mb-3" />
            <p className="text-base font-semibold text-textDark">
              {" "}
              {t("fieldsNoDrawn")}
            </p>
            <p className="text-sm lg:text-xs font-medium w-3/4 text-center text-textLight">
              {t("fieldsDefineField")}
            </p>
          </div>
        )}

        <div className="relative z-0  w-full pl-2 pt-0">
          <div className="relative   flex  ">
            {data.devices?.length > 0 ? (
              <div className="flex flex-col  w-full ">
                <p className=" flex flex-col justify-between">
                  <p className="text-base font-semibold text-textDark">
                    {data.isDevice
                      ? data.devices?.[0]?.id.slice(-4)
                      : data.name}
                  </p>
                  <p className="text-xs text-textLight">
                    {data.farmName ? data.farmName : ""}
                  </p>
                  {data.role && (
                    <p className="text-sm lg:text-xs text-white px-1 py-1 bg-blue-400 rounded-md">
                      {data.role}
                    </p>
                  )}
                </p>
                <div className=" flex flex-row gap-2">
                    <p className="text-xs text-textLight flex flex-row gap-1">
                      <ion-icon name="thermometer-outline"></ion-icon>
                      {irrigationCountTotal}
                      <p className="text-textLight font-normal">
                        {t("irrigation")}
                      </p>
                    </p>
                    <p className="text-xs text-textLight flex flex-row gap-1">
                      <ion-icon name="thermometer-outline"></ion-icon>
                      {sensorCountTotal}
                      <p className="text-textLight font-normal">
                      {t("sensors")}

                      </p>
                    </p>
                    
                  </div>
              </div>
            ) : (
              <p className=" flex flex-col justify-between">
                  <p className="text-base font-semibold text-textDark">
                    {data.isDevice
                      ? data.devices?.[0]?.id.slice(-4)
                      : data.name}
                  </p>
                  <p className="text-xs text-textLight">
                    {data.farmName ? data.farmName : ""}
                  </p>
                  {data.role && (
                    <p className="text-sm lg:text-xs text-white px-1 py-1 bg-blue-400 rounded-md">
                      {data.role}
                    </p>
                  )}
                  <div className="flex flex-row  justify-center items-center  ">
                <img src={NoInstalled} className="w-5   " />
                <p className="text-xs  text-textDark pl-3">
                  {t("fieldsNoInstalled")}
                  {/* <p className="text-xs  text-textLight pt-0">
                    {t("fieldsInstallDevice")}
                  </p> */}
                </p>
              </div>
                </p>
              
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FieldCard;
