import React from 'react'
import App from './App'
import { useLocation } from 'react-router-dom';
function WeatherCard({ lat, lon, lang, unit}) {
 if(lang?.includes("tr")){lang = "tr"}
 if(lang?.includes("en")){lang = "en"}



  if (!lat || !lon ) {
    lat = '39.794305';
    lon = '32.806588';
  }
  if (!lang) {
    lang = 'en';
  }
  if (!unit) {
    unit = 'metric';
  }
  const location = useLocation();
  var path = location.pathname

  return (
    <div style={{width:"100%",height:"100%"} } className='whitespace-nowrap' >
      <App
        lat={lat}
        lon={lon}
        lang={lang}
        unit={unit}
      />
    </div>
  )
}

export default WeatherCard