import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  Popup,
  useMapEvent,
  FeatureGroup,
  Circle,
  Polygon,
  Tooltip,
  AttributionControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { map } from "leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import SetupFieldImg from "../assets/setupFieldImg.png";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ReactWeather, { useOpenWeather } from "react-open-weather";
import Devices from "../components/Devices";
import SettingsImg from "../assets/settings.png";
import NoDrawn from "../assets/nodrawn.svg";
import SettingsIcon from "..//assets/settingsIcon.svg";
import WeatherCard from "../components/WeatherCard/WeatherCard";
import Carousel from "../components/Carousel";
import alertLowBat from "../assets/alertLowBat.png";
import alertNoConnection from "../assets/ alertNoConnection.png";
import alertVana from "../assets/alertVana.png";
import { useTranslation } from "react-i18next";
import FarmDevices from "../components/FarmDevices";
// const markerIzciIcon = new L.Icon({
//   iconUrl: require("../assets/marker-w.png"),
//   iconSize: [40, 40],
//   iconAnchor: [17, 46], //[left/right, top/bottom]
//   popupAnchor: [0, -46], //[left/right, top/bottom]
// });
// const markerOcakIcon = new L.Icon({
//   iconUrl: require("../assets/marker.png"),
//   iconSize: [40, 40],
//   iconAnchor: [17, 46], //[left/right, top/bottom]
//   popupAnchor: [0, -46], //[left/right, top/bottom]
// });
const markerIzciIcon = new L.Icon({
  iconUrl: require("../assets/softpinkmarker.png"),
  iconSize: [20, 20],
  iconAnchor: [10, 10], //[left/right, top/bottom]
  popupAnchor: [0, -10], //[left/right, top/bottom]
});
const markerOcakIcon = new L.Icon({
  iconUrl: require("../assets/softorangemarker.png"),
  iconSize: [20, 20],
  iconAnchor: [10, 10], //[left/right, top/bottom]
  popupAnchor: [0, -10], //[left/right, top/bottom]
});
const markerOptimal = new L.Icon({
  iconUrl: require("../assets/softwhitemarker.png"),
  iconSize: [20, 20],
  iconAnchor: [10, 10], //[left/right, top/bottom]
  popupAnchor: [0, -10], //[left/right, top/bottom]
});
function SelectedFarm() {
  const { t, i18n } = useTranslation();
  console.log("i18n selected field", i18n.language);
  const width = window.innerWidth;
  // Genişlik eşiği 768 piksel olarak belirlendi.
  const isMobile = width < 768;
  const myRef = useRef(null);
  useEffect(() => {
    var navi = document.getElementById("mobilBody");

    if (navi.classList.contains("mobilSmallBody")) {
      return;
    } else {
      myRef.current.scrollIntoView({ behavior: "instant", inline: "start" });
    }
  }, []);
  const navigate = useNavigate();
  const { fieldname } = useParams();
  const location = useLocation();
  const [fieldTown, setFieldTown] = useState();
  console.log("selected FRm : ", fieldname, location);
  var fieldData = location.state && location.state;
  var position = [];
  var positionset =
    fieldData?.geometry?.length > 0 &&
    fieldData.geometry.map((e) => {
      position.push([e.lat, e.lng]);
    });

  console.log("SELECTEDFARMMMMMM :", fieldData);

  useEffect(() => {
    console.log("SELECTEDFARMMMMMM :", fieldData);
    const requestOptionsnom = {
      method: "GET",
    };
    fetch(
      `https://nominatim.openstreetmap.org/search?q=${location.state[0]?.center?.lat},${location.state[0]?.center?.lng}&addressdetails=1&format=json`,
      requestOptionsnom
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("coord nomi:", result);
        setFieldTown(
          `${result[0].address.town}, ${result[0].address.province} `
        );
      })
      .catch((err) => console.log("err : ", err));
  }, []);

  const { data, isLoading, errorMessage, locationLabel } = useOpenWeather({
    key: "649aab4a6e31bce8122c212ee9b978bf",
    // lat: 36.924754,
    // lon: 30.749551,
    lat: fieldData[0]?.center?.lat,
    lon: fieldData[0]?.center?.lng,
    lang: "tr",
    unit: "metric", // values are (metric, standard, imperial)
  });

  const slides = ["3", "4", "5"];

  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth", inline: "start" });
  };
  const setGeometry = (geometry) => {
    var fieldposition = [];
    console.log("geometry", geometry);

    geometry?.map((e) => {
      fieldposition.push([e.lat, e.lng]);
    });
    console.log("fieldposition", fieldposition);
    return fieldposition;
  };
  function calculateAverageCenter(farm) {
    let totalLng = 0;
    let totalLat = 0;
    let centerCount = 0;

    farm.forEach((e) => {
      if (e.center && e.center.lng && e.center.lat) {
        totalLng += e.center.lng;
        totalLat += e.center.lat;
        centerCount++;
      }
    });

    if (centerCount === 0) {
      return null; // Merkez nokta bulunamadı
    }

    const averageLng = totalLng / centerCount;
    const averageLat = totalLat / centerCount;

    return [averageLat, averageLng];
  }
  return (
    <div
      id="section1"
      ref={myRef}
      className="relative sm:px-6 max-sm:p-5 flex flex-col gap-3 lg:pt-0 pt-16 max-sm:pt-0   mx-auto   max-sm:justify-center  lg:px-8 mb-64 "
    >
      <div
        onClick={() => {
          var navi = document.getElementById("mobilBody");

          if (navi.classList.contains("mobilSmallBody")) {
            return;
          } else {
            executeScroll();
          }
        }}
        className="sticky lg:w-full lg:mb-0 w-11/12 mx-auto top-2  z-50  mt-6 -mb-9 flex flex-row justify-between bg-white  bg-opacity-80 shadow-myShadow1 lg:shadow-lg backdrop-blur-[2rem] rounded-2xl items-center h-16 py-11 px-4"
      >
        <div>
          <p className="text-4xl lg:text-lg font-medium  max-sm:text-lg text-textDark ">
            {fieldData[0]?.farmName}
          </p>
          <p className="text-xl lg:text-sm font-medium  max-sm:text-sm text-textLight -mt-1 ">
            {fieldTown}
          </p>
        </div>
        {/* {fieldData.role !== "viewer" && (
          <div
            className="mt-4 w-10 hover:cursor-pointer h-10 max-sm:right-1  max-sm:pt-0 max-sm:mt-2 flex  items-center  "
            onClick={() => {
              navigate(`settings`, {
                state: fieldData,
              });
            }}
          >
            <img
              src={SettingsIcon}
              className="w-10 h-10 bg-white p-2 rounded-lg shadow-miniMap"
            />
          </div>
        )} */}
      </div>
      <div className="relative flex max-sm:flex-col gap-3  lg:flex-col lg:bg-white lg:p-3 lg:rounded-2xl">
        <div className=" w-full max-sm:w-full h-auto  ">
          {console.log("fieldcardrender ici  : ", fieldData)}

          <div className="relative  rounded-md  z-0 shadow-myShadow1 lg:shadow-none">
            <MapContainer
              center={calculateAverageCenter(fieldData)}
              zoom={16}
              scrollWheelZoom={false}
              className="w-full lg:w-2/3 max-sm:w-full h-[31rem] max-sm:h-72 z-0 rounded-2xl"
              dragging={isMobile ?false : true}
              zoomControl={true}
              attributionControl={false}
              
            >
              <TileLayer
                url="https://mt1.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"
                // http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}
              />
              <AttributionControl position="bottomright" prefix={"Leaflet"} />
              {fieldData.map((data) => {
                if (data.geometry_type === "polygon") {
                  return (
                    <Polygon
                      key={data.id} // her elemanın key propu olması önemli
                      positions={setGeometry(data.geometry)}
                      color={data.name == "Parcel Misket 1SerkanErsoy" || data.name == "Parcel Misket 21SerkanErsoy" || data.name == "Parcel 411SerkanErsoy"? "#FF007A":"#ffffff"}
                      fillColor={data.name == "Parcel Misket 11SerkanErsoy" || data.name == "Parcel Misket 21SerkanErsoy" || data.name == "Parcel 411SerkanErsoy" ? "#FF007A":"#ffffff"}
                      weight={2}
                      
                    >
                      <Popup  closeButton={false} autoClose={false}>{data.name}</Popup>
                    </Polygon>
                  );
                } else {
                  return null; // Polygon değilse null döndürmek uygun olabilir
                }
              })}
              {fieldData.map((data) => {
                return data.geometry_type === "circle" ? (
                  <Circle
                    key={data.id}
                    center={data.center}
                    radius={data.radius}
                    color="#ffffff"
                    fillColor="#ffffff"
                  />
                ) : null;
              })}
              {fieldData.map((data) => {
                return data.devices?.map((e) => {
                  console.log(e);
                  return e?.coords ? (
                    <Marker
                      key={e.id}
                      position={[e.coords?.lat, e.coords?.lon]}
                      icon={ markerOptimal}
                    >
                      <Popup autoClose={false}>{e.id.slice(-4)}</Popup>
                    </Marker>
                  ) : null;
                });
              })}
            </MapContainer>
          </div>
        </div>
        {/* <div className="mt-4  sm:mt-2 lg:absolute lg:right-5 lg:bottom-2 z-10 ">
          <div className=" h-[12.5rem] w-full max-w-[22rem] rounded-2xl lg:shadow-none lg:border border-dashed border- bg-white  bg-opacity-50 shadow-myShadow1 backdrop-blur-[39.5px] ">
            <Carousel autoSlide={true} autoSlideInterval={8000}>
              {slides.map((s) => {
                switch (s) {
                  case "3":
                    return (
                      <div
                        key={Math.random()}
                        className=" h-full my-auto w-full flex flex-none  "
                        onClick={() => {
                          navigate(`Haymana%20Mısır/F6AE`, {
                            state: data[2],
                          });
                        }}
                      >
                        <div className=" w-full flex flex-row text-textDark ">
                          <div className="w-9/12 h-full  flex flex-col justify-center pl-7">
                            <div onClick={(event) => event.stopPropagation()}>
                              <p
                                className="text-xs font-semibold text-blue-600"
                                onClick={() => {
                                  navigate(`Haymana Mısır`, {
                                    state: data[0],
                                  });
                                }}
                              >
                                Haymana Mısır
                              </p>
                            </div>

                            <p className="text-md font-medium  ">
                              {t("device")}
                              <span className="text-blue-600"> 74A4</span>{" "}
                              {t("homeBatteryLow")}
                            </p>
                            <p className="pt-4 text-textLight">
                              {t("homeChangeBattery")}
                            </p>
                          </div>
                          <div className="w-3/12 h-full flex items-center pr-3">
                            <img
                              className="absolute  h-32 "
                              src={alertLowBat}
                            />
                          </div>
                        </div>
                      </div>
                    );
                    break;
                  case "4":
                    return (
                      <div
                        key={Math.random()}
                        className=" h-full w-full flex flex-none my-auto"
                        onClick={() => {
                          navigate(`Haymana%20Mısır/F6AE`, {
                            state: data[2],
                          });
                        }}
                      >
                        <div className=" w-full flex flex-row text-textDark ">
                          <div className="w-9/12 h-full  flex flex-col justify-center pl-7">
                            <div onClick={(event) => event.stopPropagation()}>
                              <p
                                className="text-xs font-semibold text-blue-600"
                                onClick={() => {
                                  navigate(`Haymana Mısır`, {
                                    state: data[0],
                                  });
                                }}
                              >
                                Kalecik Üzüm
                              </p>
                            </div>

                            <p className="text-md font-medium  ">
                              {t("device")}
                              <span className="text-blue-600"> 74A4</span>{" "}
                              {t("homeConnectionProblem")}
                            </p>
                            <p className="pt-4 text-textLight">
                              {t("homeCannotSendData")}
                            </p>
                          </div>
                          <div className="w-3/12 h-full flex items-center pr-3">
                            <img
                              className="absolute  h-32 "
                              src={alertNoConnection}
                            />
                          </div>
                        </div>
                      </div>
                    );
                    break;
                  case "5":
                    return (
                      <div
                        key={Math.random()}
                        className=" h-full w-full flex flex-none my-auto"
                        onClick={() => {
                          navigate(`Haymana%20Mısır/F6AE`, {
                            state: data[2],
                          });
                        }}
                      >
                        <div className=" w-full flex flex-row text-textDark ">
                          <div className="w-9/12 h-full  flex flex-col justify-center pl-7">
                            <div onClick={(event) => event.stopPropagation()}>
                              <p
                                className="text-xs font-semibold text-blue-600"
                                onClick={() => {
                                  navigate(`Haymana Mısır`, {
                                    state: data[0],
                                  });
                                }}
                              >
                                Haymana Mısır
                              </p>
                            </div>

                            <p className="text-md font-medium  ">
                              {t("device")}
                              <span className="text-blue-600"> VANA1</span>{" "}
                              {t("homeTurnedOn")}
                            </p>
                            <p className="pt-4 text-textLight">
                              {t("homeOpenedBetween")}
                            </p>
                          </div>
                          <div className="w-5/12 h-full flex items-center pr-3">
                            <img className="  h-auto w-32" src={alertVana} />
                          </div>
                        </div>
                      </div>
                    );
                    break;
                }
              })}
            </Carousel>
          </div>
        </div> */}
        <div className="w-1/3 max-sm:w-full lg:absolute lg:right-0 lg:top-0 z-0">
          {/* <ReactWeather
            isLoading={isLoading}
            errorMessage={errorMessage}
            data={data}
            lang='en'
            locationLabel={fieldTown}
            unitsLabels={{ temperature: '°', windSpeed: 'Km/h' }}
            showForecast
          /> */}
          <div className=" w-full   h-[28rem] max-sm:h-[34rem] -mb-52 ">
            <WeatherCard
              lat={fieldData[0]?.center?.lat}
              lon={fieldData[0]?.center?.lng}
              lang={i18n.language}
            />
          </div>
          <div className="max-sm:hidden flex flex-col justify-center items-center h-[12rem] w-[90%] mx-auto mt-[4.5rem]  rounded-2xl  border-dashed border  ">
            <div className="text-5xl  text-textLight">
            <ion-icon name="notifications-off-circle-outline"></ion-icon>
            </div>
            <p className="text-md text-textLight">{t("notNoNot")}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div
          id="myElement13"
          className="myElement13  z-10  pt-8 pb-4 transition-all duration-300  ease-in-out delay-75"
        >
          <p className="text-4xl text-textDark font-medium ">{t("devices")}</p>
          {/* <div id="bigselam " className="h-16 -mb-6 overflow-hidden ">
            <div className="filterscroll w-full pt-2 h-14   text-textDark text-xs font-bold flex flex-row gap-2  overflow-x-scroll  ">
              <span className="h-fit bg-white px-2 py-1 rounded-md shadow-miniMap ">
                {" "}
                optimal
              </span>
              <span className="h-fit bg-white px-4 py-1 rounded-md shadow-miniMap whitespace-nowrap">
                {t("refill")}
              </span>
              <span className="h-fit bg-white px-4 py-1 rounded-md shadow-miniMap whitespace-nowrap">
                {t("over")}
              </span>
              <span className="h-fit bg-white px-4 py-1 rounded-md shadow-miniMap">
                {" "}
                {t("on")}
              </span>
              <span className=" h-fit bg-white px-4 py-1 rounded-md shadow-miniMap">
                {" "}
                {t("off")}
              </span>
            </div>
          </div> */}
        </div>
        <div>
          <FarmDevices data={fieldData} />
        </div>
      </div>
    </div>
  );
}

export default SelectedFarm;
