import React, { createContext, useContext, useState } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

import Pool from './UserPool';
const AccountContext = createContext();
const Account = (props) => {
  const [status, setStatus] = useState(
    localStorage.getItem('user') == 'true' ? true : false
  );
  const [user_id, setUser_Id] = useState(
    localStorage.getItem('uI') ? localStorage.getItem('uI') : false
  );
  const [userMail, setUserMail] = useState(
    localStorage.getItem('uM') ? localStorage.getItem('uM') : false
  );
  const [apiKey, setApiKey] = useState(
    localStorage.getItem('aK') ? localStorage.getItem('aK') : false
  );
  const [newPassword, setNewPassword] = useState('');
  const [pwChangeRequire, setpwChangeRequire] = useState(false);
  var sessionUserAttributes;
  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
            console.log("1.reject");
            window.location.href = "/login"
            
          } else {
            resolve(session);
            setApiKey(session.idToken.payload['custom:apiKey']);
            setUser_Id(session.idToken.payload['sub']);
            setUserMail(session.idToken.payload['email']);
            setStatus(true);
            localStorage.setItem('user', true);
            localStorage.setItem(
              'aK',
              session.idToken.payload['custom:apiKey']
            );
            localStorage.setItem('uI', session.idToken.payload['sub']);
            localStorage.setItem('uM', session.idToken.payload['email']);
          }
        });
      } else {
        reject();
        console.log("2.reject");
        window.location.href = "/login"
      }
    });
  };

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool,
      });
      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log('onSuccess: ', data);
          resolve(data);
          setStatus(true);
          setApiKey(data.idToken.payload['custom:apiKey']);
          setUser_Id(data.idToken.payload['sub']);
          setUserMail(data.idToken.payload['email']);
          localStorage.setItem('user', true);
          localStorage.setItem('aK', data.idToken.payload['custom:apiKey']);
          localStorage.setItem('uI', data.idToken.payload['sub']);
          localStorage.setItem('uM', data.idToken.payload['email']);
        },
        onFailure: (err) => {
          console.error('onFailure: ', err);
          reject(err);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          delete userAttributes.email_verified;
          delete userAttributes.email; // <--- add this line

          // store userAttributes on global variable
          sessionUserAttributes = userAttributes;
          console.log(
            'newPasswordRequired: ',
            userAttributes,
            requiredAttributes
          );
          setpwChangeRequire(true);
          if (newPassword.length > 0) {
            user.completeNewPasswordChallenge(
              newPassword,
              sessionUserAttributes,
              {
                onSuccess: (result) => {
                  console.log('completenewpasswordchallange: ', result);
                },
                onFailure: (err) => {
                  console.error('onFailure: ', err);
                  reject(err);
                },
              }
            );
          }
          resolve(userAttributes);
        },
      });
    });
  };

  // const changePassword = async (userAttributes, requiredAttributes) => {
  //   user.signOut();
  //   const user = Pool.getCurrentUser();
  //   delete userAttributes.email_verified;
  //   delete userAttributes.email; // <--- add this line

  //   // store userAttributes on global variable
  //   sessionUserAttributes = userAttributes;
  //   user.completeNewPasswordChallenge(newPassword, sessionUserAttributes, {
  //     onSuccess: (result) => {
  //       console.log('completenewpasswordchallange: ', result);
  //     },
  //   });
  // };

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      setStatus(false);
      setApiKey('');
      setUser_Id('');
      setUserMail('');
      localStorage.setItem('user', false);
      localStorage.setItem('aK', false);
      localStorage.setItem('uI', false);
      localStorage.setItem('uM', false);
      localStorage.clear()
    }
  };
  return (
    <div>
      <AccountContext.Provider
        value={{
          authenticate,
          getSession,
          logout,
          status,
          setStatus,
          apiKey,
          setApiKey,
          user_id,
          setUser_Id,
          userMail,
          pwChangeRequire,
          setNewPassword,
          newPassword,
          setpwChangeRequire,
        }}
      >
        {props.children}
      </AccountContext.Provider>
    </div>
  );
};

export { Account, AccountContext };
