import React, { useContext, useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./DeviceVanaCard.css";
import { Box, Button, MenuItem, Modal, Select, TextField } from "@mui/material";
import Prepare from "../assets/prepare.svg";
import Irrigate from "../assets/irrigate.svg";
import VanaStroke from "../assets/vanaStroke.svg";
import vanaBlack from "../assets/vanaBlack.svg";
import { AccountContext } from "../events/Account";
import { useTranslation } from "react-i18next";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { TimeClock } from "@mui/x-date-pickers/TimeClock";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
function DeviceVanaCard({ data, fieldData }) {
  const { t, i18n } = useTranslation();
  const { apiKey, user_id } = useContext(AccountContext);
  const navigate = useNavigate();
  const [lastData, setLastData] = useState();
  const [tsdata, settsdata] = useState();
  const [loading, setLoading] = useState(true);
  const [vanaStatus, setVanaStatus] = useState(false);
  const [HWNo, seyHWNo] = useState("02");
  const [SancakPacketVersion, setSancakPacketVersion] = useState("001");
  const [SancakPacketVersionOFF, setSancakPacketVersionOFF] = useState("002");
  const [GRDPacketVersion, setGRDPacketVersion] = useState("");
  const [GRDMeasureCount, setGRDMeasureCount] = useState(0);
  const [GRDSleepTimeH, setGRDSleepTimeH] = useState(0);
  const [GRDSleepTimeM, setGRDSleepTimeM] = useState(5);
  const [isCommandModal, setIsCommandModal] = useState(false);
  const [isCloseCommandModal, setIsCloseCommandModal] = useState(false);
  const [commandLoading, setCommandLoading] = useState(false);
  const [wateringTime, setWateringTime] = useState(false);
  const [connection, setConnection] = useState(3);
  const [newdata, setnewdata] = useState(false);
  const [vanaDate, setVanaDate] = useState("0000000000000");
  const [isPending, setIsPending] = useState(false);
  const [state, setState] = useState("0");
  const [isStartTime, setIsStartTime] = useState(false);
  const [isPlanIrrigation, setIsPlanIrrigation] = useState(false);
  const [isPicker, setIsPicker] = useState(true);

  useEffect(() => {
    console.log("id :::", data);
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var requestOptionsGET = {
      method: "GET",
      headers: myHeaders,
    };

    const fetchData = async () => {
      try {
        // İlk fetch işlemi
        const response1 = await fetch(
          `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device?deviceId=${data.id}`,
          requestOptionsGET
        );
        const result1 = await response1.json();
        const resultfilter = result1.body;
        console.log("vana dynamo", resultfilter);
        resultfilter?.state &&
          setState((prev) =>
            resultfilter?.state == undefined ? "0" : resultfilter?.state
          );
        setLastData((obj) => resultfilter);

        // İlk fetch işleminin sonucunu kullanarak ikinci fetch işlemi
        const response2 = await fetch(
          `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=vanalast`,
          {
            method: "GET",
          }
        );

        const result2 = await response2.json();

        
        if (JSON.parse(result2?.body?.[0])) {
          var lastd = resultfilter;
          var parsedresult2 = JSON.parse(result2?.body?.[0]);
          // lastd={lastd,"izci_sensor_data_in":parsedresult2.izci_sensor_data_in,"izci_sensor_data_out":parsedresult2.izci_sensor_data_out }
          lastd.izci_sensor_data_in = parsedresult2.izci_sensor_data_in;
          lastd.izci_sensor_data_out = parsedresult2.izci_sensor_data_out;
          lastd.received_date = parsedresult2.received_date;
          setLastData(lastd);
        }
        // İkinci fetch işleminin sonucunu işle
        console.log("vana response2:", JSON.parse(result2.body[0]));

        var obj = result2.body.map((e) => JSON.parse(e));
        console.log("vana objilk:", obj);
        settsdata((prev) => obj);
        setLoading(false);
      } catch (error) {
        console.log("vana error", error);
        setLoading(false);
      }
    };

    // fetchData fonksiyonunu çağır
    if (connection == 3) {
      fetchData();
    }
    // WebSocket bağlantısını oluşturun
    var socket = new WebSocket(
      `wss://zh906rwmy8.execute-api.eu-west-1.amazonaws.com/production/?user_id=${user_id}&devices=[${data.id}]`
    );

    // Bağlantı başarıyla kurulduğunda
    socket.addEventListener("open", (event) => {
      console.log("vana WebSocket bağlantısı başarıyla kuruldu.", event);
    });
    const pongInterval = setInterval(() => {
      socket.send("ping");
    }, 30000); // Her 30 saniyede bir
    // Mesaj alındığında
    socket.addEventListener("message", (event) => {
      setnewdata((prev) => true);
      console.log("vana WebSocketten gelen mesaj:", event);
      var dat = JSON.parse(event.data);
      console.log("vana dat dat dat 1:", dat);
      if (dat.data.state != undefined) {
        setState((prev) => dat.data.state);
      } else {
        var parseddata = { ...dat.data, ...dat.data.izci_packet };
        parseddata.status = parseddata.status == 1 ? "on" : "off";
        if (parseddata.state != undefined) {
          setState((prev) => parseddata.state);
        }
        console.log("vana dat dat dat:", parseddata);
        console.log("vana tsdata", tsdata);
        // if (lastData?.watering) {
        //    parseddata.watering =  Number(GRDSleepTimeH * 60 + GRDSleepTimeM).stringify()

        // }
        settsdata((prev) => [parseddata, ...prev]);
        setLastData((prev) => Object.assign({}, prev, parseddata));
      }
    });

    // Bağlantı kapandığında
    socket.addEventListener("close", (event) => {
      console.log("vana WebSocket bağlantısı kapatıldı.", event);
      clearInterval(pongInterval);

      setTimeout(() => {
        setConnection((prev) => !prev);
      }, 5000);
    });

    // Hata oluştuğunda
    socket.addEventListener("error", (event) => {
      console.error("vana WebSocket hatası:", event);
    });
    console.log("vana websocet kapalı triggered", socket.readyState);
  }, [connection]);

  function formatDate(date) {
    var mdate = new Date(Number(date));
    // console.log("date",mdate);
    mdate.setMinutes(mdate.getMinutes());
    // return `       ${mdate.getHours()}:${
    //   mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    // }
    //  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    // `;
    return `${timeDifference(mdate)},       ${mdate.getHours()}:${
      mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    }
     
    `;
  }
  const VanaCommand = (status) => {
    setVanaStatus(status);
  };

  const StatusFunction = () => {
    if (lastData && lastData.status) {
      if (lastData.status == "on") {
        return <p className="text-green-500">ON</p>;
      } else if (lastData.status == "off") {
        return <p className="text-gray-500">OFF</p>;
      } else if (lastData.status == "pending") {
        return <p className="text-blue-500">pending</p>;
      }
    } else {
      return <p>noData</p>;
    }
  };

  const CommandFunctionOpenValve = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var tempCont = Number(GRDMeasureCount).toString(16);
    if (tempCont.length == 1) {
      tempCont = `0${tempCont}`;
    }

    var tempSleep = Number(GRDSleepTimeH * 60 + GRDSleepTimeM).toString(16);
    var vanaDateHex = parseInt(Number(vanaDate) / 1000).toString(16);
    let commandTime = new Date().getTime();
    var vanaCommandId = parseInt(Number(commandTime)).toString(16);
    console.log("vanaDate Before", vanaDate);
    console.log("vanaDate After", vanaDate / 1000);
    tempSleep = tempSleep.padStart(4, "0");
    vanaDateHex = vanaDateHex.padStart(8, "0");
    console.log(
      "`${vanaDateHex.toUpperCase()}${durationHex.toUpperCase()}`",
      `${vanaDateHex.toUpperCase()}--${tempSleep.toUpperCase()}`
    );
    var body = {
      o: tsdata?.[0]?.ocak_id,
      s: SancakPacketVersion,
      g: HWNo,
      i: data.id,
      d: `${vanaDateHex.toUpperCase()}${tempSleep.toUpperCase()}`,
      cid:vanaCommandId.toUpperCase()
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    console.log(body);
    fetch(
      `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/command?id=${data.id}&type=vana`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        // setLastData((prev) => {
        //   var newlast = prev;
        //   newlast.status = "pending";

        //   return newlast;
        // });

        setCommandLoading(false);
        setConnection((prev) => !prev);
        var tempLast = lastData;
        tempLast.watering = Number(GRDSleepTimeH * 60 + GRDSleepTimeM);
        var NewDate = Date.now().toString();
        tempLast.startTime =
          Number(vanaDate) == 0
            ? parseInt(Number(NewDate))
            : parseInt(Number(vanaDate));
        setLastData((prev) => tempLast);
        setVanaDate("0000000000000");
        setIsPicker((prev) => true);
        setIsPlanIrrigation(prev => false);
      })
      .then(() => setIsCommandModal(false))
      .catch((error) => {
        console.log("error", error);
        setCommandLoading(false);
        setVanaDate("0000000000000");
        setIsPicker((prev) => true);
        setIsPlanIrrigation(prev => false);

      });
  };
  const CommandFunctionCloseValve = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    let commandTime = new Date().getTime();
    var vanaCommandId = parseInt(Number(commandTime)).toString(16);
    var body = {
      o: tsdata?.[0]?.ocak_id,
      s: SancakPacketVersionOFF,
      g: HWNo,
      i: data.id,
      cid: vanaCommandId.toUpperCase(),
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    console.log(body);
    fetch(
      `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/command?id=${data.id}&type=vana`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        // setLastData((prev) => {
        //   var newlast = prev;
        //   newlast.status = "pending";
        //   setIsAction(prev => true)
        //   return newlast;
        // });

        setCommandLoading(false);
      })
      .then(() => setIsCloseCommandModal(false))
      .catch((error) => {
        console.log("error", error);
        setIsCloseCommandModal(false);
      });
  };

  function timestampToDate(timestamp) {
    console.log("timestamp", timestamp);
    var saat = new Date(timestamp).getHours();
    var dakika = new Date(timestamp).getMinutes();
    return `${saat}:${dakika <= 9 ? `0${dakika}` : dakika}`;
  }

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    if (lastData?.status != "off") {
      setIsCloseCommandModal(true);
    } else {
      setIsCommandModal(true);
    }
    // setIsChecked(!isChecked);
  };
  var date = new Date();
  var now_utc = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  console.log("vanaDate", vanaDate);

  const navigateFunction = (id) => {
    var pathname = window.location.pathname;
    var sonIndis = pathname.lastIndexOf("/"); // Son "/" işaretinin indisini buluyoruz
    var yeniDizge = pathname.substring(sonIndis + 1); // Substring metodu ile istenen kısmı alıyoruz
    if (yeniDizge !== id) {
      navigate(`${id}`, {
        state: { ...data, tsdata },
      });
    }
  };
  function timeDifference(previous) {
    var current = new Date().getTime();
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ` ${t("minutes")} ${t("ago")}`;
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ` ${t("hours")} ${t("ago")}`;
    } else if (elapsed < msPerMonth) {
      return " " + Math.round(elapsed / msPerDay) + ` ${t("days")} ${t("ago")}`;
    } else if (elapsed < msPerYear) {
      return (
        " " + Math.round(elapsed / msPerMonth) + ` ${t("months")} ${t("ago")}`
      );
    } else {
      return (
        " " + Math.round(elapsed / msPerYear) + ` ${t("years")} ${t("ago")}`
      );
    }
  }

  const handleOpen = (e) => {
    setIsPicker((prev) => true);
  };

  const handleClose = (e) => {
    setIsPicker((prev) => false);
  };

  const handleAccept = (e) => {


    setIsPicker((prev) => false);
  };
  return (
    <div className="w-48     max-sm:p-0  max-sm:w-[calc(50%-0.5rem)] max-sm:min-w-[12rem] ">
      {loading || !tsdata?.[0]?.ocak_id ? (
        <div className=" w-full flex max-sm:flex-col flex-row gap-4">
          <div className="w-full ">
            <SkeletonTheme baseColor="#d9d9d9" highlightColor="#fff">
              <Skeleton width={"60%"} height={30} />
              <Skeleton width={"100%"} height={200} />
            </SkeletonTheme>
          </div>
        </div>
      ) : (
        <div
          className="w-full "
          onClick={() => {
            navigateFunction(`${data.id.slice(-4)}`, {
              state: { ...data, tsdata },
            });
          }}
        >
          <div
            className={`${
              lastData?.status == "on"
                ? "bg-gradient-to-br from-[#62CBF8] to-[#3157F5] "
                : "bg-white shadow-myShadow1"
            }  rounded-xl p-5 transition duration-300 overflow-hidden`}
          >
            <div className="w-full  flex flex-row justify-between">
              <p
                className={`${
                  lastData?.status == "on" ? "text-white " : "text-textLight"
                }  capitalize `}
              >
                {t(`${lastData?.status}`)}
              </p>
              {/* <div className="w-12 h-6 rounded-full bg-slate-300"></div> */}
              {fieldData?.role !== "viewer" && (
                <div
                  className=""
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <label className="flex cursor-pointer select-none items-center">
                    <div className="relative">
                      <input
                        type="checkbox"
                        checked={lastData?.status == "on"}
                        onChange={handleCheckboxChange}
                        className="sr-only"
                      />

                      <div
                        className={`box block h-6 w-10 rounded-full ${
                          lastData?.status == "on"
                            ? "bg-textDark"
                            : "bg-gray-300"
                        }`}
                      ></div>
                      <div
                        className={`absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-white transition ${
                          lastData?.status == "on" ? "translate-x-full" : ""
                        }`}
                      ></div>
                    </div>
                  </label>
                </div>
              )}
            </div>
            <p
              className={`${
                lastData?.status == "on" ? "text-white" : "text-textLight"
              }  text-xs`}
            >
              {t("fieldsLastMeasurement")}
              <br />
              {/* {alert(JSON.stringify(lastData?.received_date))} */}
              {lastData?.received_date
                ? formatDate(lastData?.received_date)
                : formatDate(tsdata?.[0]?.received_date)}
            </p>
            <div className="flex flex-row ">
              <img className="w-2/3 py-2 -ml-1 mr-1 max-h-20" src={vanaBlack} />

              <div className="flex flex-col w-full text-md  justify-center ">
                <p
                  className={`${
                    lastData?.status == "on"
                      ? "text-textLight bg-white"
                      : "text-textLight bg-gray-300"
                  }  mb-1 text-xs   text-center`}
                >
                  {t("bar")}
                </p>
                <div
                  className={`${
                    lastData?.status == "on" ? "text-white" : "text-textLight"
                  }  flex flex-row justify-evenly `}
                >
                  <p>in</p>
                  <p>out</p>
                </div>
                <div
                  className={`${
                    lastData?.status == "on" ? "text-white" : "text-textDark"
                  }  flex flex-row justify-evenly font-semibold`}
                >
                  <p>{lastData?.izci_sensor_data_in}</p>
                  <p>{lastData?.izci_sensor_data_out}</p>
                </div>
              </div>
            </div>
            {console.log("lastData", lastData)}

            <p
              className={`${
                lastData?.status == "on" ? "text-white " : "text-textDark"
              }  text-lg w-full break-words  font-semibold  `}
            >
              {data.id.slice(-4)}
            </p>
            <p
              className={`${
                lastData?.status == "on" ? "text-white " : "text-blue-500"
              }   text-xs font-semibold `}
            >
              {data?.fieldName}
            </p>
            <p
              className={`${
                lastData?.status == "on" ? "text-white " : "text-textLight "
              }  text-sm  h-10`}
            >
              {state == "0"  || state == "3" ? (
                ""
              ) : (
                <div className="relative ">
                  <div
                    className={`absolute right-0 -mr-32 -mt-16 w-60 h-60  border-2 ${
                      lastData?.status == "on"
                        ? "border-white bg-white"
                        : "border-slate-800"
                    }   rounded-full opacity-0   animate-growAndFade`}
                  ></div>
                  <div
                    className={`absolute right-0 -mr-32 -mt-16 w-60 h-60  border-2 ${
                      lastData?.status == "on"
                        ? "border-white"
                        : "border-slate-800"
                    }   rounded-full opacity-0   animate-growAndFade2`}
                  ></div>
                  <div
                    className={`absolute right-0 -mr-32 -mt-16 w-60 h-60  border-2 ${
                      lastData?.status == "on"
                        ? "border-white"
                        : "border-slate-800"
                    }   rounded-full opacity-0   animate-growAndFade3`}
                  ></div>
                </div>
              )}

              {state == "0"
                ? lastData?.status == "on"
                  ? `${timestampToDate(
                      Number(lastData?.startTime)
                    )} - ${timestampToDate(
                      Number(lastData?.startTime) +
                        Number(lastData?.watering) * 60000
                    )}`
                  : t("vanaSetDevice")
                : state == "1"
                ? t("vanaCommandSendingStation")
                : state == "2"
                ? t("vanaCommandSendingDevice")
                : state == "3"
                ? lastData?.status == "off"
                  ? `${t("vanaWillBeOpened")} ${timestampToDate(
                      Number(lastData?.startTime)
                    )} - ${timestampToDate(
                      Number(lastData?.startTime) +
                        Number(lastData?.watering) * 60000
                    )}${t("vanaWillBeOpened2")}.`
                  : t("vanaWillTurnOff")
                : ""}
            </p>
          </div>
        </div>
      )}

      <Modal
        open={isCommandModal}
        onClose={() => {
          setIsCommandModal(false);
          setIsChecked(false);
          setVanaDate("0000000000000");
          setIsPlanIrrigation((prev) => false);
          setIsPicker((prev) => true);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="mx-auto translate-y-1/2 w-1/3 max-lg:w-4/6 max-sm:w-5/6 bg-white/90 rounded-2xl  backdrop-blur-md  shadow-24 p-5  flex flex-col">
          <p className="text-xl w-full text-textDark text-center pt- pb-4">
            {data.id.slice(-4)}
          </p>
          <div className="flex flex-col gap-2 ">
            {/* <TextField
                  id="outlined-basic"
                  label="Ocak Id"
                  value={tsdata?.[0]?.ocak_id}
                  variant="standard"
                />
                <TextField
                  id="outlined-basic"
                  label="Izci Id"
                  value={data.id}
                  variant="standard"
                />
                <TextField
                  id="outlined-basic"
                  label="Sancak Packet Version"
                  variant="standard"
                  value={SancakPacketVersion}
                  onChange={(event) => {
                    setSancakPacketVersion(event.target.value.toString());
                  }}
                /> */}
            {/* <p className="pt-3 font-bold">GRD Commands </p> */}
            {/* <div className="w-full p-4 max-sm:hidden">
              <img src={vanaBlack} className="w-32 mx-auto" />
            </div> */}
            <div className="px-4 text-sm flex flex-row max-sm:flex-col max-sm:justify-center max-sm:items-start  gap-2 justify-evenly items-end text-textLight">
              <div className="relative h-24 ">
                <p>{t("vanaStart")}</p>
                <div className="hover:!cursor-pointer ">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      // disablePast

                      className="customTimePicker "
                      defaultValue={dayjs(new Date())}
                      ampm={false}
                      open={isPlanIrrigation && isPicker}
                      onAccept={(e) => handleAccept(e)}
                      onClose={(e) => handleClose(e)}
                      onDismiss={(e) => handleClose(e)}
                      onOpen={(e) => handleOpen(e)}
                      onChange={(e) => {
                        var now = new Date();
                        var selected_now = new Date(
                          now.getUTCFullYear(),
                          now.getUTCMonth(),
                          now.getUTCDate(),
                          now.getUTCHours(),
                          now.getUTCMinutes(),
                          now.getUTCSeconds()
                        );
                        var date = new Date(e.$d);
                        var selected_utc = new Date(
                          date.getUTCFullYear(),
                          date.getUTCMonth(),
                          date.getUTCDate(),
                          date.getUTCHours(),
                          date.getUTCMinutes(),
                          date.getUTCSeconds()
                        );
                        console.log("dayı", date.getTime());
                        if (selected_now.getTime() > selected_utc.getTime()) {
                          console.log(
                            "selected_now",
                            selected_now,
                            selected_now.getTime(),
                            "selected_utc",
                            selected_utc,
                            selected_utc.getTime() + 86400000
                          );
                          setVanaDate((prev) => date.getTime() + 86400000);
                        } else {
                          setVanaDate((prev) => date.getTime());
                        }
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div
                  className={`${
                    isPlanIrrigation ? "hidden" : ""
                  }  backdrop-blur-sm absolute bg-gray-200/50 w-full h-full top-0 rounded-lg -ml-4 text-center flex flex-col justify-center items-center`}
                >
                  <div className="text-5xl text-textDark">
                    <ion-icon name="alarm-outline"></ion-icon>
                  </div>
                  <div
                    className="hover:cursor-pointer bg-gradient-to-br from-[#62CBF8] to-[#3157F5]  text-white p-2 rounded-md"
                    onClick={() => {
                      setIsPlanIrrigation((prev) => true);
                    }}
                  >
                    {t("vanaPlanIrrigation")}
                  </div>
                </div>
              </div>

              <div className="flex flex-col h-24 ">
                <p className="mb-2">{t("vanaWatering")}</p>
                <div className="flex flex-row items-end ">
                  {/* <p className="">{t("irrigate")}</p> */}

                  <Select
                    variant="standard"
                    id="demo-simple-select"
                    value={GRDSleepTimeH}
                    sx={{
                      fontWeight: "400",
                      color: "rgb(69 74 115)",
                      fontSize: "3rem",
                      height: "3rem",
                    }}
                    onChange={(event) => {
                      setGRDSleepTimeH(event.target.value);
                    }}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                    {/* <MenuItem value={13}>13</MenuItem>
                    <MenuItem value={14}>14</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={16}>16</MenuItem>
                    <MenuItem value={17}>17</MenuItem>
                    <MenuItem value={18}>18</MenuItem>
                    <MenuItem value={19}>19</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={21}>21</MenuItem>
                    <MenuItem value={22}>22</MenuItem>
                    <MenuItem value={23}>23</MenuItem>
                    <MenuItem value={24}>24</MenuItem> */}
                  </Select>
                  <p className="mr-3">{t("hours")}</p>
                  <Select
                    className="w-"
                    variant="standard"
                    id="demo-simple-select"
                    value={GRDSleepTimeM}
                    sx={{
                      fontWeight: "400",
                      color: "rgb(69 74 115)",
                      fontSize: "3rem",
                      height: "3rem",
                    }}
                    onChange={(event) => {
                      setGRDSleepTimeM(event.target.value);
                    }}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                    <MenuItem value={13}>13</MenuItem>
                    <MenuItem value={14}>14</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={16}>16</MenuItem>
                    <MenuItem value={17}>17</MenuItem>
                    <MenuItem value={18}>18</MenuItem>
                    <MenuItem value={19}>19</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={21}>21</MenuItem>
                    <MenuItem value={22}>22</MenuItem>
                    <MenuItem value={23}>23</MenuItem>
                    <MenuItem value={24}>24</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={26}>26</MenuItem>
                    <MenuItem value={27}>27</MenuItem>
                    <MenuItem value={28}>28</MenuItem>
                    <MenuItem value={29}>29</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={31}>31</MenuItem>
                    <MenuItem value={32}>32</MenuItem>
                    <MenuItem value={33}>33</MenuItem>
                    <MenuItem value={34}>34</MenuItem>
                    <MenuItem value={35}>35</MenuItem>
                    <MenuItem value={36}>36</MenuItem>
                    <MenuItem value={37}>37</MenuItem>
                    <MenuItem value={38}>38</MenuItem>
                    <MenuItem value={39}>39</MenuItem>
                    <MenuItem value={40}>40</MenuItem>
                    <MenuItem value={41}>41</MenuItem>
                    <MenuItem value={42}>42</MenuItem>
                    <MenuItem value={43}>43</MenuItem>
                    <MenuItem value={44}>44</MenuItem>
                    <MenuItem value={45}>45</MenuItem>
                    <MenuItem value={46}>46</MenuItem>
                    <MenuItem value={47}>47</MenuItem>
                    <MenuItem value={48}>48</MenuItem>
                    <MenuItem value={49}>49</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={51}>51</MenuItem>
                    <MenuItem value={52}>52</MenuItem>
                    <MenuItem value={53}>53</MenuItem>
                    <MenuItem value={54}>54</MenuItem>
                    <MenuItem value={55}>55</MenuItem>
                    <MenuItem value={56}>56</MenuItem>
                    <MenuItem value={57}>57</MenuItem>
                    <MenuItem value={58}>58</MenuItem>
                    <MenuItem value={59}>59</MenuItem>
                  </Select>
                  <p>{t("minutes")}.</p>
                </div>
              </div>
            </div>
          </div>
          <div className=" p-4 w-full flex flex-row gap-2 justify-end">
            <Button
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {
                setIsCommandModal(false);
                setIsChecked(false);
                setIsPlanIrrigation((prev) => false);
                setIsPicker((prev) => true);
              }}
              variant="text"
              sx={{ fontWeight: "600", color: "rgb(69 74 115)" }}
            >
              {t("cancel")}
            </Button>
            <Button
              sx={{
                fontWeight: "600",
                background:
                  "linear-gradient(146deg, #62CBF8 5.53%, #3157F5 91.6%)",
              }}
              disabled={
                SancakPacketVersion === "" ||
                GRDSleepTimeM === "" ||
                GRDSleepTimeH === ""
              }
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {
                CommandFunctionOpenValve();
                setCommandLoading(true);

              }}
              startIcon={commandLoading ? "" : ""}
              variant="contained"
            >
              {commandLoading ? (
                <div className=" flex flex-row flex-wrap">
                  <div className=" m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-5 w-5"></div>
                </div>
              ) : (
                t("apply")
              )}
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={isCloseCommandModal}
        onClose={() => {
          setIsCloseCommandModal(false);
          setIsChecked(false);
          setVanaDate("0000000000000");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="mx-auto translate-y-1/2 w-1/3 max-lg:w-4/6 max-sm:w-5/6 bg-white/90 rounded-2xl  backdrop-blur-md  shadow-24 p-5  flex flex-col">
          <p className="text-xl w-full text-textDark text-center pt- pb-4">
            {data.id.slice(-4)}
          </p>
          <div className="flex flex-col justify-center items-center mb-2">
            <img src={VanaStroke} className="w-20  mb-5" />
            <p className="text-textDark ">
              {t("vanaTurnOff")}
            </p>
          </div>
          <div className=" p-4 w-full flex flex-row gap-2 justify-end">
            <Button
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {
                setIsCloseCommandModal(false);
                setIsChecked(false);
              }}
              variant="text"
              sx={{ fontWeight: "600", color: "rgb(69 74 115)" }}
            >
              {t("cancel")}
            </Button>
            <Button
              sx={{
                fontWeight: "600",
                background:
                  "linear-gradient(146deg, #62CBF8 5.53%, #3157F5 91.6%)",
              }}
              disabled={
                SancakPacketVersion === "" ||
                GRDSleepTimeM === "" ||
                GRDSleepTimeH === ""
              }
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {
                CommandFunctionCloseValve();
                setCommandLoading(true);
              }}
              startIcon={commandLoading ? "" : ""}
              variant="contained"
            >
              {commandLoading ? (
                <div className=" flex flex-row flex-wrap">
                  <div className=" m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-5 w-5"></div>
                </div>
              ) : (
                t("apply")
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default DeviceVanaCard;
