import React from 'react';

import { Outlet, Link, useNavigate } from 'react-router-dom';

const SelectedFieldLayout = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default SelectedFieldLayout;
