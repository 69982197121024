import Signup from "./pages/Signup";
import Login from "./pages/Login";
import { Account, AccountContext } from "./events/Account";
import { useContext, useEffect, useRef, useState } from "react";
import Setting from "./pages/Setting";
import ForgotPassword from "./pages/ForgotPassword";
import Timeseries from "./pages/TimeSeries";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginLayout from "./pages/LoginLayout";
import PrivateRoute from "./pages/PrivateRoute";
import { Layout } from "./pages/Layout";
import Home from "./pages/Home2";
import SetupField from "./pages/SetupField";
import SetupDevice from "./pages/SetupDevice";
import SelectedField from "./pages/SelectedField2";
import SelectedDevice from "./pages/SelectedDevice";
import SelectedFieldLayout from "./pages/SelectedFieldLayout";
import DevicesPage from "./pages/DevicesPage2";
import FieldSetting from "./pages/FieldSetting";
import "./App.css";
import Profile from "./pages/Profile";
import PullToRefresh from "react-simple-pull-to-refresh";
import SelectedFarm2 from "./pages/SelectedFarm2";
import SelectedFarm from "./pages/SelectedFarm";

function App() {
  document.documentElement.style.setProperty(
    "--vh",
    window.innerHeight * 0.01 + "px"
  );
  const { status } = useContext(AccountContext);
  const handleRefresh = () => {
    window.location.reload();
  };
  const width = window.innerWidth;
  // Genişlik eşiği 768 piksel olarak belirlendi.
  const isMobile = width < 768;
  return (
    // <PullToRefresh
    //   isPullable={isMobile ? true : false}
    //   onRefresh={handleRefresh}
    //   resistance={7}
    //   pullingContent={
    //     <div className="w-full refresh-icon items-center flex justify-center p-2 rounded-full">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         strokeWidth={1.5}
    //         stroke="currentColor"
    //         className="w-6 h-6"
    //       >
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
    //         />
    //       </svg>
    //     </div>
    //   }
    // >
    //   <ul>
    //     <li>

    //     </li>
    //   </ul>
    // </PullToRefresh>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginLayout />}>
          <Route index path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
        </Route>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route index element={<Home />} />
          <Route path=":fieldname" element={<SelectedFieldLayout />}>
            <Route index element={<SelectedField />} />
            <Route path=":deviceid" element={<SelectedDevice />} />
            <Route path="settings" element={<FieldSetting />} />
          </Route>
          <Route path="farm/:fieldname" element={<SelectedFieldLayout />}>
            <Route index element={<SelectedFarm2 />} />
            <Route path=":deviceid" element={<SelectedDevice />} />
            <Route path="settings" element={<FieldSetting />} />
          </Route>
          <Route path="devices" element={<DevicesPage />} />
          <Route path="setupField" element={<SetupField />} />
          <Route path="SetupDevice" element={<SetupDevice />} />
          <Route path="profile" element={<Profile />} />
          {/* <Route path='izci' element={<IzciLayout />}>
            <Route index element={<Izci />} />
            <Route path=':id' element={<SelectedIzci />} />
          </Route>
          <Route path='user' element={<User />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
{
  /* <Route path='/' element={<Status />}>
{status ? (
  <Route path='/' index element={<Setting />} />
) : (
  <>
    <Route path='/' element={<Login />} />
    <Route path='/signup' element={<Signup />} />
    <Route path='/forgotPassword' element={<ForgotPassword />} />
  </>
)}
<Route path='/timeseries' element={<Timeseries />} />
</Route> */
}
