import { CognitoUser } from 'amazon-cognito-identity-js';
import React, { useState } from 'react';
import { AccounContext } from '../events/Account';
import Pool from '../events/UserPool';
import Logo from '../assets/suyabakan_logo_svg.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const ForgotPassword = () => {
  const [stage, setstage] = useState(1);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [forgotPasswordControl, setForgotPasswordControl] = useState('');
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const getUser = () => {
    return new CognitoUser({
      Username: email.toLocaleLowerCase(),
      Pool,
    });
  };
  const sendCode = (event) => {
    event.preventDefault();

    getUser().forgotPassword({
      onSuccess: (data) => {
        console.log('onSuccess: ', data);
      },
      onFailure: (err) => {
        console.log('onFailure: ', err);
        setForgotPasswordControl('err');
      },
      inputVerificationCode: (data) => {
        console.log('inputVerificationCode: ', data);
        setstage(2);
      },
    });
  };
  const resetPassword = (event) => {
    event.preventDefault();
    if (letterValidated && numberValidated && lengthValidated) {
      console.log('event: ', event, 'code: ', code, 'sifre : ', password);
      getUser().confirmPassword(code, password, {
        onSuccess: (data) => {
          console.log('onSuccess: ', data);
          setForgotPasswordControl('success');
          setLoading(false);
          setTimeout(() => {
            navigate('/');
          }, 1000);
        },
        onFailure: (err) => {
          console.log('onFailure: ', err);
          setForgotPasswordControl('err');
          setLoading(false);
        },
      });
    } else {
      setForgotPasswordControl('passwordNotOK');
      setLoading(false);
    }
  };
  const [letterValidated, setLetterValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);
  const handlePasswordChange = (value) => {
    setPassword(value);
    const letter = new RegExp('(?=.*[A-z])');
    const number = new RegExp('(?=.*[0-9])');
    const length = new RegExp('(?=.{8,})');
    if (letter.test(value)) {
      setLetterValidated(true);
    } else {
      setLetterValidated(false);
    }

    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    if (length.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }
  };

  return (
    <div className=' my-auto bg-[#F8F9FA] h-screen overflow-scroll'>
      <div className='container mx-auto '>
        <div className='flex justify-center px-5 my-12 '>
          <div className='w-full xl:w-3/4 lg:w-11/12 flex '>
            <div className='bg-hero3  w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg'>
              <img src={Logo} className=' block w-40 h-40 mx-auto my-5' />
            </div>

            <div className='w-full lg:w-1/2 bg-white p-0 pb-5 rounded-lg lg:rounded-l-none'>
              <div className='px-8 mb-4 text-center'>
                <img src={Logo} className=' mx-auto my-8 w-32 lg:hidden ' s />

                <h3 className='pt-4 mb-2 text-2xl clear-both text-textDark'>
                  {t('forgotPassword')}
                </h3>
                <p className='mb-4 text-sm text-textLight'>{t('forgotText')}</p>
              </div>
              {stage == 1 && (
                <form
                  onSubmit={(e) => {
                    sendCode(e);
                  }}
                  className='px-8 pt-6 pb-8 mb-4 bg-white rounded'
                >
                  <div className='mb-4'>
                    <label
                      className='block mb-2 text-sm font-bold text-textDark'
                      htmlFor='email'
                    >
                      {t('email')}
                    </label>
                    <input
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      className='w-full px-3 py-3 text-sm  text-textDark border border-textLight/50 rounded-lg  appearance-none focus:outline-none '
                      type='email'
                      placeholder={`${t('email')}..`}
                    />
                  </div>
                  <div className='mb-6 text-center'>
                    <button
                      className='w-full text-sm px-4 py-3 uppercase font-semibold text-white bg-gradient-to-r from-[#62CBF8] to-[#3157F5] rounded-lg  focus:outline-none focus:shadow-outline'
                      type='submit'
                    >
                      {t('sendCode')}
                    </button>
                  </div>
                </form>
              )}
              {stage == 2 &&
                (forgotPasswordControl == 'success' ? (
                  <form
                    onSubmit={resetPassword}
                    className='px-8 pt-6 pb-8 mb-4 bg-white rounded  '
                  >
                    <h1 className='font-bold text-green-600'>
                      {t('passwordChanged')} ;)
                    </h1>
                    <div className='mb-4 invisible'>
                      <label
                        className='block mb-2 text-sm font-bold text-gray-700'
                        htmlFor='email'
                      >
                        {t('verificationCode')}
                      </label>
                      <input
                        value={code}
                        className='w-full px-3 py-3 text-sm  text-textDark border border-textLight/50 rounded-lg  appearance-none focus:outline-none '
                        type='number'
                        placeholder='Enter Verification Code...'
                      />
                      <label
                        className='block mb-2 text-sm font-bold text-gray-700'
                        htmlFor='email'
                      >
                        {t('newPassword')}
                      </label>
                      <input
                        value={password}
                        onChange={(event) =>
                          handlePasswordChange(event.target.value)
                        }
                        className='w-full px-3 py-3 text-sm  text-textDark border border-textLight/50 rounded-lg  appearance-none focus:outline-none '
                        type='password'
                        placeholder={`${t('newPassword')}..`}
                      />
                    </div>

                    <div className='mb-6 text-center invisible'>
                      {forgotPasswordControl == 'err' ? (
                        <p className='text-sm text-red-600'>{t('codeWrong')}</p>
                      ) : (
                        ''
                      )}
                      <button
                        className='w-full text-sm px-4 py-3 uppercase font-semibold text-white bg-gradient-to-r from-[#62CBF8] to-[#3157F5] rounded-lg  focus:outline-none focus:shadow-outline'
                        type='submit'
                      >
                        {t('resetPassword')}
                      </button>
                    </div>
                  </form>
                ) : (
                  <form
                    onSubmit={(e) => {
                      setLoading(true);
                      resetPassword(e);
                    }}
                    className='px-8 pt-6 pb-8 mb-4 bg-white rounded'
                  >
                    <div className='mb-4'>
                      <label
                        className='block mb-2 text-sm font-bold text-gray-700'
                        htmlFor='email'
                      >
                        {t('verificationCode')}
                      </label>
                      <input
                        value={code}
                        onChange={(event) => setCode(event.target.value)}
                        className='w-full px-3 py-3 text-sm  text-textDark border border-textLight/50 rounded-lg  appearance-none focus:outline-none'
                        type='number'
                        placeholder='Enter Verification Code...'
                      />
                      <label
                        className='block mb-2 text-sm font-bold text-gray-700'
                        htmlFor='email'
                      >
                        {t('newPassword')}
                      </label>
                      <input
                        value={password}
                        onChange={(event) =>
                          handlePasswordChange(event.target.value)
                        }
                        className='w-full px-3 py-3 text-sm  text-textDark border border-textLight/50 rounded-lg  appearance-none focus:outline-none'
                        type='password'
                        placeholder='New Password...'
                      />
                      <div className='flex flex-row flex-wrap text-xs text-gray-500'>
                        <p
                          className={
                            letterValidated ? `text-green-500 font-bold` : ``
                          }
                        >
                          {' '}
                          *{t('containALetter')}&nbsp;
                        </p>
                        <p
                          className={
                            numberValidated ? `text-green-500 font-bold` : ``
                          }
                        >
                          {t('andANumber')}&nbsp;
                        </p>
                        <p
                          className={
                            lengthValidated ? `text-green-500 font-bold` : ``
                          }
                        >
                          {t('least8Characters')}
                        </p>
                      </div>
                    </div>

                    <div className='mb-6 text-center'>
                      {forgotPasswordControl == 'err' ? (
                        <p className='text-sm text-red-600'>{t('codeWrong')}</p>
                      ) : (
                        forgotPasswordControl == 'passwordNotOK' && (
                          <p className='text-sm text-red-600'>
                            Password is not OK
                          </p>
                        )
                      )}
                      <button
                        className='w-full text-sm px-4 py-3 uppercase font-semibold text-white bg-gradient-to-r from-[#62CBF8] to-[#3157F5] rounded-lg  focus:outline-none focus:shadow-outline'
                        type='submit'
                        disabled={loading ? true : false}
                      >
                        {loading == true ? (
                          <div className='  '>
                            <div className='m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-6 w-6'></div>
                          </div>
                        ) : (
                          `${t('resetPassword')}`
                        )}
                      </button>
                    </div>
                  </form>
                ))}
              <hr className='mb-6 border-t' />
              <div className='text-center'>
                <Link
                  className='inline-block text-sm text-blue-500 align-baseline hover:text-blue-800'
                  to='/signup'
                >
                  {t('createAnAccount')}
                </Link>
              </div>
              <div className='text-center'>
                <Link
                  className='inline-block text-sm text-blue-500 align-baseline hover:text-blue-800'
                  to='/'
                >
                  {t('alreadyHave')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
