import React from "react";

function HamburgerButton() {
  return (
    <>
      <div
        onClick={() => {
          var navi = document.getElementById("mobilBody");
          var navi2 = document.getElementById("sheetBody");

          if (navi.classList.contains("mobilSmallBody")) {
            navi.classList.remove("mobilSmallBody");
          } else {
            navi.classList.add("mobilSmallBody");
          }
          if (navi2.classList.contains("bottomsheet")) {
            navi2.classList.remove("bottomsheet");
          } else {
            navi2.classList.add("bottomsheet");
          }
        }}
        className="lg:hidden w-10 h-10   top-0 left-3 fixed max-sm:bottom-0  demo1  "
        id="navi"
      >
        <p className="w-10 h-10 text-xl    flex justify-center items-center">
          &#9776;
        </p>
      </div>
      {/* <div
      onClick={() => {
        var navi = document.getElementById("mobilBody");
        
        if(navi.classList.contains("mobilWebBody")){
          navi.classList.remove("mobilWebBody");
        }else{
          navi.classList.add("mobilWebBody");
        }
      }}
      className="max-sm:hidden w-10 h-10   top-0 left-3 fixed max-sm:bottom-0  demo1  "
      id="navi"
    >

      <p className="w-10 h-10 text-xl    flex justify-center items-center">
      &#9776;
      </p>
      
    </div> */}
    </>
  );
}

export default HamburgerButton;
