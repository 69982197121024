import { useState, useEffect } from "react";
import axios from "axios";
import "./style.css";
import SVGComponent from "./WeatherIcon";
import svgIcons from "./svgIcons";
import Damla from "./Asset/Frame.svg";
import Nem from "./Asset/NemB.svg";
import Ruzgar from "./Asset/RuzgarB.svg";
import Yagmur from "./Asset/YagmurB.svg";
import { useLocation } from "react-router-dom";

function App({ lat, lon, lang, unit }) {
  const [weatherData, setWeatherData] = useState(null);
  const [imagePath, setImagePath] = useState("");
  const [groupedWeatherData, setGroupedWeatherData] = useState({});
  const location = useLocation();
  var path = location.pathname
  var pathcounter = path.replace(/[^/]/g, "").length

  useEffect(() => {
    const fetchWeatherData = async () => {
      const apiUrl = `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lon}&appid=649aab4a6e31bce8122c212ee9b978bf&units=${unit}&lang=${lang}`;

      try {
        const response = await axios.get(apiUrl);
        setImagePath(getWeatherImage(response.data.list[0].weather[0].main));
        setWeatherData(response.data);
        console.log("api yaniti :", response.data);
      } catch (error) {
        console.error("Hava durumu verileri alinirken hata oluştu:", error);
      }
    };

    fetchWeatherData();
  }, [lat, lon, lang, unit]);

  const getWeatherImage = (weatherCondition) => {
    const dailyWeatherImages = {
      Clouds: svgIcons.cloudy,
      Clear: svgIcons.sunny,
      Cloudy: svgIcons.cloudy,
      Foggy: svgIcons.fog,
      Rain: svgIcons.rain,
      Snow: svgIcons.snow,
      Wind: svgIcons.windySnow,
      Storm: svgIcons.thunderstorms,
    };
    return dailyWeatherImages[weatherCondition];
  };

  const getWeatherImageWeek = (weatherCondition) => {
    const weeklyWeatherImages = {
      Clouds: svgIcons.cloudy,
      Clear: svgIcons.sunny,
      Cloudy: svgIcons.cloudy,
      Foggy: svgIcons.fog,
      Rain: svgIcons.rain,
      Snow: svgIcons.snow,
      Wind: svgIcons.windySnow,
      Storm: svgIcons.thunderstorms,
    };
    return weeklyWeatherImages[weatherCondition];
  };

  const groupForecastsByDay = (forecasts) => {
    const groupedForecasts = {};
    forecasts.forEach((forecast) => {
      const date = new Date(forecast.dt_txt);
      const dayKey = date.toLocaleDateString({ weekday: "long" });
      if (!groupedForecasts[dayKey]) {
        groupedForecasts[dayKey] = [];
      }
      groupedForecasts[dayKey].push(forecast);
    });
    return groupedForecasts;
  };

  useEffect(() => {
    if (weatherData) {
      const forecastKeys = Object.keys(groupForecastsByDay(weatherData.list));
      const startIndex = 1;
      const endIndex = startIndex + 5;
      const filteredKeys = forecastKeys.slice(startIndex, endIndex);
      const filteredForecasts = filteredKeys.reduce((filtered, key) => {
        filtered[key] = groupForecastsByDay(weatherData.list)[key];
        return filtered;
      }, {});

      setGroupedWeatherData(filteredForecasts);
    }
  }, [weatherData]);

  return (
    <section className={`${pathcounter > 2 || path.includes("devices")? " border-none" : " border-b max-sm:border-slate-300"}  w-full max-w-[24rem]   `} >
      {weatherData && (
        <div className={`${pathcounter > 2 ? "justify-end" : " "} w-full flex flex-row gap-0  max-sm:justify-start rounded-2xl h-32 max-sm:bg-transparent bg-white/0 py-4 px-3`}>
          <div className=" h-full flex flex-col pr-3">
            <div className="flex flex-col leading-none pt-1">
              <p className="text-base font-semibold text-textDark p-0 m-0 leading-none">
              {weatherData.city.name}
              </p>
              <p className="text-xs p-o m-0 leading-none text-textLight whitespace-nowrap">
              {new Date().toLocaleDateString(lang === 'tr' ? "tr-TR" : "en-US", {
                  weekday: "long",
                  day: "numeric",
                  month: "long"
                })}
              </p>
            </div>
            <div className="flex flex-row leading-none  justify-evenly pt-[0.7rem]">
              <div className="w-10">
                <SVGComponent
                  image={imagePath}
                  width="100%"
                  height="100%"
                  viewBox="0 -5 40 40"
                />
              </div>
              <div className="flex flex-col justify-center items-center">
                <p className="text-xs p-0 m-0 leading-none text-textLight ">
                {weatherData.list[0].weather[0].description}
                </p>
                <p className="text-base font-semibold text-textDark p-0 m-0 leading-none">
                {Math.round(weatherData.list[0].main.temp)}°
                </p>
              </div>
            </div>
          </div>
          <div className="w-8/12 pl-0 max-sm:pl- h-full flex flex-col items-center  leading-none">
            <div className=" flex flex-row  items-center gap-6">
              <div className="flex flex-col items-center">
                <p className="text-textLight text-xs">{lang === "tr" ? "Nem" : "Humidity"}</p>
                <p className="text-textDark text-base font-semibold">{weatherData.list[0].main.humidity}%</p>
              </div>
              <div className="flex flex-col items-center">
                <p className="text-textLight text-xs">{lang === "tr" ? "Yağmur" : "Rain"}</p>

                <p className="text-textDark text-base font-semibold">
                  {weatherData.list[0].pop
                    ? `${Math.round(weatherData.list[0].pop * 100)}`
                    : "0"}
                  %
                </p>
              </div>
              <div className="flex flex-col items-center">
                <p className="text-textLight text-xs">{lang === "tr" ? "Rüzgar" : "Wind"}</p>
                <p className="text-textDark text-base font-semibold">
                  {Math.round(weatherData.list[0].wind.speed)}
                  <span className="text-xs">{lang === "tr" ? " km/s" : " km/h"}</span>
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row justify-between font-medium text-textLight">
            {weatherData.list.slice(1, 6).map((hourlyForecast) => {
              const date = new Date(hourlyForecast.dt_txt);
              const hour = date.getHours();
              const weatherIcon = getWeatherImage(hourlyForecast.weather[0].main);

              return (
                <div key={hourlyForecast.dt} className="flex  flex-col items-center  text-center text-xs pt-1">
                  <p>{hour}:00</p>
                  <div className=" w-5 h-5 flex items-center justify-center ">
                    <SVGComponent

                      image={weatherIcon}
                      viewBox={'0 -3 38 37'}
                      width="100%"
                      height="100%"
                      style={true}
                    />
                  </div>
                  <p className="text-textDark">{Math.round(hourlyForecast.main.temp)}°</p>
                </div>
              );
            })}
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default App;
