import React, { useContext, useState } from "react";
import DeviceCard from "./DeviceCard2";
import DeviceOcakCard from "./DeviceOcakCard";
import { Link } from "react-router-dom";
import InstallDevice from "../assets/installnewdevice.svg";
import FarmerWoman from "../assets/farmerwoman.png";
import DeviceVanaCard from "./DeviceVanaCard2";
import DevicePompaCard from "./DevicePompaCard";
import DeviceTofCard from "./DeviceTofCard";
import { useTranslation } from "react-i18next";
import VanaStroke from "../assets/vanaStroke.svg";
import { AppContext } from "../pages/Layout";

function FarmDevices({ data }) {
  const { t, i18n } = useTranslation();
  const [isTof, setIsTof] = useState("");
  const [isVana, setIsVana] = useState("");
  const [isPompa, setIsPompa] = useState("");
  const [isIzci, setIsIzci] = useState("");
  const [isOcak, setIsOcak] = useState("");
  const {
    userApp,
    setUserApp,
    farmsApp,
    setFarmsApp,
    userOcakApp,
    setUserOcakApp,
    userVanaApp,
    setUserVanaApp,
    farmNameApp,
    setFarmNameApp,
    userIzciApp,
    setUserIzciApp,
  } = useContext(AppContext);
  data?.devices?.sort((a, b) =>
    a.izci_length > b.izci_length ? 1 : b.izci_length > a.izci_length ? -1 : 0
  );
  data.devices = data?.devices?.filter(
    (obj) => obj.user_deleted_at == undefined || obj.user_deleted_at == false
  );
  console.log(
    "seribak",
    data?.devices?.filter(
      (obj) => obj.user_deleted_at == undefined || obj.user_deleted_at == false
    )
  );
  console.log("FarmDevices.js", data);
  return (
    <div className="flex flex-row max-sm:flex-col w-full   flex-wrap ">
      <p className="text-textDark font-semibold text-base mb-4">
        {t("irrigation")}
        <p className="text-textLight text-xs font-normal">Set the perfect irrigation.</p>
      </p>
      
      <div className="max-w-[24rem] flex flex-row  flex-wrap gap-4 max-sm:gap-4  max-sm:justify-between  w-full">
        {data?.map((e) => {
          return e.devices?.map((a) => {
            if (a?.type == "tof") {
              if(!isTof){
                setIsTof((prev) => true);
              } 
              return <DeviceTofCard key={a.id} data={a} fieldData={data} />;
            }
          });
        })}
        {data?.map((e) => {
          return e.devices?.map((a) => {
            if (a?.type == "pompa") {
              if(!isPompa){
                setIsPompa((prev) => true);
              } 

              return <DevicePompaCard key={a.id} data={a} fieldData={data} />;
            }
          });
        })}
        {data?.map((e) => {
          return e.devices?.map((a) => {
            if (a?.type == "vana") {
              if(!isVana){
                setIsVana((prev) => true);
                setUserVanaApp((prev) => {
                  if (prev && typeof prev[Symbol.iterator] === 'function') {
                    if (!prev.some((element) => element.id === a.id)) {
                      return [...prev, a];
                    } else {
                      return prev;
                    }
                  } else {
                    return [a];
                  }
                });
              } 
              return <div className=" w-[calc(50%-0.5rem)]"><DeviceVanaCard key={a.id} data={a} fieldData={data} /></div>;
            }
          });
        })}
        {isTof || isVana || isPompa ? (
          ""
        ) : (
          <div className="w-full flex flex-col   z-0 rounded-t-md items-center justify-center ">
            <img src={VanaStroke} className="w-20  mb-5" />
            <p className="text-lg font-semibold text-textDark">
              {t("devicesNoDevice")}
            </p>
            <p className="text-sm font-medium w-3/4 text-center text-textLight">
              {t("devicesInstallDevice")}
              
            </p>
          </div>
        )}
      </div>

      <p className="text-textDark font-semibold text-base mb-4 mt-8 w-full ">
        {t("sensors")}
        <p className="text-textLight text-xs font-normal">Monitor soil and crops.</p>

      </p>
      {/* {data?.map((e) => {
        return e.devices?.map((a) => {
          if (a?.type == "ocak") {
            if(!isOcak){
              setIsOcak((prev) => true);
            } 

            return <DeviceOcakCard key={a.id} data={a} fieldData={data} />;
          }
        });
      })} */}
      {data?.map((e) => {
        return e.devices?.map((a) => {
          if (a?.type == "izci") {
            if(!isIzci){
              setIsIzci((prev) => true);
              setUserIzciApp((prev) => {
                if (prev && typeof prev[Symbol.iterator] === 'function') {
                  if (!prev.some((element) => element.id === a.id)) {
                    return [...prev, a];
                  } else {
                    return prev;
                  }
                } else {
                  return [a];
                }
              });
            }
            

            return <DeviceCard key={a.id} data={a} fieldData={data} />;
          }
        });
      })}
      {isIzci || isOcak ? (
          ""
        ) : (
          <div className="w-full flex flex-col   z-0 rounded-t-md items-center justify-center ">
            <img src={VanaStroke} className="w-20  mb-5" />
            <p className="text-lg font-semibold text-textDark">
              {t("devicesNoDevice")}
            </p>
            <p className="text-sm font-medium w-3/4 text-center text-textLight">
              {t("devicesInstallDevice")}
            </p>
          </div>
        )}
      {/* <div className="p-2  ">
        <Link to="/setupDevice" className="  w-full h-full ">
          <div className="h-full bg-white shadow-myShadow1 rounded-2xl p-16 flex flex-col justify-center items-center">
            <img src={InstallDevice} className="w-32 mb-5" />
            <img src={FarmerWoman} className="w-20" />
          </div>
        </Link>
      </div> */}
    </div>
  );
}

export default FarmDevices;
