import React, { useState, useEffect, useRef, useContext } from "react";

import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  Popup,
  useMapEvent,
  FeatureGroup,
  Circle,
  Polygon,
  Tooltip,
  AttributionControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { map } from "leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import { AccountContext } from "../events/Account";
import { v4 as uuidv4 } from "uuid";
import SetupFieldImg from "../assets/setupFieldImg.png";
import ReactJoyride from "react-joyride";
import DevicesSvg from "..//assets/izciFull.svg";
import MapIcon from "..//assets/mapIcon.svg";
import NoInstalled from "..//assets/noinstalleddevice.svg";
import OcakPng from "..//assets/ocakpanel.png";
import ListSvg from "..//assets/list.svg";
import PompaSvg from "..//assets/pompa.svg";
import VanaSvg from "..//assets/vana.svg";
import TofSvg from "..//assets/tof.svg";
import { useTranslation } from "react-i18next";
import VanaStroke from "../assets/vanaStroke.svg";
import { Button, MenuItem, Modal, Select, TextField } from "@mui/material";
const markerIzciIcon = new L.Icon({
  iconUrl: require("../assets/marker-w.png"),
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});
const markerOcakIcon = new L.Icon({
  iconUrl: require("../assets/marker.png"),
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

function FieldSetting() {
  const { t, i18n } = useTranslation();

  const { state } = useLocation();
  const [stateF, setStateF] = useState(state);
  console.log("seleced basd : ", stateF);
  const { apiKey, user_id } = useContext(AccountContext);
  const navigate = useNavigate();
  const [FieldName, setFieldName] = useState(stateF?.name ? stateF?.name : "");
  const [position, setPosition] = useState([51.505, -0.09]);
  const [devices, setDevices] = useState([]);
  const [isMap, setIsMap] = useState(66);
  const [isModal, setIsModal] = useState(false);
  const [isDeviceModal, setIsDeviceModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState();
  const [status, setStatus] = useState(null);
  const [updatedDevice, setUpdatedDevice] = useState();
  const [farmName, setFarmName] = useState();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [farms, setFarms] = useState([]);
  const steps = [
    {
      target: ".my-first-step",
      disableBeacon: true,
      content: (
        <div className="bg-white h-[40vh]   p-6">
          <img
            className="w-full h-full"
            src="https://media.tenor.com/rJn6n_aR6WMAAAAd/giff.gif"
          />
        </div>
      ),
    },
  ];
  const [fieldGeometry, setFieldGeometry] = useState(
    stateF?.geometry ? stateF?.geometry : []
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("apikey", apiKey);
        myHeaders.append("userid", user_id);

        const requestOptionsGET = {
          method: "GET",
          headers: myHeaders,
        };

        // Kullanıcı verilerini alma
        const userDataResponse = await fetch(
          `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
          requestOptionsGET
        );
        const userData = await userDataResponse.json();

        const activeFields = userData.body.fields.filter(
          (field) => field.deleted_at === undefined
        );

        GetUserFarm(activeFields);
        // setFields(updatedFields);
        // setLoading(false);
      } catch (error) {
        console.log("error", error);

        // Hata durumunda kullanıcıya bilgilendirme yapılabilir
      }
    };

    fetchData();
  }, [apiKey, user_id]);
  useEffect(() => {
    var tempDevicesIds = [];
    var tempDevices = [];
    console.log("stateF", stateF);
    stateF?.devices?.map((d) => {
      tempDevices.push(d);
      tempDevicesIds.push(d.id);
    });

    setDevices(tempDevices);
  }, [stateF]);

  const [fieldGeometryCenter, setFieldGeometryCenter] = useState({});
  const [fieldZoom, setFieldZoom] = useState(stateF?.zoom);
  var geometry = [];
  var geometryCenter = {};
  var leafletId;
  var zoom;
  const _onEdited = (e) => {
    console.log(`_onEdited : `, e);
    console.log(`leafletid : `, leafletId);
    console.log("geometri before edit: ", geometry);
    var editedDevicesCoord = [];
    var editedObj = Object.values(e.layers._layers);
    editedObj.map((obj) => {
      if (obj.editing._poly) {
        console.log(
          `_onEdited : `,
          Object.values(e.layers._layers)[0]._latlngs[0]
        );
        geometryCenter = Object.values(e.layers._layers)[0].getCenter();
        zoom = e.sourceTarget._zoom;
        setFieldGeometry(Object.values(e.layers._layers)[0]._latlngs[0]);
        setFieldGeometryCenter(Object.values(e.layers._layers)[0].getCenter());
        setFieldZoom(e.sourceTarget._zoom);
        stateF.geometry = Object.values(e.layers._layers)[0]._latlngs[0];
        stateF.center = Object.values(e.layers._layers)[0].getCenter();
        stateF.zoom = e.sourceTarget._zoom;
        stateF.role
          ? (stateF.owner_id = stateF.ACL[0].ownerid)
          : (stateF.owner_id = user_id);
        GeometrySubmit();
      }
      if (obj.editing._marker) {
        editedDevicesCoord.push({
          id: obj.options.id,
          type: obj.options.deviceType,
          coords: {
            lat: obj._latlng.lat,
            lon: obj._latlng.lng,
          },
        });
        var tempdevices = stateF.devices;
        tempdevices.find((f, i) => {
          console.log("find", f, i);
          if (f.id == obj.options.id) {
            tempdevices[i].coords = {
              lat: obj._latlng.lat,
              lon: obj._latlng.lng,
            };
            console.log("find method :", f, "index : ", i);
            stateF.devices[i].coords = {
              lat: obj._latlng.lat,
              lon: obj._latlng.lng,
            };
          }
        });
        setUpdatedDevice(editedDevicesCoord);
      }
      console.log("editedDevicesCoords", editedDevicesCoord);
    });
    if (editedDevicesCoord.length > 0) {
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      var tempdevices = stateF.devices;
      editedDevicesCoord.map((editedDevice) => {
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(editedDevice),
        };
        //
        //
        //stateF güncellemesi tam olmuyor bir tane eksik device günvelliyor ona bi bak
        //asyncfıfonksitona cevirelecek önce apidan gelen veirnşn deggerekten yazıldığı bakılcacak sonrasında işse fgele verilere bakııpa iselen yapılacak
        fetch(
          ` https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device/${editedDevice.id}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => console.log("editedDeviceApiResult", result))
          .then(() => {
            console.log("curreneuapsd", stateF);
            tempdevices.find((f, i) => {
              console.log("find", f, i);
              if (f.id == editedDevice.id) {
                tempdevices[i].coords = editedDevice.coords;
                console.log("find method :", f, "index : ", i);
                stateF.devices[i].coords = editedDevice.coords;
              }
            });
            setDevices(tempdevices);
          })
          .catch((error) => console.log("editedDeviceApiError", error));
      });
    }
  };

  const GetUserFarm = (allfield) => {
    const Farms = {};

    // Veriyi "farmName" özelliğine göre grupla
    allfield.forEach((item) => {
      if (item.farmName) {
        if (!Farms[item.farmName]) {
          Farms[item.farmName] = [item];
        } else {
          Farms[item.farmName].push(item);
        }
      }
    });
    console.log("Setup Device FArms:", Farms);
    setFarms((prev) => Farms);
  };
  const _onDrawStop = (e) => {
    console.log(`_onEdited : `, e);
    console.log(`leafletid : `, leafletId);
    console.log("geometri before edit: ", geometry);
    // var editedDevicesCoord=[]
    // var editedObj = Object.values(e.layers._layers)
    // editedObj.map((obj)=>{
    //   if(obj.editing._poly){
    //     console.log(`_onEdited : `, Object.values(e.layers._layers)[0]._latlngs[0]);
    //     geometryCenter = Object.values(e.layers._layers)[0].getCenter();
    //     zoom = e.sourceTarget._zoom;
    //     setFieldGeometry(Object.values(e.layers._layers)[0]._latlngs[0]);
    //     setFieldGeometryCenter(Object.values(e.layers._layers)[0].getCenter());
    //     setFieldZoom(e.sourceTarget._zoom);
    //     stateF.geometry = Object.values(e.layers._layers)[0]._latlngs[0];
    //     stateF.center = Object.values(e.layers._layers)[0].getCenter();
    //     stateF.zoom = e.sourceTarget._zoom;

    //     GeometrySubmit()
    //    }
    //    if(obj.editing._marker){

    //     editedDevicesCoord.push(
    //       {
    //         id:obj.options.id,
    //         type:obj.options.deviceType,
    //         coords: {
    //           lat: obj._latlng.lat,
    //           lon: obj._latlng.lng
    //         }
    //       }
    //       )
    //       var tempdevices = stateF.devices
    //       tempdevices.find((f, i) => {
    //         console.log("find",f,i);
    //         if (f.id == obj.options.id) {
    //           tempdevices[i].coords={
    //           lat: obj._latlng.lat,
    //           lon: obj._latlng.lng
    //         }
    //           console.log("find method :", f, "index : ", i);
    //           stateF.devices[i].coords = {
    //           lat: obj._latlng.lat,
    //           lon: obj._latlng.lng
    //         }

    //         }
    //       });
    //      setUpdatedDevice(editedDevicesCoord)
    //    }
    //    console.log("editedDevicesCoords",editedDevicesCoord);

    // })
    // if(editedDevicesCoord.length >0){
    //   var myHeaders = new Headers();
    //   myHeaders.append("apikey", apiKey);
    //   myHeaders.append("userid", user_id);
    //   var tempdevices = stateF.devices
    //   editedDevicesCoord.map((editedDevice)=>{

    //       var requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         body: JSON.stringify(editedDevice),
    //       };
    //       //
    //       //
    //       //stateF güncellemesi tam olmuyor bir tane eksik device günvelliyor ona bi bak
    //       //asyncfıfonksitona cevirelecek önce apidan gelen veirnşn deggerekten yazıldığı bakılcacak sonrasında işse fgele verilere bakııpa iselen yapılacak
    //       fetch(
    //         ` https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device/${editedDevice.id}`,
    //         requestOptions
    //       )
    //         .then((response) => response.json())
    //         .then((result) => console.log("editedDeviceApiResult",result))
    //         .then(()=>{

    //          console.log("curreneuapsd",stateF);
    //           tempdevices.find((f, i) => {
    //             console.log("find",f,i);
    //             if (f.id == editedDevice.id) {
    //               tempdevices[i].coords=editedDevice.coords
    //               console.log("find method :", f, "index : ", i);
    //               stateF.devices[i].coords = editedDevice.coords

    //             }
    //           });
    //           setDevices(tempdevices)
    //         })
    //         .catch((error) => console.log("editedDeviceApiError", error));

    //   })

    // }
  };

  const _onDeleted = (e) => {
    console.log("_onDeleted : ", e);
  };
  const GeometrySubmit = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var allfield = [];
    var requestOptionsGET = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
      requestOptionsGET
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("neymişbu:", result);
        allfield = result.body.fields ? result.body.fields : [];
        postField(allfield);
        setSaveLoading((prev) => false);
      })
      .catch((error) => {
        console.log("error", error);
        setSaveLoading((prev) => false);
      });
    // postField(allfield);
    function postField(allfield) {
      // allfield.find((f, i) => {
      //   if (f.id == stateF.id) {
      //     console.log("find method :", f, "index : ", i);
      //     delete stateF.devices;
      //     allfield[i] = stateF;
      //   }
      // });
      // var arr = { fields: allfield };

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(stateF),
      };

      fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/field`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
        })
        .catch((error) => console.log("error", error));
    }
  };
  const DeleteField = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var allfield = [];
    var requestOptionsGET = {
      method: "GET",
      headers: myHeaders,
    };

    devices.map((device) => {
      device.deleted_at = Date.now();
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);

      var id = device.id;

      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: JSON.stringify(device),
      };

      fetch(
        ` https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device/${device.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log("delete field result:",result))
        .catch((error) => console.log("error", error));
      //////////////////////////////////////////////////////////////
    });

    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
      requestOptionsGET
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        allfield = result.body.fields ? result.body.fields : [];
        postField(allfield);
      })
      .catch((error) => console.log("error", error));
    // postField(allfield);
    function postField(allfield) {
      allfield.find((f, i) => {
        if (f.id == stateF.id) {
          stateF.deleted_at = Date.now();
          allfield[i] = stateF;
        }
      });
      var arr = { fields: allfield };

      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: JSON.stringify(arr),
      };
      console.log(arr);
      fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/field`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          setStatus(true);
          setDeleteLoading(false);
          navigate("/");
        })
        .catch((error) => {
          console.log(error);
          setStatus(false);
          setDeleteLoading(false);
        });
    }
  };

  const DeviceSubmit = (device) => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var id = device.id;
    device.fieldid = stateF.id;
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: JSON.stringify(device),
    };

    fetch(
      ` https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device/${device.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setDeleteLoading(false);
        setIsModal(false);
        setIsDeviceModal(false);
        stateF.devices = stateF.devices?.filter((f) => f.id != device.id);

        setDevices((prev) => stateF.devices);
      })
      .catch((error) => console.log("error", error));
    //////////////////////////////////////////////////////////////

    var allfield = [];
    var requestOptionsGET = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
      requestOptionsGET
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        allfield = result.body.fields ? result.body.fields : [];
      })
      .catch((error) => console.log("error", error));

    console.log(device);
  };

  function DeleteModal() {
    return (
      <div
        className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
        id="modal-id"
      >
        <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
        <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
          <div className="">
            <div className="text-center p-5 flex-auto justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 -m-1 flex items-center text-red-500 mx-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-16 h-16 flex items-center text-red-500 mx-auto"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <h2 className="text-xl font-bold py-4 ">{t('profileAreYouSure')}</h2>
              <p className="text-sm text-gray-500 px-8">
              {t('fieldSettingsWantDeleteF')}
              </p>
            </div>

            {deleteLoading ? (
              <div className="   w-full h-6 ">
                <div className="m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-700 border-4 h-6 w-6"></div>
              </div>
            ) : status == false ? (
              <p className="w-full text-center">{t('profileWentWrong')}</p>
            ) : (
              <div className="p-3  mt-2 text-center space-x-4 md:block">
                <button
                  onClick={() => setIsModal(false)}
                  className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm  font-medium tracking-wider border text-black rounded-sm  hover:bg-gray-100"
                >
                  {t('cancel')}
                </button>
                <button
                  onClick={() => {
                    setDeleteLoading(true);
                    DeleteField();
                  }}
                  className="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-sm hover:shadow-lg hover:bg-red-600"
                >
                  {t('delete')}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  function DeleteDeviceModal({ device }) {
    return (
      <div
        className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
        id="modal-id"
      >
        <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
        <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
          <div className="">
            <div className="text-center p-5 flex-auto justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 -m-1 flex items-center text-red-500 mx-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-16 h-16 flex items-center text-red-500 mx-auto"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <h2 className="text-xl font-bold py-4 ">
              {t("profileAreYouSure")}</h2>
              <p className="text-sm text-gray-500 px-8">
              {t("fieldSettingsWantDelete")}
              </p>
            </div>

            {deleteLoading ? (
              <div className="   w-full h-6 ">
                <div className="m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-700 border-4 h-6 w-6"></div>
              </div>
            ) : status == false ? (
              <p>
              {t("profileWentWrong")}</p>
            ) : (
              <div className="p-3  mt-2 text-center space-x-4 md:block">
                <button
                  onClick={() => setIsDeviceModal(false)}
                  className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm  font-medium tracking-wider border text-black rounded-sm  hover:bg-gray-100"
                >
                  
                  {t("cancel")}
                </button>
                <button
                  onClick={() => {
                    setDeleteLoading(true);
                    DeviceSubmit(device);
                  }}
                  className="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-sm hover:shadow-lg hover:bg-red-600"
                >
                  {t("delete")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="flex flex-col gap-3  max-sm:pt-0 mx-auto    max-sm:justify-center   ">
        {isMap == true ? (
          <label
            onClick={() => setIsMap(false)}
            className="z-50 fixed top-2 rounded-lg px-6 left-1/2 -translate-x-1/2 bg-white  inline-flex items-center p-2  cursor-pointer text-black"
          >
            <div className="w-9 h-9  text-xl  rounded-sm flex flex-row items-center justify-center">
            <ion-icon name="settings-outline"></ion-icon>
            </div>
          </label>
        ) : (
          <label
            onClick={() => setIsMap(true)}
            className="absolute top-12 right-8 rounded-md  inline-flex items-center p-2  cursor-pointer text-textDark"
          >
            <div className="w-20 h-10 bg-white rounded-md flex flex-row items-center justify-center">
              <div className="fill-slate-600 items-center justify-center h-7 flex flex-row w-7">
              <ion-icon name="map-outline"></ion-icon>
              </div>
              {t("map")}

            </div>
          </label>
        )}
        <div className="w-full hfull  ">
          {isMap == true ? (
            stateF?.center?.lat ? (
              <div className="map bg-purple-600 w-full h-screen  ">
                {/* <ReactJoyride
              steps={steps}
              continuous={true}
              styles={{
                options: {
                  arrowColor: "rgba(255, 255, 255, 0.7)",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  overlayColor: "rgba(0, 0, 0, 0.5)",
                  primaryColor: "#000",
                  textColor: "#004a14",
                  width: "undefined",
                  zIndex: 1000,
                },
              }}
            /> */}
                <MapContainer
                  center={[stateF.center.lat, stateF.center.lng]}
                  zoom={fieldZoom}
                  scrollWheelZoom={true}
                  className="w-full h-full z-0 my-first-step"
                  attributionControl={false}
                >
                  <FeatureGroup>
                    <EditControl
                      position="topright"
                      onEdited={_onEdited}
                      onDeleted={_onDeleted}
                      onDrawStop={_onDrawStop}
                      edit={{ remove: false }}
                      draw={{
                        rectangle: false,
                        circlemarker: false,
                        polyline: false,
                        circle: false,
                        polygon: false,
                        marker: false,
                      }}
                    />
                    <AttributionControl
                      position="bottomright"
                      prefix={"Leaflet"}
                    />
                    {stateF?.geometry_type == "polygon" && (
                      <Polygon
                        draw={"edited"}
                        positions={stateF.geometry}
                        eventHandlers={{
                          click: (e) => {
                            console.log("marker clicked", e);
                          },
                        }}
                      />
                    )}
                    {stateF?.geometry_type == "circle" && (
                      <Circle
                        draw={"edited"}
                        center={stateF.center}
                        radius={stateF.radius}
                        eventHandlers={{
                          click: (e) => {
                            console.log("marker clicked", e);
                          },
                        }}
                      />
                    )}
                    {devices
                      ? devices.map((e) => {
                          console.log("stateF.devices?.map((da)", e);
                          return e?.coords?.lat ? (
                            <Marker
                              key={e.id}
                              id={e.id}
                              deviceType={e.type}
                              position={[e.coords.lat, e.coords.lon]}
                              icon={
                                e.type == "izci"
                                  ? markerIzciIcon
                                  : markerOcakIcon
                              }
                            >
                              <Tooltip
                                direction="top"
                                offset={[0, -45]}
                                opacity={0.8}
                                permanent
                                className="my-other-step"
                              >
                                {e.id.slice(-4)}
                              </Tooltip>
                            </Marker>
                          ) : (
                            ""
                          );
                        })
                      : ""}
                  </FeatureGroup>
                  <TileLayer url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}" />
                </MapContainer>
              </div>
            ) : (
              <div className="map bg-purple-600 w-full h-screen  ">
                {/* <ReactJoyride
                steps={steps}
                continuous={true}
                styles={{
                  options: {
                    arrowColor: "rgba(255, 255, 255, 0.7)",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    overlayColor: "rgba(0, 0, 0, 0.5)",
                    primaryColor: "#000",
                    textColor: "#004a14",
                    width: "undefined",
                    zIndex: 1000,
                  },
                }}
              /> */}
                <MapContainer
                  center={[39.794305, 32.806588]}
                  zoom={7}
                  scrollWheelZoom={true}
                  className="w-full h-full z-0 my-first-step"
                  attributionControl={false}
                >
                  <FeatureGroup>
                    <EditControl
                      position="topright"
                      onEdited={_onEdited}
                      onDeleted={_onDeleted}
                      onDrawStop={_onDrawStop}
                      edit={{ remove: false }}
                      draw={{
                        rectangle: false,
                        circlemarker: false,
                        polyline: false,
                        circle: false,
                        polygon: false,
                        marker: false,
                      }}
                    />
                    <AttributionControl
                      position="bottomright"
                      prefix={"Leaflet"}
                    />
                    {stateF?.geometry_type == "polygon" && (
                      <Polygon
                        draw={"edited"}
                        positions={stateF.geometry}
                        eventHandlers={{
                          click: (e) => {
                            console.log("marker clicked", e);
                          },
                        }}
                      />
                    )}
                    {stateF?.geometry_type == "circle" && (
                      <Circle
                        draw={"edited"}
                        center={stateF.center}
                        radius={stateF.radius}
                        eventHandlers={{
                          click: (e) => {
                            console.log("marker clicked", e);
                          },
                        }}
                      />
                    )}
                    {devices
                      ? devices.map((e) => {
                          console.log("stateF.devices?.map((da)", e);
                          return e?.coords?.lat ? (
                            <Marker
                              key={e.id}
                              id={e.id}
                              deviceType={e.type}
                              position={[e.coords.lat, e.coords.lon]}
                              icon={
                                e.type == "izci"
                                  ? markerIzciIcon
                                  : markerOcakIcon
                              }
                            >
                              <Tooltip
                                direction="top"
                                offset={[0, -45]}
                                opacity={0.8}
                                permanent
                                className="my-other-step"
                              >
                                {e.id.slice(-4)}
                              </Tooltip>
                            </Marker>
                          ) : (
                            ""
                          );
                        })
                      : ""}
                  </FeatureGroup>
                  <TileLayer url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}" />
                </MapContainer>
              </div>
            )
          ) : (
            <div className="flex flex-col max-sm:flex-col max-sm:mt-4  mx-auto  sm:px-6 max-sm:p-5   lg:px-8 lg:pt-8 lg:pb-20 md:pt-16">
              <div className="w-full h-80 bg-bgoverview14  bg-top  bg-cover rounded-2xl   ">
                <div className=" pt-6 px-5 w-full flex flex-row justify-between ">
                  <p className="text-2xl  text-white font-medium ">
                  {t("fieldSettings")}

                  </p>
                  {/* <div className=" flex flex-row">
                    <select className="block  mr-4 text-white bg-white/0">
                      <option value="tr-TR">TR</option>
                      <option value="en-EN">EN</option>
                    </select>
                    <div className="w-9 h-9 bg-white rounded-md text-2xl flex items-center justify-center shadow-miniMap">
                      <ion-icon name="notifications-outline"></ion-icon>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="panel -mt-48 max-sm:-mt-36 z-10  w-full max-sm:w-full  pt-10 max-sm:px-0 max-sm:pt-5 flex flex-col  gap-5 center  overflow-y-auto ">
                  <div className="flex flex-col   mx-80 max-sm:mx-6 py-6 px-5  justify-between  z-50 rounded-2xl  bg-white  bg-opacity-80 shadow-myShadow1 backdrop-blur-[20px] ">
                    <div className="flex flex-row max-sm:flex-col">
                      <div className="flex flex-col w-1/2 max-sm:w-full">
                        <p className="text-md text-textDark font-semibold mb-2 ">
                          {t("profileFieldName")}
                        </p>

                        <TextField
                          id="user-email"
                          type="text"
                          value={FieldName}
                          onChange={(event) => {
                            setFieldName(event.target.value);
                            stateF.name = event.target.value;
                          }}
                        />
                      </div>
                      <div className="flex flex-col ml-5 w-1/2 max-sm:w-full max-sm:m-0 max-sm:mt-4">
                        <p className="text-md text-textDark font-semibold mb-2 ">
                        {t("profileFarmName")}

                        </p>

                        <Select
                          className="w-full "
                          value={farmName}
                          defaultValue={stateF?.farmName}
                          onChange={(e) => {
                            setFarmName((prev) => e.target.value);
                            stateF.farmName = e.target.value;
                          }}
                        >
                          {Object.keys(farms)?.map((e) => {
                            return (
                              <MenuItem key={e} value={e}>
                                {e}
                              </MenuItem>
                            );
                          })}
                          {farmName &&
                            !Object.keys(farms).includes(farmName) && (
                              <MenuItem value={farmName}>{farmName}</MenuItem>
                            )}

                          <div className="px-3 pt-3">
                            <Button
                              className="w-full "
                              variant="text"
                              onClick={() => setCreateModalOpen((prev) => true)}
                            >
                              + {t("fieldSettingsCreateFarm")}
                            </Button>
                          </div>
                        </Select>
                      </div>
                    </div>
                    <Button
                      color="primary"
                      className="!mt-4"
                      onClick={() => {
                        GeometrySubmit();
                        setSaveLoading((prev) => true);
                      }}
                      variant="contained"
                      sx={{
                        fontWeight: "600",
                        background:
                          "linear-gradient(146deg, #62CBF8 5.53%, #3157F5 91.6%)",
                      }}
                    >
                      {saveLoading ? (
                        <div className="   w-full h-6 ">
                          <div className="m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-6 w-6"></div>
                        </div>
                      ) : (
                        t("save")
                      )}
                    </Button>
                  </div>
                  <div className="flex flex-row bg-white p-6  rounded-2xl w-full">
                    <div className="flex flex-col w-1/2 max-sm:w-full">
                      <p className="text-md text-textDark font-semibold  ">
                        {t("fieldSettingsDeviceProp")}
                      </p>
                      <p className="text-md text-textLight  mb-2 ">
                      {t("fieldSettingsConfDeviceProp")}

                      </p>
                      <div className="w-full max-sm:items-center max-sm:justify-center flex flex-col flex-wrap gap-3 content-start">
                        {devices.length > 0 ? (
                          devices?.map((device) => {
                            console.log("devices", devices);
                            return device?.id ? (
                              <div
                                key={device.id}
                                className="bg-gray-50 shadow-lg  rounded-lg flex flex-row  w-full  p-3 h-20 justify-between overflow-clip  "
                              >
                                <p className="text-textDark font-semibold self-start">
                                  {device.id.slice(-4)}
                                </p>
                                <img
                                  className=" w-16 self-center "
                                  src={
                                    device.type == "izci"
                                      ? DevicesSvg
                                      : device.type == "ocak"
                                      ? OcakPng
                                      : device.type == "vana"
                                      ? VanaSvg
                                      : device.type == "pompa"
                                      ? PompaSvg
                                      : device.type == "tof"
                                      ? TofSvg
                                      : ""
                                  }
                                />
                                {/* <button
                              className="bg-gray-900 w-24 h-12 text-white rounded-sm self-center"
                              onClick={() => {
                                setSelectedDevice(device);
                                setIsDeviceModal(true);
                              }}
                            >
                              Kaldır
                            </button> */}
                                <div className="flex flex-row items-center justify-center gap-2">
                                  <button
                                    className="flex items-center gap-1 justify-center rounded-md   p-2 hover:shadow-lg border border-red-700 text-red-700  self-center "
                                    onClick={() => {
                                      setSelectedDevice(device);
                                      setIsDeviceModal(true);
                                    }}
                                  >
                                    <ion-icon name="trash-bin-outline"></ion-icon>
                                  </button>
                                </div>
                              </div>
                            ) : (
                              ""
                            );
                          })
                        ) : (
                          <div className="w-full p-6 flex flex-col border-2  border-dashed  z-0 rounded-t-md items-center justify-center ">
                            <img src={VanaStroke} className="w-20  mb-5" />
                            <p className="text-lg font-semibold text-textDark">
                              {t("devicesNoDevice")}
                            </p>
                            <p className="text-sm font-medium w-3/4 text-center text-textLight">
                              {t("devicesInstallDevice")}
                            </p>
                            <Link
                              to="/setupDevice"
                              className=" font-semibold px-10 py-3 bg-blue-500 text-white mt-2 rounded-md"
                            >
                              {t("navSetupDevice")}
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                    {stateF?.center?.lat ? (
                      <div className="map bg-white pl-6 rounded-2xl w-1/2 h-full min-h-[35rem] max-sm:hidden ">
                        {/* <ReactJoyride
              steps={steps}
              continuous={true}
              styles={{
                options: {
                  arrowColor: "rgba(255, 255, 255, 0.7)",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  overlayColor: "rgba(0, 0, 0, 0.5)",
                  primaryColor: "#000",
                  textColor: "#004a14",
                  width: "undefined",
                  zIndex: 1000,
                },
              }}
            /> */}
                        <MapContainer
                          center={[stateF.center.lat, stateF.center.lng]}
                          zoom={fieldZoom - 1}
                          scrollWheelZoom={true}
                          className="w-full h-full z-0 my-first-step rounded-xl"
                          attributionControl={false}
                        >
                          <FeatureGroup>
                            <EditControl
                              position="topright"
                              onEdited={_onEdited}
                              onDeleted={_onDeleted}
                              onDrawStop={_onDrawStop}
                              edit={{ remove: false }}
                              draw={{
                                rectangle: false,
                                circlemarker: false,
                                polyline: false,
                                circle: false,
                                polygon: false,
                                marker: false,
                              }}
                            />
                            <AttributionControl
                              position="bottomright"
                              prefix={"Leaflet"}
                            />
                            {stateF?.geometry_type == "polygon" && (
                              <Polygon
                                draw={"edited"}
                                positions={stateF.geometry}
                                eventHandlers={{
                                  click: (e) => {
                                    console.log("marker clicked", e);
                                  },
                                }}
                              />
                            )}
                            {stateF?.geometry_type == "circle" && (
                              <Circle
                                draw={"edited"}
                                center={stateF.center}
                                radius={stateF.radius}
                                eventHandlers={{
                                  click: (e) => {
                                    console.log("marker clicked", e);
                                  },
                                }}
                              />
                            )}
                            {devices
                              ? devices.map((e) => {
                                  console.log("stateF.devices?.map((da)", e);
                                  return e?.coords?.lat ? (
                                    <Marker
                                      key={e.id}
                                      id={e.id}
                                      deviceType={e.type}
                                      position={[e.coords.lat, e.coords.lon]}
                                      icon={
                                        e.type == "izci"
                                          ? markerIzciIcon
                                          : markerOcakIcon
                                      }
                                    >
                                      <Tooltip
                                        direction="top"
                                        offset={[0, -45]}
                                        opacity={0.8}
                                        permanent
                                        className="my-other-step"
                                      >
                                        {e.id.slice(-4)}
                                      </Tooltip>
                                    </Marker>
                                  ) : (
                                    ""
                                  );
                                })
                              : ""}
                          </FeatureGroup>
                          <TileLayer url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}" />
                        </MapContainer>
                      </div>
                    ) : (
                      <div className="map bg-purple-600 w-full h-screen  ">
                        {/* <ReactJoyride
                steps={steps}
                continuous={true}
                styles={{
                  options: {
                    arrowColor: "rgba(255, 255, 255, 0.7)",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    overlayColor: "rgba(0, 0, 0, 0.5)",
                    primaryColor: "#000",
                    textColor: "#004a14",
                    width: "undefined",
                    zIndex: 1000,
                  },
                }}
              /> */}
                        <MapContainer
                          center={[39.794305, 32.806588]}
                          zoom={7}
                          scrollWheelZoom={true}
                          className="w-full h-full z-0 my-first-step"
                          attributionControl={false}
                        >
                          <FeatureGroup>
                            <EditControl
                              position="topright"
                              onEdited={_onEdited}
                              onDeleted={_onDeleted}
                              onDrawStop={_onDrawStop}
                              edit={{ remove: false }}
                              draw={{
                                rectangle: false,
                                circlemarker: false,
                                polyline: false,
                                circle: false,
                                polygon: false,
                                marker: false,
                              }}
                            />
                            <AttributionControl
                              position="bottomright"
                              prefix={"Leaflet"}
                            />
                            {stateF?.geometry_type == "polygon" && (
                              <Polygon
                                draw={"edited"}
                                positions={stateF.geometry}
                                eventHandlers={{
                                  click: (e) => {
                                    console.log("marker clicked", e);
                                  },
                                }}
                              />
                            )}
                            {stateF?.geometry_type == "circle" && (
                              <Circle
                                draw={"edited"}
                                center={stateF.center}
                                radius={stateF.radius}
                                eventHandlers={{
                                  click: (e) => {
                                    console.log("marker clicked", e);
                                  },
                                }}
                              />
                            )}
                            {devices
                              ? devices.map((e) => {
                                  console.log("stateF.devices?.map((da)", e);
                                  return e?.coords?.lat ? (
                                    <Marker
                                      key={e.id}
                                      id={e.id}
                                      deviceType={e.type}
                                      position={[e.coords.lat, e.coords.lon]}
                                      icon={
                                        e.type == "izci"
                                          ? markerIzciIcon
                                          : markerOcakIcon
                                      }
                                    >
                                      <Tooltip
                                        direction="top"
                                        offset={[0, -45]}
                                        opacity={0.8}
                                        permanent
                                        className="my-other-step"
                                      >
                                        {e.id.slice(-4)}
                                      </Tooltip>
                                    </Marker>
                                  ) : (
                                    ""
                                  );
                                })
                              : ""}
                          </FeatureGroup>
                          <TileLayer url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}" />
                        </MapContainer>
                      </div>
                    )}
                  </div>

                  <div className="flex flex-row items-center justify-center gap-2">
                    <button
                      className="flex items-center gap-1 justify-center rounded-sm   p-2 hover:shadow-lg border border-red-700 text-red-700  self-center "
                      onClick={() => setIsModal(true)}
                    >
                      <ion-icon name="trash-bin-outline"></ion-icon>
                      {t("fieldSettingsDeleteField")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isModal ? <DeleteModal /> : ""}
          {isDeviceModal ? <DeleteDeviceModal device={selectedDevice} /> : ""}
        </div>
      </div>
      <Modal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mx-auto  translate-y-3/4  w-1/3 max-sm:w-5/6 bg-white/90 rounded-2xl  backdrop-blur-md  shadow-24 p-6  flex flex-col">
          <p className="text-lg font-semibold text-textDark py-3">
            {t("fieldSettingsDeleteField")}
          </p>
          <TextField
            className="w-full"
            id="user-email"
            label="Farm Name"
            type="text"
            value={farmName}
            onChange={(event) => setFarmName((prev) => event.target.value)}
          />
          <div className=" mt-6 w-full flex flex-row gap-2 justify-end">
            <Button
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {
                setCreateModalOpen(false);
                setFarmName((prev) => undefined);
              }}
              variant="text"
              sx={{ fontWeight: "600", color: "rgb(69 74 115)" }}
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={() => setCreateModalOpen(false)}
              variant="contained"
              disabled={farmName ? false : true}
              sx={{
                fontWeight: "600",
                background: `${
                  farmName
                    ? "linear-gradient(146deg, #62CBF8 5.53%, #3157F5 91.6%)"
                    : "rgb(69 74 115)"
                }`,
                color: "#FFF !important",
              }}
            >
                            {t("fieldSettingsCreateFarm")}

            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default FieldSetting;
