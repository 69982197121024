import React, { useContext, useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./DeviceVanaCard.css";
import { Box, Button, MenuItem, Modal, Select, TextField } from "@mui/material";
import Prepare from "../assets/prepare.svg";
import Irrigate from "../assets/irrigate.svg";
import VanaStroke from "../assets/vanaStroke.svg";
import vanaBlack from "../assets/vanaBlack.svg";
import { AccountContext } from "../events/Account";
import { useTranslation } from "react-i18next";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { TimeClock } from "@mui/x-date-pickers/TimeClock";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import * as echarts from "echarts";
import { AppContext } from "../pages/Layout";

function DeviceVanaCard({ data, fieldData }) {
  const { t, i18n } = useTranslation();
  const { apiKey, user_id } = useContext(AccountContext);
  const navigate = useNavigate();
  const [lastData, setLastData] = useState();
  const [tsdata, settsdata] = useState();
  const [loading, setLoading] = useState(true);
  const [vanaStatus, setVanaStatus] = useState(false);
  const [HWNo, seyHWNo] = useState("02");
  const [SancakPacketVersion, setSancakPacketVersion] = useState("001");
  const [SancakPacketVersionOFF, setSancakPacketVersionOFF] = useState("002");
  const [GRDPacketVersion, setGRDPacketVersion] = useState("");
  const [GRDMeasureCount, setGRDMeasureCount] = useState(0);
  const [GRDSleepTimeH, setGRDSleepTimeH] = useState(0);
  const [GRDSleepTimeM, setGRDSleepTimeM] = useState(5);
  const [isCommandModal, setIsCommandModal] = useState(false);
  const [isCloseCommandModal, setIsCloseCommandModal] = useState(false);
  const [commandLoading, setCommandLoading] = useState(false);
  const [wateringTime, setWateringTime] = useState(false);
  const [connection, setConnection] = useState(3);
  const [newdata, setnewdata] = useState(false);
  const [vanaDate, setVanaDate] = useState("0000000000000");
  const [isPending, setIsPending] = useState(false);
  const [state, setState] = useState("0");
  const [isStartTime, setIsStartTime] = useState(false);
  const [isPlanIrrigation, setIsPlanIrrigation] = useState(false);
  const [isPicker, setIsPicker] = useState(true);
  const [filteredtsdata, setfilteredtsdata] = useState([]);
  const [filterSelect, setFilterSelect] = useState("1m");
  const [chunkSelect, setChunkSelect] = useState("h");
  const {
    userApp,
    setUserApp,
    farmsApp,
    setFarmsApp,
    userOcakApp,
    setUserOcakApp,
    userVanaApp,
    setUserVanaApp,
    farmNameApp,
    setFarmNameApp,
  } = useContext(AppContext);
  useEffect(() => {
    console.log("id :::", data);
    console.log("id2 :::", fieldData);
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var requestOptionsGET = {
      method: "GET",
      headers: myHeaders,
    };
    const fetchData = () => {
      const fetch1 = () =>{
        fetch(
          `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=vana7d&created_at=${data.created_at}`
        )
          .then((response) => response.json())
          .then((result) => {
            const obj1 = result.body.map((e) => JSON.parse(e));
            console.log("vana objilk:", obj1, userVanaApp);
            settsdata(prev => obj1);

            //find device from userVanaApp array and add tsdata with 7d key if userVanaApp array includes device
            if (userVanaApp && userVanaApp.length > 0) {
              var index = userVanaApp.findIndex((x) => x.id === data.id);
              console.log("index", index);
              var temp = userVanaApp;
              if (index !== -1) {
                temp[index].tsdata7d = obj1;
                setUserVanaApp((prev) => temp);
              }
              console.log("userVanaApp", userVanaApp);
              
            }
          })
          .catch((error) => {
            console.log("vana error", error);
          });}

      const fetch2 = () =>{
        fetch(
          `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=vanalast&created_at=${data.created_at}`
        )
          .then((response) => response.json())
          .then((result) => {
            var obj2 = result.body.map((e) => JSON.parse(e));
            console.log("vana lastdata:", obj2);
            obj2 = obj2?.[0];
            obj2.status = obj2.status == 1 ? "on" : "off";
            setLastData((prev) => obj2);
            setLoading(prev =>false);
          })
          .catch((error) => {
            console.log("vana error", error);
            setLoading(prev =>false);
          });}

      //if userVanaApp defined and found device in userVanaApp array and have tsdata7d, set tsdata with tsdata7d and dont call fetch1
      var index = userVanaApp
        ? userVanaApp.findIndex((x) => x.id === data.id)
        : -1;
      console.log("index", index);
      console.log("userVanaApp", userVanaApp);
      fetch2();

      if (index !== -1 && userVanaApp && userVanaApp[index].tsdata7d) {
        console.log("vana tsdata7d contextten alındı ctxvana",userVanaApp[index].tsdata7d);
        settsdata(userVanaApp[index].tsdata7d);

      } else {
        console.log("vana tsdatası fetch ile alındı ctxvana");

        fetch1();
      }
    };

    // fetchData fonksiyonunu çağır
    if (connection == 3) {
      fetchData();
    }
    // WebSocket bağlantısını oluşturun
    var socket = new WebSocket(
      `wss://zh906rwmy8.execute-api.eu-west-1.amazonaws.com/production/?user_id=${user_id}&devices=[${data.id}]`
    );

    // Bağlantı başarıyla kurulduğunda
    socket.addEventListener("open", (event) => {
      console.log("vana WebSocket bağlantısı başarıyla kuruldu.", event);
    });
    const pongInterval = setInterval(() => {
      socket.send("ping");
    }, 30000); // Her 30 saniyede bir
    // Mesaj alındığında
    socket.addEventListener("message", (event) => {
      setnewdata((prev) => true);
      console.log("vana WebSocketten gelen mesaj:", event);
      var dat = JSON.parse(event.data);
      console.log("vana dat dat dat 1:", dat);
      if (dat.data.state != undefined) {
        setState((prev) => dat.data.state);
      } else {
        var parseddata = { ...dat.data, ...dat.data.izci_packet };
        parseddata.status = parseddata.status == 1 ? "on" : "off";
        if (parseddata.state != undefined) {
          setState((prev) => parseddata.state);
        }
        console.log("vana dat dat dat:", parseddata);
        console.log("vana tsdata", tsdata);
        // if (lastData?.watering) {
        //    parseddata.watering =  Number(GRDSleepTimeH * 60 + GRDSleepTimeM).stringify()

        // }
        !loading && settsdata((prev) => [parseddata, ...prev]);
        !loading && setLastData((prev) => Object.assign({}, prev, parseddata));
      }
    });

    // Bağlantı kapandığında
    socket.addEventListener("close", (event) => {
      console.log("vana WebSocket bağlantısı kapatıldı.", event);
      clearInterval(pongInterval);

      setTimeout(() => {
        setConnection((prev) => !prev);
      }, 5000);
    });

    // Hata oluştuğunda
    socket.addEventListener("error", (event) => {
      console.error("vana WebSocket hatası:", event);
    });
    console.log("vana websocet kapalı triggered", socket.readyState);
  }, [connection]);

  function formatDate(date) {
    var mdate = new Date(Number(date));
    // console.log("date",mdate);
    mdate.setMinutes(mdate.getMinutes());
    // return `       ${mdate.getHours()}:${
    //   mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    // }
    //  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    // `;
    return `${timeDifference(mdate)}
    `;
  }
  const VanaCommand = (status) => {
    setVanaStatus(status);
  };

  const StatusFunction = () => {
    if (lastData && lastData.status) {
      if (lastData.status == "on") {
        return <p className="text-green-500">ON</p>;
      } else if (lastData.status == "off") {
        return <p className="text-gray-500">OFF</p>;
      } else if (lastData.status == "pending") {
        return <p className="text-blue-500">pending</p>;
      }
    } else {
      return <p>noData</p>;
    }
  };

  const CommandFunctionOpenValve = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var tempCont = Number(GRDMeasureCount).toString(16);
    if (tempCont.length == 1) {
      tempCont = `0${tempCont}`;
    }

    var tempSleep = Number(GRDSleepTimeH * 60 + GRDSleepTimeM).toString(16);
    var vanaDateHex = parseInt(Number(vanaDate) / 1000).toString(16);
    let commandTime = new Date().getTime();
    var vanaCommandId = parseInt(Number(commandTime)).toString(16);
    console.log("vanaDate Before", vanaDate);
    console.log("vanaDate After", vanaDate / 1000);
    tempSleep = tempSleep.padStart(4, "0");
    vanaDateHex = vanaDateHex.padStart(8, "0");
    console.log(
      "`${vanaDateHex.toUpperCase()}${durationHex.toUpperCase()}`",
      `${vanaDateHex.toUpperCase()}--${tempSleep.toUpperCase()}`
    );
    var body = {
      o: tsdata?.[0]?.ocak_id,
      s: SancakPacketVersion,
      g: HWNo,
      i: data.id,
      d: `${vanaDateHex.toUpperCase()}${tempSleep.toUpperCase()}`,
      cid: vanaCommandId.toUpperCase(),
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    console.log(body);
    fetch(
      `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/command?id=${data.id}&type=vana`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        // setLastData((prev) => {
        //   var newlast = prev;
        //   newlast.status = "pending";

        //   return newlast;
        // });

        setCommandLoading(false);
        setConnection((prev) => !prev);
        var tempLast = lastData;
        tempLast.watering = Number(GRDSleepTimeH * 60 + GRDSleepTimeM);
        var NewDate = Date.now().toString();
        tempLast.startTime =
          Number(vanaDate) == 0
            ? parseInt(Number(NewDate))
            : parseInt(Number(vanaDate));
        setLastData((prev) => tempLast);
        setVanaDate("0000000000000");
        setIsPicker((prev) => true);
        setIsPlanIrrigation((prev) => false);
      })
      .then(() => setIsCommandModal(false))
      .catch((error) => {
        console.log("error", error);
        setCommandLoading(false);
        setVanaDate("0000000000000");
        setIsPicker((prev) => true);
        setIsPlanIrrigation((prev) => false);
      });
  };
  const CommandFunctionCloseValve = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    let commandTime = new Date().getTime();
    var vanaCommandId = parseInt(Number(commandTime)).toString(16);
    var body = {
      o: tsdata?.[0]?.ocak_id,
      s: SancakPacketVersionOFF,
      g: HWNo,
      i: data.id,
      cid: vanaCommandId.toUpperCase(),
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    console.log(body);
    fetch(
      `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/command?id=${data.id}&type=vana`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        // setLastData((prev) => {
        //   var newlast = prev;
        //   newlast.status = "pending";
        //   setIsAction(prev => true)
        //   return newlast;
        // });

        setCommandLoading(false);
      })
      .then(() => setIsCloseCommandModal(false))
      .catch((error) => {
        console.log("error", error);
        setIsCloseCommandModal(false);
      });
  };

  function timestampToDate(timestamp) {
    console.log("timestamp", timestamp);
    var saat = new Date(timestamp).getHours();
    var dakika = new Date(timestamp).getMinutes();
    return `${saat}:${dakika <= 9 ? `0${dakika}` : dakika}`;
  }

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    if (lastData?.status != "off") {
      setIsCloseCommandModal(true);
    } else {
      setIsCommandModal(true);
    }
    // setIsChecked(!isChecked);
  };
  var date = new Date();
  var now_utc = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  const navigateFunction = (id) => {
    var pathname = window.location.pathname;
    var sonIndis = pathname.lastIndexOf("/"); // Son "/" işaretinin indisini buluyoruz
    var yeniDizge = pathname.substring(sonIndis + 1); // Substring metodu ile istenen kısmı alıyoruz
    if (yeniDizge !== id) {
      navigate(`${id}`, {
        state: { ...data, tsdata },
      });
    }
  };
  function timeDifference(previous) {
    var current = new Date().getTime();
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ` ${t("minutes")} ${t("ago")}`;
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ` ${t("hours")} ${t("ago")}`;
    } else if (elapsed < msPerMonth) {
      return " " + Math.round(elapsed / msPerDay) + ` ${t("days")} ${t("ago")}`;
    } else if (elapsed < msPerYear) {
      return (
        " " + Math.round(elapsed / msPerMonth) + ` ${t("months")} ${t("ago")}`
      );
    } else {
      return (
        " " + Math.round(elapsed / msPerYear) + ` ${t("years")} ${t("ago")}`
      );
    }
  }

  const handleOpen = (e) => {
    setIsPicker((prev) => true);
  };

  const handleClose = (e) => {
    setIsPicker((prev) => false);
  };

  const handleAccept = (e) => {
    setIsPicker((prev) => false);
  };

  function yaz(attrname) {
    var time = [];
    var value = [];
    var lasttime;

    tsdata?.map((data, index) => {
      if (attrname.includes("izci_sf")) {
        var calc;
        if (
          Number(data[attrname]) < 400000000 ||
          Number(data[attrname]) > 500000000
        ) {
          return;
        } else {
          calc = (500000000 - data[attrname]) / 1000000;
        }
        var mdate = new Date(data.time);
        mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
        value.push([mdate, Number(calc).toFixed(2)]);

        lasttime = Date.parse(data.time);
      } else {
        var mdate = new Date(data.time);
        mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
        value.push([mdate, Number(data[attrname]).toFixed(2)]);

        lasttime = Date.parse(data.time);
      }
    });
    return value;
  }
  const DrawStackedTSSM = (data) => {
    var graphData1 = yaz("izci_sensor_data_in");
    var graphData2 = yaz("izci_sensor_data_out");
    return StackedLine([graphData1, graphData2], ["in", "out"]);
  };
  const calculateAverage = (data, keys) => {
    const sum = {};
    keys.forEach((key) => (sum[key] = 0));

    data.forEach((item) => {
      keys.forEach((key) => {
        if (!isNaN(parseFloat(item[key]))) {
          sum[key] += parseFloat(item[key]);
        }
      });
    });

    const averages = {};
    keys.forEach(
      (key) => (averages[key] = (sum[key] / data.length).toFixed(1))
    );

    return averages;
  };
  const processData = (data) => {
    const hourlyData = [];
    const keysToAverage = [
      "izci_battery_voltage",
      "izci_seq",
      "izci_sensor_data_in",
      "izci_sensor_data_out",
    ];

    for (let i = 0; i < data.length; i += 24) {
      const hourlyChunk = data.slice(i, i + 24);
      const averageData = calculateAverage(hourlyChunk, keysToAverage);
      averageData.ocak_id = hourlyChunk[0].ocak_id;
      averageData.event_date = hourlyChunk[0].event_date;
      averageData.time = hourlyChunk[0].time;
      hourlyData.push(averageData);
    }
    setfilteredtsdata((prev) => hourlyData);
    console.log("hourlyData", hourlyData);
  };
  const FilterTimeFunction = (data, timeP, chunk) => {
    var reqTime = new Date();
    var filteredArr;

    switch (timeP) {
      case "1d":
        // Tarihi bir ay öncesine göre
        reqTime.setMinutes(reqTime.getMinutes() - 1440);
        filteredArr = data?.filter(
          (obje) => Date.parse(obje.time) >= Date.parse(reqTime)
        );
        setfilteredtsdata((prev) => filteredArr);
        if (chunk == "d") {
          processData(filteredArr);
        }
        break;
      case "2d":
        // Tarihi bir ay öncesine göre
        reqTime.setMinutes(reqTime.getMinutes() - 2880);
        filteredArr = data?.filter(
          (obje) => Date.parse(obje.time) >= Date.parse(reqTime)
        );

        setfilteredtsdata((prev) => filteredArr);
        if (chunk == "d") {
          processData(filteredArr);
        }
        break;
      case "7d":
        // Tarihi bir ay öncesine göre
        reqTime.setMinutes(reqTime.getMinutes() - 10800);
        filteredArr = data?.filter(
          (obje) => Date.parse(obje.time) >= Date.parse(reqTime)
        );

        setfilteredtsdata((prev) => filteredArr);
        if (chunk == "d") {
          processData(filteredArr);
        }
        break;
      case "14d":
        // Tarihi bir ay öncesine göre
        reqTime.setMinutes(reqTime.getMinutes() - 20160);
        filteredArr = data?.filter(
          (obje) => Date.parse(obje.time) >= Date.parse(reqTime)
        );

        setfilteredtsdata((prev) => filteredArr);
        if (chunk == "d") {
          processData(filteredArr);
        }
        break;
      case "1m":
        // Tarihi bir ay öncesine göre
        reqTime.setMonth(reqTime.getMonth() - 1);
        filteredArr = data?.filter(
          (obje) => Date.parse(obje.time) >= Date.parse(reqTime)
        );

        setfilteredtsdata((prev) => filteredArr);
        if (chunk == "d") {
          processData(filteredArr);
        }
        break;
      case "3m":
        reqTime.setMonth(reqTime.getMonth() - 3);
        filteredArr = data?.filter(
          (obje) => Date.parse(obje.time) >= Date.parse(reqTime)
        );
        setfilteredtsdata((prev) => filteredArr);
        if (chunk == "d") {
          processData(filteredArr);
        }
        break;
      case "6m":
        reqTime.setMonth(reqTime.getMonth() - 6);
        filteredArr = data?.filter(
          (obje) => Date.parse(obje.time) >= Date.parse(reqTime)
        );
        setfilteredtsdata((prev) => filteredArr);
        if (chunk == "d") {
          processData(filteredArr);
        }
        break;
      case "1y":
        reqTime.setMonth(reqTime.getMonth() - 12);
        filteredArr = data?.filter(
          (obje) => Date.parse(obje.time) >= Date.parse(reqTime)
        );
        setfilteredtsdata((prev) => filteredArr);
        if (chunk == "d") {
          processData(filteredArr);
        }
        break;
      case "all":
        setfilteredtsdata((prev) => tsdata);
        if (chunk == "d") {
          processData(filteredArr);
        }
        break;
    }
  };
  return (
    <div className="w-[calc(100%-0.5rem)] max-w-[11rem]     max-sm:p-0  max-sm:w-[calc(100%-0rem)] max-sm:min-w-[9rem] ">
      {loading  ? (
        <div className=" w-full flex max-sm:flex-col flex-row gap-4">
          <div className="w-full ">
            <SkeletonTheme baseColor="#ededed" highlightColor="#f8f8f8">
              <Skeleton width={"100%"} height={200} />
            </SkeletonTheme>
          </div>
        </div>
      ) : (
        <div
          className="w-full bg"
          onClick={() => {
            navigateFunction(`${data.id.slice(-4)}`, {
              state: { ...data, tsdata },
            });
          }}
        >
          <div
            className={`${
              lastData?.status == "on"
                ? "bg-gradient-to-br from-[#62CBF8] to-[#3157F5] "
                : "bg-white max-sm:bg-transparent border max-sm:border-slate-300"
            }  rounded-xl p-5 transition duration-300 overflow-hidden relative`}
          >
            <div className="w-full  flex flex-row justify-between">
              <p
                className={`${
                  lastData?.status == "on" ? "text-white " : "text-textLight"
                }  capitalize text-base`}
              >
                {t(`${lastData?.status}`)}
              </p>
              {/* <div className="w-12 h-6 rounded-full bg-slate-300"></div> */}
              {fieldData?.role !== "viewer" && (
                <div
                  className=""
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <label className="flex cursor-pointer select-none items-center">
                    <div className="relative">
                      <input
                        type="checkbox"
                        checked={lastData?.status == "on"}
                        onChange={handleCheckboxChange}
                        className="sr-only"
                      />

                      <div
                        className={`box block h-6 w-10 rounded-full ${
                          lastData?.status == "on"
                            ? "bg-textDark"
                            : "bg-gray-300"
                        }`}
                      ></div>
                      <div
                        className={`absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-white transition ${
                          lastData?.status == "on" ? "translate-x-full" : ""
                        }`}
                      ></div>
                    </div>
                  </label>
                </div>
              )}
            </div>
            <p
              className={`${
                lastData?.status == "on" ? "text-white" : "text-textLight"
              }  text-xs`}
            >
              {t("fieldsLastMeasurement")}
              <br />
              {/* {alert(JSON.stringify(lastData?.received_date))} */}
              {lastData?.received_date
                ? formatDate(lastData?.received_date)
                : formatDate(tsdata?.[0]?.received_date)}
            </p>
            <div
              className={`${
                lastData?.status == "on" ? "text-white " : "text-textDark"
              }  flex flex-row text-xs  justify-center  leading-none`}
            >
              <div className="flex flex-col justify-center items-center ">
                <p>in</p>
                <p
                  className={`${
                    lastData?.status == "on" ? "text-white " : "text-blue-500"
                  }  text-base font-semibold`}
                >
                  {lastData?.izci_sensor_data_in}
                </p>
                <p className="-mt-1">bar</p>
              </div>
              <img className="w-16 py-2 mx-1 max-h-20" src={vanaBlack} />
              <div className="flex flex-col justify-center items-center">
                <p>out</p>
                <p
                  className={`${
                    lastData?.status == "on"
                      ? "text-textDark "
                      : "text-textDark"
                  }  text-base font-semibold`}
                >
                  {lastData?.izci_sensor_data_out}
                </p>
                <p>bar</p>
              </div>
              {/* <div className="flex flex-col w-full text-md  justify-center ">
                <p
                  className={`${
                    lastData?.status == "on"
                      ? "text-textLight bg-white"
                      : "text-textLight bg-gray-300"
                  }  mb-1 text-xs   text-center`}
                >
                  {t("bar")}
                </p>
                <div
                  className={`${
                    lastData?.status == "on" ? "text-white" : "text-textLight"
                  }  flex flex-row justify-evenly `}
                >
                  <p>in</p>
                  <p>out</p>
                </div>
                <div
                  className={`${
                    lastData?.status == "on" ? "text-white" : "text-textDark"
                  }  flex flex-row justify-evenly font-semibold`}
                >
                  <p>{lastData?.izci_sensor_data_in}</p>
                  <p>{lastData?.izci_sensor_data_out}</p>
                </div>
              </div> */}
            </div>

            <p
              className={`${
                lastData?.status == "on" ? "text-white " : "text-textDark"
              }  text-base w-full break-words  font-semibold  leading-none `}
            >
              {data.id.slice(-4)}
            </p>
            <p
              className={`${
                lastData?.status == "on" ? "text-white " : "text-blue-500"
              }   text-xs font-semibold  whitespace-nowrap`}
            >
              {data?.fieldName}
            </p>
            <div className="opacity-100  w-[calc(100%+2.5rem)] h-12  relative left-0 -mx-5 -mb-5 rounded-xl">
              {DrawStackedTSSM(filteredtsdata)}
            </div>

            {/* <p
              className={`${
                lastData?.status == "on" ? "text-white " : "text-textLight "
              }  text-xs  h-10`}
            >
              {state == "0"  || state == "3" ? (
                ""
              ) : (
                <div className="relative ">
                  <div
                    className={`absolute right-0 -mr-32 -mt-16 w-60 h-60  border-2 ${
                      lastData?.status == "on"
                        ? "border-white bg-white"
                        : "border-slate-800"
                    }   rounded-full opacity-0   animate-growAndFade`}
                  ></div>
                  <div
                    className={`absolute right-0 -mr-32 -mt-16 w-60 h-60  border-2 ${
                      lastData?.status == "on"
                        ? "border-white"
                        : "border-slate-800"
                    }   rounded-full opacity-0   animate-growAndFade2`}
                  ></div>
                  <div
                    className={`absolute right-0 -mr-32 -mt-16 w-60 h-60  border-2 ${
                      lastData?.status == "on"
                        ? "border-white"
                        : "border-slate-800"
                    }   rounded-full opacity-0   animate-growAndFade3`}
                  ></div>
                </div>
              )}

              {state == "0"
                ? lastData?.status == "on"
                  ? `${timestampToDate(
                      Number(lastData?.startTime)
                    )} - ${timestampToDate(
                      Number(lastData?.startTime) +
                        Number(lastData?.watering) * 60000
                    )}`
                  : t("vanaSetDevice")
                : state == "1"
                ? t("vanaCommandSendingStation")
                : state == "2"
                ? t("vanaCommandSendingDevice")
                : state == "3"
                ? lastData?.status == "off"
                  ? `${t("vanaWillBeOpened")} ${timestampToDate(
                      Number(lastData?.startTime)
                    )} - ${timestampToDate(
                      Number(lastData?.startTime) +
                        Number(lastData?.watering) * 60000
                    )}${t("vanaWillBeOpened2")}.`
                  : t("vanaWillTurnOff")
                : ""}
            </p> */}
          </div>
        </div>
      )}

      <Modal
        open={isCommandModal}
        onClose={() => {
          setIsCommandModal(false);
          setIsChecked(false);
          setVanaDate("0000000000000");
          setIsPlanIrrigation((prev) => false);
          setIsPicker((prev) => true);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="mx-auto translate-y-1/2 w-1/3 max-lg:w-4/6 max-sm:w-5/6 bg-white/90 rounded-2xl  backdrop-blur-md  shadow-24 p-5  flex flex-col">
          <p className="text-xl w-full text-textDark text-center pt- pb-4">
            {data.id.slice(-4)}
          </p>
          <div className="flex flex-col gap-2 ">
            {/* <TextField
                  id="outlined-basic"
                  label="Ocak Id"
                  value={tsdata?.[0]?.ocak_id}
                  variant="standard"
                />
                <TextField
                  id="outlined-basic"
                  label="Izci Id"
                  value={data.id}
                  variant="standard"
                />
                <TextField
                  id="outlined-basic"
                  label="Sancak Packet Version"
                  variant="standard"
                  value={SancakPacketVersion}
                  onChange={(event) => {
                    setSancakPacketVersion(event.target.value.toString());
                  }}
                /> */}
            {/* <p className="pt-3 font-bold">GRD Commands </p> */}
            {/* <div className="w-full p-4 max-sm:hidden">
              <img src={vanaBlack} className="w-32 mx-auto" />
            </div> */}
            <div className="px-4 text-xs flex flex-row max-sm:flex-col max-sm:justify-center max-sm:items-start  gap-2 justify-evenly items-end text-textLight">
              <div className="relative h-24 ">
                <p>{t("vanaStart")}</p>
                <div className="hover:!cursor-pointer ">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      // disablePast

                      className="customTimePicker "
                      defaultValue={dayjs(new Date())}
                      ampm={false}
                      open={isPlanIrrigation && isPicker}
                      onAccept={(e) => handleAccept(e)}
                      onClose={(e) => handleClose(e)}
                      onDismiss={(e) => handleClose(e)}
                      onOpen={(e) => handleOpen(e)}
                      onChange={(e) => {
                        var now = new Date();
                        var selected_now = new Date(
                          now.getUTCFullYear(),
                          now.getUTCMonth(),
                          now.getUTCDate(),
                          now.getUTCHours(),
                          now.getUTCMinutes(),
                          now.getUTCSeconds()
                        );
                        var date = new Date(e.$d);
                        var selected_utc = new Date(
                          date.getUTCFullYear(),
                          date.getUTCMonth(),
                          date.getUTCDate(),
                          date.getUTCHours(),
                          date.getUTCMinutes(),
                          date.getUTCSeconds()
                        );
                        console.log("dayı", date.getTime());
                        if (selected_now.getTime() > selected_utc.getTime()) {
                          console.log(
                            "selected_now",
                            selected_now,
                            selected_now.getTime(),
                            "selected_utc",
                            selected_utc,
                            selected_utc.getTime() + 86400000
                          );
                          setVanaDate((prev) => date.getTime() + 86400000);
                        } else {
                          setVanaDate((prev) => date.getTime());
                        }
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div
                  className={`${
                    isPlanIrrigation ? "hidden" : ""
                  }  backdrop-blur-sm absolute bg-gray-200/50 w-full h-full top-0 rounded-lg -ml-4 text-center flex flex-col justify-center items-center`}
                >
                  <div className="text-4xl text-textDark">
                    <ion-icon name="alarm-outline"></ion-icon>
                  </div>
                  <div
                    className="hover:cursor-pointer bg-textDark  text-white p-2 rounded-md"
                    onClick={() => {
                      setIsPlanIrrigation((prev) => true);
                    }}
                  >
                    {t("vanaPlanIrrigation")}
                  </div>
                </div>
              </div>

              <div className="flex flex-col h-24 ">
                <p className="mb-2">{t("vanaWatering")}</p>
                <div className="flex flex-row items-end ">
                  {/* <p className="">{t("irrigate")}</p> */}

                  <Select
                    variant="standard"
                    id="demo-simple-select"
                    value={GRDSleepTimeH}
                    sx={{
                      fontWeight: "400",
                      color: "rgb(69 74 115)",
                      fontSize: "3rem",
                      height: "3rem",
                    }}
                    onChange={(event) => {
                      setGRDSleepTimeH(event.target.value);
                    }}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                    {/* <MenuItem value={13}>13</MenuItem>
                    <MenuItem value={14}>14</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={16}>16</MenuItem>
                    <MenuItem value={17}>17</MenuItem>
                    <MenuItem value={18}>18</MenuItem>
                    <MenuItem value={19}>19</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={21}>21</MenuItem>
                    <MenuItem value={22}>22</MenuItem>
                    <MenuItem value={23}>23</MenuItem>
                    <MenuItem value={24}>24</MenuItem> */}
                  </Select>
                  <p className="mr-3">{t("hours")}</p>
                  <Select
                    className="w-"
                    variant="standard"
                    id="demo-simple-select"
                    value={GRDSleepTimeM}
                    sx={{
                      fontWeight: "400",
                      color: "rgb(69 74 115)",
                      fontSize: "3rem",
                      height: "3rem",
                    }}
                    onChange={(event) => {
                      setGRDSleepTimeM(event.target.value);
                    }}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                    <MenuItem value={13}>13</MenuItem>
                    <MenuItem value={14}>14</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={16}>16</MenuItem>
                    <MenuItem value={17}>17</MenuItem>
                    <MenuItem value={18}>18</MenuItem>
                    <MenuItem value={19}>19</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={21}>21</MenuItem>
                    <MenuItem value={22}>22</MenuItem>
                    <MenuItem value={23}>23</MenuItem>
                    <MenuItem value={24}>24</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={26}>26</MenuItem>
                    <MenuItem value={27}>27</MenuItem>
                    <MenuItem value={28}>28</MenuItem>
                    <MenuItem value={29}>29</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={31}>31</MenuItem>
                    <MenuItem value={32}>32</MenuItem>
                    <MenuItem value={33}>33</MenuItem>
                    <MenuItem value={34}>34</MenuItem>
                    <MenuItem value={35}>35</MenuItem>
                    <MenuItem value={36}>36</MenuItem>
                    <MenuItem value={37}>37</MenuItem>
                    <MenuItem value={38}>38</MenuItem>
                    <MenuItem value={39}>39</MenuItem>
                    <MenuItem value={40}>40</MenuItem>
                    <MenuItem value={41}>41</MenuItem>
                    <MenuItem value={42}>42</MenuItem>
                    <MenuItem value={43}>43</MenuItem>
                    <MenuItem value={44}>44</MenuItem>
                    <MenuItem value={45}>45</MenuItem>
                    <MenuItem value={46}>46</MenuItem>
                    <MenuItem value={47}>47</MenuItem>
                    <MenuItem value={48}>48</MenuItem>
                    <MenuItem value={49}>49</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={51}>51</MenuItem>
                    <MenuItem value={52}>52</MenuItem>
                    <MenuItem value={53}>53</MenuItem>
                    <MenuItem value={54}>54</MenuItem>
                    <MenuItem value={55}>55</MenuItem>
                    <MenuItem value={56}>56</MenuItem>
                    <MenuItem value={57}>57</MenuItem>
                    <MenuItem value={58}>58</MenuItem>
                    <MenuItem value={59}>59</MenuItem>
                  </Select>
                  <p>{t("minutes")}.</p>
                </div>
              </div>
            </div>
          </div>
          <div className=" p-4 w-full flex flex-row gap-2 justify-end">
            <Button
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {
                setIsCommandModal(false);
                setIsChecked(false);
                setIsPlanIrrigation((prev) => false);
                setIsPicker((prev) => true);
              }}
              variant="outlined"
              sx={{ fontWeight: "400" }}
              className="!text-xs !text-textDark !border !border-gray-400 !font-medium"
            >
              {t("cancel")}
            </Button>
            <Button
              sx={{
                fontWeight: "400",
                background: "#454a73",
              }}
              className="!text-xs !text-white !border !border-gray-400 !font-medium !py-2"
              disabled={
                SancakPacketVersion === "" ||
                GRDSleepTimeM === "" ||
                GRDSleepTimeH === ""
              }
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {
                CommandFunctionOpenValve();
                setCommandLoading(true);
              }}
              startIcon={commandLoading ? "" : ""}
              variant="contained"
            >
              {commandLoading ? (
                <div className=" flex flex-row flex-wrap">
                  <div className=" m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-5 w-5"></div>
                </div>
              ) : (
                t("apply")
              )}
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={isCloseCommandModal}
        onClose={() => {
          setIsCloseCommandModal(false);
          setIsChecked(false);
          setVanaDate("0000000000000");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="mx-auto translate-y-1/2 w-1/3 max-lg:w-4/6 max-sm:w-5/6 bg-white/90 rounded-2xl  backdrop-blur-md  shadow-24 p-5  flex flex-col">
          <p className="text-xl w-full text-textDark text-center pt- pb-4">
            {data.id.slice(-4)}
          </p>
          <div className="flex flex-col justify-center items-center mb-2">
            <img src={VanaStroke} className="w-20  mb-5" />
            <p className="text-textDark ">{t("vanaTurnOff")}</p>
          </div>
          <div className=" p-4 w-full flex flex-row gap-2 justify-end">
            <Button
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {
                setIsCloseCommandModal(false);
                setIsChecked(false);
              }}
              variant="text"
              sx={{ fontWeight: "600", color: "rgb(69 74 115)" }}
            >
              {t("cancel")}
            </Button>
            <Button
              sx={{
                fontWeight: "600",
                background:
                  "linear-gradient(146deg, #62CBF8 5.53%, #3157F5 91.6%)",
              }}
              disabled={
                SancakPacketVersion === "" ||
                GRDSleepTimeM === "" ||
                GRDSleepTimeH === ""
              }
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {
                CommandFunctionCloseValve();
                setCommandLoading(true);
              }}
              startIcon={commandLoading ? "" : ""}
              variant="contained"
            >
              {commandLoading ? (
                <div className=" flex flex-row flex-wrap">
                  <div className=" m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-5 w-5"></div>
                </div>
              ) : (
                t("apply")
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default DeviceVanaCard;
function StackedLine(rawdatas, names) {
  let option = {
    // title: {
    //   show: false,
    //   text: ' asd',
    //   display: 'none',
    // },

    tooltip: {
      show: false,
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      show: false,
      type: "plain",
      top: "0%",
      padding: [
        0, // up
        0, // right
        0, // down
        45, // left
      ],
      icon: "roundRect",
    },
    toolbox: {
      show: false,
      feature: {
        dataView: { show: false, readOnly: false },
        saveAsImage: {},
      },
      top: "12%",
      z: 100000,
    },
    grid: [
      {
        left: "0%",
        right: "0%",
        bottom: "0%",
        top: "3%",
        height: "100%",
        containLabel: false,
      },
    ],
    axisLine: {
      show: false,
    },
    xAxis: {
      show: false,
      type: "time",
      boundaryGap: false,
    },
    yAxis: {
      splitLine: { show: false },
      splitArea: {
        show: false,
      },
      axisLine: { show: false },
      show: false,
      type: "value",
      max: function (value) {
        return (value.max + 0.1).toFixed(1);
      },
      min: function (value) {
        return (value.min - 0).toFixed(0);
      },
      axisTick: {
        inside: false,
      },
      axisLabel: {
        inside: false,
        formatter: "{value}\n",
      },
    },
    dataZoom: [
      {
        type: "inside",
        disabled: true,
        throttle: 50,
        show: false,
        brushSelect: false,
        handleStyle: {
          borderColor: "rgba(0, 0, 0, 1)",
        },
        handleIcon: "roundRect",
        handleSize: "61%",
      },
    ],
    series: [],
    seriesCnt: "6",
    backgroundColor: "rgba(255,255,255,0)",
    titleColor: "#000000",
    subtitleColor: "#505050",
    textColorShow: false,
    textColor: "#333",
    markTextColor: "#ffffff",
    color: [
      "#3b82f6",
      "#454a73",
      "#94d2bd",
      "#ca6702",
      "#bb3e03",
      "#ae2012",
      "#9b2226",
      "#0a9396",
      "#454a73",
    ],
    borderColor: "#ccc",
    borderWidth: "0",
    visualMapColor: ["#8a7ca8", "#e098c7", "#cceffa"],
    legendTextColor: "#000000",
    kColor: "#e098c7",
    kColor0: "transparent",
    kBorderColor: "#e098c7",
    kBorderColor0: "#8fd3e8",
    kBorderWidth: "2",
    lineWidth: "1",
    symbolSize: "0",
    symbol: "emptyRoundRect",
    symbolBorderWidth: "0",
    lineSmooth: false,
    graphLineWidth: 1,
    graphLineColor: "#aaaaaa",
    mapLabelColor: "#000",
    mapLabelColorE: "#ffffff",
    mapBorderColor: "#444",
    mapBorderColorE: "#444",
    mapBorderWidth: 0.5,
    mapBorderWidthE: 1,
    mapAreaColor: "#eee",
    mapAreaColorE: "#e098c7",
    axes: [
      {
        type: "all",
        name: "通用坐标轴",
        axisLineShow: true,
        axisLineColor: "#000000",
        axisTickShow: false,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#000000",
        splitLineShow: false,
        splitLineColor: ["#eeeeee", "#333333"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
      {
        type: "category",
        name: "类目坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: false,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "value",
        name: "数值坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "log",
        name: "对数坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "time",
        name: "时间坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
    ],
    axisSeperateSetting: false,
    toolboxColor: "#4100ff",
    toolboxEmphasisColor: "#000000",
    tooltipAxisColor: "#0013ff",
    tooltipAxisWidth: "3",
    timelineLineColor: "#8fd3e8",
    timelineLineWidth: 1,
    timelineItemColor: "#ff0000",
    timelineItemColorE: "#8fd3e8",
    timelineCheckColor: "#8fd3e8",
    timelineCheckBorderColor: "#8a7ca8",
    timelineItemBorderWidth: 1,
    timelineControlColor: "#8fd3e8",
    timelineControlBorderColor: "#8fd3e8",
    timelineControlBorderWidth: 0.5,
    timelineLabelColor: "#8fd3e8",
    datazoomBackgroundColor: "rgba(0,0,0,0)",
    datazoomDataColor: "rgba(255,255,255,0.3)",
    datazoomFillColor: "rgba(167,183,204,0.4)",
    datazoomHandleColor: "#a7b7cc",
    datazoomHandleWidth: "100",
    datazoomLabelColor: "#333",
  };
  function areafunc(index) {
    if (names.length < 4) {
      option.series.lineStyle = { width: 0 };
      return {
        opacity: 0.5,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: option.color[index],
          },
          {
            offset: 1,
            color: "rgb(255, 255, 255,0)",
          },
        ]),
      };
    } else {
      return {
        opacity: 0.0,
      };
    }
  }
  function linefunc(params) {
    if (names.length == 1) {
      return 1;
    } else {
      return 2;
    }
  }
  function linecolorfunc(index) {
    if (names.length == 1) {
      return 1;
    } else {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: option.color[index],
        },
        {
          offset: 1,
          color: "rgb(255, 255, 255,0)",
        },
      ]);
    }
  }
  rawdatas.map((e, index) => {
    option.series.push({
      name: names[index],
      showSymbol: false,
      type: "line",

      smooth: true,
      data: e,
      lineStyle: {
        width: linefunc(),
        // color: linecolorfunc(index),
        opacity: 0.5,
      },
      emphasis: {
        disabled: true,
        focus: "none",
      },
      areaStyle: areafunc(index),
    });
  });
  return (
    <ReactEcharts
      option={option}
      className=" !h-full w-full z-10"
      opts={{ renderer: "canvas" }}
      lazyUpdate={false}
    />
  );
}
