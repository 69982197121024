import React, { useContext, useEffect, useState } from "react";
import Logo from "../assets/suyabakan_logo_svg.svg";
import HomeSvg from "../assets/homesvg.svg";
import DevicesSvg from "../assets/devicessvg.svg";
import { Outlet, NavLink, useNavigate } from "react-router-dom";
import { AccountContext } from "../events/Account";
import SuyabakanLogo from "../assets/suyabakan_logo_svg.svg";
import { useTranslation } from "react-i18next";
function Navbar() {
  const { t, i18n } = useTranslation();
  const [userDropDown, setUserDropDown] = useState(false);
  const [menuDropDown, setMenuDropDown] = useState(false);
  const { getSession, logout, status, setStatus } = useContext(AccountContext);
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [setupActive, setSetupActive] = useState(false);
  const Menus = [
    { name: "Home", icon: "home-outline", dis: "-translate-x-0" },
    { name: "Devices", icon: "thermometer-outline", dis: "translate-x-16" },
    { name: "Setup", icon: "add-outline", dis: "translate-x-32" },
    { name: "Profile", icon: "person-outline", dis: "translate-x-48" },
  ];

  const CloseSideBar = () => {
     

    var navi = document.getElementById("mobilBody");
    var navi2 = document.getElementById("sheetBody");

    


    if (navi.classList.contains("mobilSmallBody")) {
      navi.classList.remove("mobilSmallBody");
    } else {
      return;
    }
    
  };
  return (
    <div className="fixed w-full h-screen bg-gradient-to-br from-[#F5F8FD] to-[#DADFE4] lg:from-[#f8f9fa] lg:to-[#f2f3f5] py-10 pl-5 lg:pl-10">
      <div className=" w-48  h-16  mb-10 " onClick={() => CloseSideBar()}>
        <NavLink to="/" >
          <img className="w-full h-full" src={SuyabakanLogo} />
        </NavLink>
      </div>
      <div className=" h-5/6 w-60  pr-12  overflow-y-auto flex flex-col  justify-between border-none ">

        <div
          onClick={() => CloseSideBar()}
          className="w-full   flex flex-col gap-1 "
        >
          <NavLink
            to="/"
            className={({ isActive }) => {
              return `  w-full ${isActive ? setActive(0) : ""}`;
            }}
          >
            <div
              className={`flex flex-row gap-2  text-center p-2  rounded-lg ${
                0 === active && " bg-white shadow-myShadow1  "
              } `}
              onClick={() => setActive(0)}
            >
              <span
                className={`text-xl rounded-lg w-10 h-10 flex justify-center items-center bg-white cursor-pointer duration-500 text-textDark shadow-miniMap ${
                  0 === active &&
                  " text-white bg-gradient-to-br from-[#62CBF8] to-[#3157F5] shadow-none"
                }`}
              >
                <ion-icon name="home"></ion-icon>
              </span>
              <span
                className={`text-sm duration-700 flex items-center ${
                  active === 0
                    ? " duration-700 opacity-100 text-textDark font-medium  ml-1"
                    : " text-textLight font-normal ml-0"
                } `}
              >
                {t("navHome")}
              </span>
            </div>
          </NavLink>
          <NavLink
            to="/devices"
            className={({ isActive }) => {
              return `  w-full ${isActive ? setActive(1) : ""}`;
            }}
          >
            <div
              className={`flex flex-row gap-2  text-center p-2  rounded-lg ${
                1 === active && " bg-white shadow-myShadow1  "
              } `}
              onClick={() => setActive(1)}
            >
              <span
                className={`text-xl rounded-lg w-10 h-10 flex justify-center items-center bg-white cursor-pointer duration-500 text-textDark shadow-miniMap ${
                  1 === active &&
                  " text-white bg-gradient-to-br from-[#62CBF8] to-[#3157F5] shadow-none"
                }`}
              >
                <ion-icon name="thermometer"></ion-icon>
              </span>
              <span
                className={`text-sm duration-700 flex items-center ${
                  active === 1
                    ? " duration-700 opacity-100 text-textDark font-medium  ml-1"
                    : " text-textLight font-normal ml-0"
                } `}
              >
                {t("navDevices")}
              </span>
            </div>
          </NavLink>
          <NavLink
            to="/setupField"
            className={({ isActive }) => {
              return `  w-full ${isActive ? setActive(2) : ""}`;
            }}
          >
            <div
              className={`flex flex-row gap-2  text-center p-2  rounded-lg ${
                2 === active && " bg-white shadow-myShadow1  "
              } `}
              onClick={() => setActive(2)}
            >
              <span
                className={`text-xl rounded-lg w-10 h-10 flex justify-center items-center bg-white cursor-pointer duration-500 text-textDark shadow-miniMap ${
                  2 === active &&
                  " text-white bg-gradient-to-br from-[#62CBF8] to-[#3157F5] shadow-none"
                }`}
              >
                <ion-icon name="trail-sign"></ion-icon>
              </span>
              <span
                className={`text-sm duration-700 flex items-center ${
                  active === 2
                    ? " duration-700 opacity-100 text-textDark font-medium  ml-1"
                    : " text-textLight font-normal ml-0"
                } `}
              >
                {t("navSetupField")}
              </span>
            </div>
          </NavLink>
          <NavLink
            to="/setupDevice"
            className={({ isActive }) => {
              return `  w-full ${isActive ? setActive(3) : ""}`;
            }}
          >
            <div
              className={`flex flex-row gap-2  text-center p-2  rounded-lg ${
                3 === active && " bg-white shadow-myShadow1  "
              } `}
              onClick={() => setActive(3)}
            >
              <span
                className={`text-xl rounded-lg w-10 h-10 flex justify-center items-center bg-white cursor-pointer duration-500 text-textDark shadow-miniMap ${
                  3 === active &&
                  " text-white bg-gradient-to-br from-[#62CBF8] to-[#3157F5] shadow-none"
                }`}
              >
                <ion-icon name="add-circle"></ion-icon>
              </span>
              <span
                className={`text-sm duration-700 flex items-center ${
                  active === 3
                    ? " duration-700 opacity-100 text-textDark font-medium  ml-1"
                    : " text-textLight font-normal ml-0"
                } `}
              >
                {t("navSetupDevice")}
              </span>
            </div>
          </NavLink>
          <NavLink
            to="/profile"
            className={({ isActive }) => {
              return `  w-full ${isActive ? setActive(4) : ""}`;
            }}
          >
            <div
              className={`flex flex-row gap-2  text-center p-2  rounded-lg ${
                4 === active && " bg-white shadow-myShadow1  "
              } `}
              onClick={() => setActive(4)}
            >
              <span
                className={`text-xl rounded-lg w-10 h-10 flex justify-center items-center bg-white cursor-pointer duration-500 text-textDark shadow-miniMap ${
                  4 === active &&
                  " text-white bg-gradient-to-br from-[#62CBF8] to-[#3157F5] shadow-none"
                }`}
              >
                <ion-icon name="person"></ion-icon>
              </span>
              <span
                className={`text-sm duration-700 flex items-center ${
                  active === 4
                    ? " duration-700 opacity-100 text-textDark font-medium  ml-1"
                    : " text-textLight font-normal ml-0"
                } `}
              >
                {t("navProfile")}
              </span>
            </div>
          </NavLink>
        </div>
        <div 
      onClick={() => CloseSideBar()}
      className="w-full  " >
          <NavLink
            href="#"
            className=" flex flex-row gap-2 items-center py-2 text-sm text-textLight border-b-2  mb-2"
            role="menuitem"
            tabIndex="-1"
            id="user-menu-item-2"
            onClick={() => {
              logout();
              navigate("/login");
            }}
          >
            <span
              className={`text-xl rounded-lg w-10 h-10 flex justify-center items-center  cursor-pointer duration-500 text-textLight
                }`}
            >
              <ion-icon name="log-out-outline"></ion-icon>
            </span>
            <p>{t("navSignOut")}</p>
          </NavLink>
          <p className="text-xs text-textLight mb-3 uppercase ">
            {t("navDocumentation")}
          </p>
          <div>
            <div
              className="flex flex-row gap-2 text-center  "
              onClick={() => setActive(4)}
            >
              <span
                className={`text-xl rounded-lg w-10 h-10 flex justify-center items-center bg-white cursor-pointer duration-500 text-textDark shadow-miniMap `}
              >
                <ion-icon name="rocket"></ion-icon>
              </span>
              <span
                className={`text-sm duration-700 flex items-center text-textLight  `}
              >
                {t("navGettingStarted")}
              </span>
            </div>
          </div>
        </div>
        <div
          // href="https://eru.suyabakan.com"
          // target="_blank"
          className="flex flex-col justify-center items-center w-40 h-20 rounded-md  text-white bg-gradient-to-br from-textDark to-black"
        >
          <p className="text-3xl">&#9759;</p>
          <p className="text-md font-semibold">{t("navUpgradePro")}</p>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
