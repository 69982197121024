import React, { useContext, useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Box, Button, MenuItem, Modal, Select, TextField } from "@mui/material";
import Prepare from "../assets/prepare.svg";
import Irrigate from "../assets/irrigate.svg";
import { AccountContext } from "../events/Account";
import tof from "../assets/tof.svg";
import { useTranslation } from "react-i18next";

function DeviceTofCard({ data }) {
  const {t,i18n}= useTranslation()
  const { apiKey, user_id } = useContext(AccountContext);
  const navigate = useNavigate();
  const [lastData, setLastData] = useState();
  const [tsdata, settsdata] = useState();
  const [loading, setLoading] = useState(true);
  const [vanaStatus, setVanaStatus] = useState(false);
  const [HWNo, seyHWNo] = useState("02");
  const [SancakPacketVersion, setSancakPacketVersion] = useState("001");
  const [SancakPacketVersionOFF, setSancakPacketVersionOFF] = useState("002");
  const [GRDPacketVersion, setGRDPacketVersion] = useState("");
  const [GRDMeasureCount, setGRDMeasureCount] = useState(0);
  const [GRDSleepTimeH, setGRDSleepTimeH] = useState(3);
  const [GRDSleepTimeM, setGRDSleepTimeM] = useState(30);
  const [isCommandModal, setIsCommandModal] = useState(false);
  const [commandLoading, setCommandLoading] = useState(false);
  const [wateringTime, setWateringTime] = useState(false);
  const [connection, setConnection] = useState(3);
  const [newdata, setnewdata] = useState(false);

  // useEffect(() => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("apikey", apiKey);
  //   myHeaders.append("userid", user_id);

  //   var requestOptionsGET = {
  //     method: "GET",
  //     headers: myHeaders,
  //   };
  //   fetch(
  //     `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device?deviceId=${data.id}`,
  //     requestOptionsGET
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log(result);
  //       const resultfilter = result.body;
  //       console.log("VANADATA : ", resultfilter);
  //       setLastData(resultfilter);
  //     })
  //     .then(() =>
  //       fetch(
  //         `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=tof`,
  //         {
  //           method: "GET",
  //         }
  //       )
  //         .then((response) => response.json())
  //         .then((result) => {
  //           console.log(result);
  //           if (result?.body?.[0]?.status) {
  //             var lastd = lastData;
  //             lastd.status = result.body[0].status;
  //             setLastData(lastd);
  //           }
  //           const resultfilter = result.body;
  //           var obj = [];
  //           result.body.map((e) => {
  //             obj.push(JSON.parse(e));
  //           });
  //           settsdata(obj);

  //           // yaz(resultfilter.at);

  //           // console.log('funcyaz: ', yaz(resultfilter.at));
  //           // settsdata(resultfilter);
  //         })
  //         .then(() => setLoading(false))
  //         .catch((err) => {
  //           console.log("DeviceTofCard err :", err);
  //           setLoading(false);
  //         })
  //     )
  //     .catch((err) => {
  //       console.log("lasdatafetcherr : ", err);
  //       setLoading(false);
  //     });

  // }, []);
  useEffect(() => {
    console.log("id :::", data.id);
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
  
    var requestOptionsGET = {
      method: "GET",
      headers: myHeaders,
    };
  
    const fetchData = async () => {
      try {
        // İlk fetch işlemi
        const response1 = await fetch(
          `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/device?deviceId=${data.id}`,
          requestOptionsGET
        )
        const result1 = await response1.json();
        const resultfilter = result1.body;
  
        setLastData(resultfilter);
  
        // İlk fetch işleminin sonucunu kullanarak ikinci fetch işlemi
        const response2 = await fetch(
          `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=tof`,
          {
            method: "GET",
          }
        )
        const result2 = await response2.json();
        if (result2?.body?.[0]?.status) {
          var lastd = lastData;
          lastd.status = result2.body[0].status;
          setLastData(lastd);
        }
        // İkinci fetch işleminin sonucunu işle
        var obj = result2.body.map((e) => JSON.parse(e));
        console.log("tof objilk:", obj);
        settsdata(prev=> obj);
        setLoading(false)
      } catch (error) {
        console.log("tof error", error);
        setLoading(false)
      }
    };
  
    // fetchData fonksiyonunu çağır
    if(connection == 3){
    fetchData();
  }
    // WebSocket bağlantısını oluşturun
    var socket = new WebSocket(`wss://zh906rwmy8.execute-api.eu-west-1.amazonaws.com/production/?user_id=${user_id}&devices=[${data.id}]`);

    // Bağlantı başarıyla kurulduğunda
    socket.addEventListener('open', (event) => {
      console.log('Tof WebSocket bağlantısı başarıyla kuruldu.', event);
    });
    const pongInterval = setInterval(() => {
      socket.send('ping');
    }, 30000); // Her 30 saniyede bir
    // Mesaj alındığında
    socket.addEventListener('message', (event) => {
      setnewdata(prev => true)
      console.log('Tof WebSocketten gelen mesaj:', event);
      var dat = JSON.parse(event.data)
      console.log('Tof dat dat dat 1:', dat);
      var parseddata = {...dat.data, ...dat.data.izci_packet}
      console.log('Tof dat dat dat:', parseddata);
      console.log("Tof tsdata",tsdata)
      
      settsdata(prev => [parseddata,...prev])
      

    });

    // Bağlantı kapandığında
    socket.addEventListener('close', (event) => {
      console.log('Tof WebSocket bağlantısı kapatıldı.', event);
      clearInterval(pongInterval);
      
      setTimeout(() => {
        setConnection(prev => !prev)
      }, 5000);
    });

    // Hata oluştuğunda
    socket.addEventListener('error', (event) => {
      console.error('Tof WebSocket hatası:', event);
    });
    console.log("Tof websocet kapalı triggered",socket.readyState); 
    

  }, [connection]);
  
  function formatDate(date) {
    
    var mdate = new Date(date);
    console.log("date",mdate);
    mdate.setMinutes(mdate.getMinutes());
    // return `       ${mdate.getHours()}:${
    //   mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    // }
    //  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    // `;
    return `${timeDifference(mdate)},       ${mdate.getHours()}:${
        mdate.getMinutes() < 10 ? `0${mdate.getMinutes() }` : mdate.getMinutes()
      }
     
    `;
    
  }
  function timeDifference( previous) {
    var current = new Date().getTime()
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerHour) {
      return Math.round(elapsed/msPerMinute) + ` ${t('minutes')} ${t('ago')}`;   
 }

 else if (elapsed < msPerDay ) {
      return Math.round(elapsed/msPerHour ) + ` ${t('hours')} ${t('ago')}`;   
 }

 else if (elapsed < msPerMonth) {
     return ' ' + Math.round(elapsed/msPerDay) + ` ${t('days')} ${t('ago')}`;   
 }

 else if (elapsed < msPerYear) {
     return ' ' + Math.round(elapsed/msPerMonth) + ` ${t('months')} ${t('ago')}`;   
 }

 else {
     return ' ' + Math.round(elapsed/msPerYear ) + ` ${t('years')} ${t('ago')}`;   
 }
}
  const VanaCommand = (status) => {
    setVanaStatus(status);
  };

  const StatusFunction = () => {
    if (lastData && lastData.status) {
      if (lastData.status == "on") {
        return <p className="text-green-500">ON</p>;
      } else if (lastData.status == "off") {
        return <p className="text-gray-500">OFF</p>;
      } else if (lastData.status == "pending") {
        return <p className="text-blue-500">pending</p>;
      }
    } else {
      return <p>noData</p>;
    }
  };

  const CommandFunctionOpenValve = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var tempCont = Number(GRDMeasureCount).toString(16);
    if (tempCont.length == 1) {
      tempCont = `0${tempCont}`;
    }
    var tempSleep = Number(GRDSleepTimeH * 60 + GRDSleepTimeM).toString(16);

    if (tempSleep.length == 1) {
      tempSleep = `000${tempSleep}`;
    } else if (tempSleep.length == 2) {
      tempSleep = `00${tempSleep}`;
    } else if (tempSleep.length == 3) {
      tempSleep = `0${tempSleep}`;
    }
    var body = {
      o: tsdata?.[0]?.ocak_id,
      s: Number(SancakPacketVersion),
      g: Number(HWNo),
      i: data.id,
      d: `${tempSleep.toUpperCase()}`,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    console.log(body);
    fetch(
      `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/command?id=${data.id}&type=tof`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setCommandLoading(false);
      })
      .then(() => setIsCommandModal(false))
      .catch((error) => console.log("error", error));
  };
  const CommandFunctionCloseValve = () => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var body = {
      o: tsdata?.[0]?.ocak_id,
      s: Number(SancakPacketVersionOFF),
      g: Number(HWNo),
      i: data.id,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
    };
    console.log(body);
    fetch(
      `https://9e3ejjbsuc.execute-api.eu-west-1.amazonaws.com/v1/command?id=${data.id}&type=tof&created_at=${data.created_at}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setCommandLoading(false);
      })
      .then(() => setIsCommandModal(false))
      .catch((error) => console.log("error", error));
  };
  function timestampToDate(timestamp) {
    var saat = new Date(timestamp).getHours();
    var dakika = new Date(timestamp).getMinutes();
    return `${saat}:${dakika <= 9 ? `0${dakika}` : dakika}`;
  }
  return (
    <div className=" min-w-[20rem]  lg:w-1/4  md:w-1/2   max-sm:p-0  max-sm:w-full mb-8">
      {loading ? (
        <div className=" w-full flex max-sm:flex-col flex-row gap-4">
          <div className="w-full ">
          <SkeletonTheme baseColor="#d9d9d9" highlightColor="#fff">
              <Skeleton width={"60%"} height={30} />
              <Skeleton width={"100%"} height={200} />
            </SkeletonTheme>
          </div>
        </div>
      ) : (
        <>
          <div
            className=" w-full h-full flex flex-row flex-wrap bg-white shadow-myShadow1 p-5 rounded-2xl "
            // onClick={() => {
            //   navigate(`${data.id.slice(-4)}`, {
            //     state: { ...data, tsdata },
            //   });
            // }}
          >
            <div className="w-5/12">
              <p className="font-semibold text-blue-400">{t("active")}</p>

              <img src={tof} className="h-16 my-2 " />

              <p className="text-lg font-semibold text-textDark ">
                {data.id.slice(-4)}
              </p>
              <div>
                <p className=" flex flex-col text-sm text-textLight">
                  {t('fieldsLastMeasurement')}
                  <p className=" text-xs">
                    {tsdata?.[0]?.received_date && formatDate(Number(tsdata?.[0]?.received_date))}
                  </p>
                </p>
              </div>
            </div>
            <div className="w-7/12  flex flex-col justify-center  ">
              <p className="w-full text-textLight">{t('tofDepth')}</p>
              <p className="text-3xl font-semibold text-textDark w-full text-center  mb-2">{tsdata?.[0]?.max_dist} cm</p>
              <div className="flex flex-row justify-between text-xs">
                <p className="text-textLight">{tsdata?.[1] ? formatDate( Number(tsdata?.[1]?.received_date) ): ""}</p>
                <p className="text-textDark font-semibold">{tsdata?.[1] ? tsdata?.[1]?.max_dist + " cm" :"" }</p>
              </div>
              <div className="flex flex-row justify-between text-xs">
                <p className="text-textLight">{ tsdata?.[2] ? formatDate(Number(tsdata?.[2]?.received_date)) : ""}</p>
                <p className="text-textDark font-semibold">{tsdata?.[2] ? tsdata?.[2]?.max_dist + " cm" : ""} </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default DeviceTofCard;
