import React, { useContext, useState, useEffect } from "react";
import Fields from "../components/Fields";
import People from "../assets/people.jpg";
import Stars from "../assets/stars.png";
import DeviceStatus from "../components/DeviceStatus";
import { AccountContext } from "../events/Account";
import { AppContext } from "./Layout";
import Carousel from "../components/Carousel";
import SuyabakanLogo from "../assets/suyabakan_logo_svg.svg";
import Blop from "../assets/blop-pink.svg";
import FieldDemo from "../assets/field-demo.png";
import VanaSvg from "../assets/fm-valve.svg";
import Izciler from "../assets/fm-izci.svg";
import alertMapOver from "../assets/alertMapOver.svg";
import alertLowBat from "../assets/alertLowBat.png";
import alertNoConnection from "../assets/ alertNoConnection.png";
import alertVana from "../assets/alertVana.png";

import { MapContainer, Polygon, TileLayer } from "react-leaflet";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Farms from "../components/Farms";
import DeviceOcakCard from "../components/DeviceOcakCard2Home";
import WeatherCard from "../components/WeatherCard/WeatherCard";
import HomeVanaCard from "../components/HomeVanaCard";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import OcakPanel from "../assets/ocakpanel.png";

function Home() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  console.log("wnavigator", window.navigator);
  const { apiKey, user_id } = useContext(AccountContext);
  const {
    userApp,
    setUserApp,
    farmsApp,
    setFarmsApp,
    userOcakApp,
    setUserOcakApp,
    userVanaApp,
    setUserVanaApp,
    farmNameApp,
    setFarmNameApp,
    userIzciApp,
    setUserIzciApp,
  } = useContext(AppContext);

  //if app context values are not empty assign them all to local states

  const [user, setUser] = useState("");
  const [farms, setFarms] = useState([]);
  const [userOcak, setUserOcak] = useState();
  const [userVana, setUserVana] = useState();
  const [userIzci, setUserIzci] = useState();
  const [farmName, setFarmName] = useState();
  const [loading, setLoading] = useState(true);

  var myHeaders = new Headers();
  myHeaders.append("apikey", apiKey);
  myHeaders.append("userid", user_id);

  var requestOptionsGET = {
    method: "GET",
    headers: myHeaders,
  };
  useEffect(() => {
    var allfield = [];
    //if app context is empty, fetch user data
    if (userApp == "") {
      console.log("fetching user data");

      fetch(
        `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
        requestOptionsGET
      )
        .then((response) => response.json())
        .then((result) => {
          // alert(JSON.stringify(result))
          console.log("result.body", result.body);

          setUser((prev) => result.body);
          setUserApp((prev) => result.body);
          allfield =
            result.body.fields &&
            result.body.fields.filter((field) => field.deleted_at == undefined);
          GetUserFarm(allfield);
        });
    } else if (userApp != "") {
      console.log("userApp", userApp);
      console.log("farmsApp", farmsApp);
      console.log("userOcakApp", userOcakApp);
      console.log("userVanaApp", userVanaApp);
      console.log("farmNameApp", farmNameApp);

      setUser((prev) => userApp);
      setFarms((prev) => farmsApp);
      setFarmName((prev) => farmNameApp);
      userOcakApp && setUserOcak((prev) => userOcakApp);
      userVanaApp && setUserVana((prev) => userVanaApp);
      userIzciApp && setUserIzci((prev) => userIzciApp);
      setLoading((prev) => false);
    }
  }, []);

  const GetUserFarm = (allfield) => {
    const Farms = {};

    // Veriyi "farmName" özelliğine göre grupla
    allfield?.forEach((item) => {
      if (item.farmName) {
        setFarmName((prev) => item.farmName);
        setFarmNameApp((prev) => item.farmName);
        if (!Farms[item.farmName]) {
          Farms[item.farmName] = [item];
        } else {
          Farms[item.farmName].push(item);
        }
      }
    });
    console.log("Farms", Farms);
    setFarms((prev) => Farms);
    setFarmsApp((prev) => Farms);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("fetching user devices");

        const myHeaders = new Headers();
        myHeaders.append("apikey", apiKey);
        myHeaders.append("userid", user_id);

        const requestOptionsGET = {
          method: "GET",
          headers: myHeaders,
        };
        const userOcakDeviceResponse = await fetch(
          `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/userOcakDevice`,
          requestOptionsGET
        );
        var userOcakDeviceData = await userOcakDeviceResponse.json();
        userOcakDeviceData = userOcakDeviceData.body.filter(
          (obj) =>
            obj.user_deleted_at == undefined || obj.user_deleted_at == false
        );
        setUserOcak((prev) => userOcakDeviceData);
        setUserOcakApp((prev) => userOcakDeviceData);

        var userIzciDeviceResponse = await fetch(
          `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/userIzciDevice`,
          requestOptionsGET
        );
        var userIzciDeviceDataAll = await userIzciDeviceResponse.json();
        console.log("userIzciDeviceDataAll", userIzciDeviceDataAll);
        var userIzciDeviceData = userIzciDeviceDataAll.body.filter(
          (obj) =>
            obj.type == "izci" &&
            (obj.user_deleted_at == undefined || obj.user_deleted_at == false)
        );
        console.log("userIzciDeviceData", userIzciDeviceData);
        setUserIzci((prev) => userIzciDeviceData);
        setUserIzciApp((prev) => userIzciDeviceData);

        var userVanaDeviceData = userIzciDeviceDataAll.body.filter(
          (obj) =>
            obj.type == "vana" &&
            (obj.user_deleted_at == undefined || obj.user_deleted_at == false)
        );
        console.log("userVanaDeviceData", userVanaDeviceData);
        setUserVana((prev) => userVanaDeviceData);
        setUserVanaApp((prev) => userVanaDeviceData);

        setLoading((prev) => false);
      } catch (error) {
        console.log("error", error);
        setLoading((prev) => false);
        // Hata durumunda kullanıcıya bilgilendirme yapılabilir
      }
    };
    // if appcontext is empty, fetch user data
    if (!userOcakApp || !userVanaApp || !userIzciApp) {
      fetchData();
    } else {
      setLoading((prev) => false);
    }
  }, [apiKey, user_id]);

  return (
    <div className="max-sm:mt-4  mx-auto  sm:px-6 max-sm:p-5  max-sm:justify-center max-sm:mb-20  lg:px-8 lg:pt-8 lg:pb-6 md:pt-16 ">
      <div className="w-full flex flex-row max-sm:flex-col ">
        <div className="w-6/12 max-sm:w-full  max-sm:h-auto max-sm:min-h-[20rem] min-h-[17.5rem] bg-bgoverview14   max-sm:bg-bgoverview14    bg-right-top rounded-2xl lg:mb-0">
          <div className="flex max-sm:flex   pt-6 px-5  flex-row justify-between    scroll w-full  z-50 ">
            <div className="flex flex-row">
              <div className="w-14 h-14 rounded-md bg-orange-400 mr-3 shadow-miniMap">
                <img src={People} className="w-full h-full rounded-md " />
              </div>
              <div className="flex flex-col ">
                <p className="text-white text-sm  flex flex-row gap-1">
                  {t("homeWelcome")} <img src={Stars} className="w-4 h-4 " />
                </p>
                <p className="text-white font-semibold text-base">
                  {user ? user.name : ""}
                </p>
              </div>
            </div>
            <p className="text-white text-xl">
              <ion-icon name="notifications-outline"></ion-icon>
            </p>
          </div>
          <p className=" w-full text-center text-white pt-16 ">
            {t("homeMonitor")}
          </p>
        </div>
        <div className=" flex flex-col ml-6 w-96 h-full  max-sm:w-11/12 max-sm:mx-auto max-sm:-mt-16 max-sm:z-50">
          {loading ? (
            <div className="w-full ">
              <div className=" w-full h-32 flex  flex-row gap-4">
                <div className="w-full h-32">
                  <SkeletonTheme baseColor="#ededed" highlightColor="#f8f8f8">
                    <Skeleton width={"100%"} height={"100%"} />
                  </SkeletonTheme>
                </div>
              </div>
            </div>
          ) : userOcak?.[0] ? (
            <div className="bg-white  shadow-myShadow1 rounded-xl p-0 m-0">
              <DeviceOcakCard key={userOcak?.[0].id} data={userOcak?.[0]} />{" "}
            </div>
          ) : (
            <div className="bg-white h-32 w-full shadow-myShadow1 rounded-xl p-0 m-0 flex flex-row items-center justify-start px-4 ">
              <img src={OcakPanel} className=" h-16 shadow-miniMap mr-8" />
              <div className="h-20 w-[1px] bg-gray-300" />
              <div className="flex flex-col justify-center items-center w-full">
                <p className="text-sm text-textLight">No installed station</p>
                <Link
                  to="/setupDevice"
                  className="text-xs font-semibold p-3 bg-blue-500 text-white mt-2 rounded-md"
                >
                  Setup New Device
                </Link>
              </div>
            </div>
          )}
          {loading ? (
            <div className="w-full max-sm:hidden mt-6">
              <div className=" w-full h-32 flex max-sm:flex-col flex-row gap-4">
                <div className="w-full h-32">
                  <SkeletonTheme baseColor="#ededed" highlightColor="#f8f8f8">
                    <Skeleton width={"100%"} height={"100%"} />
                  </SkeletonTheme>
                </div>
              </div>
            </div>
          ) : (
            <>
              {farms && (
                <div className="bg-white shadow-myShadow1 rounded-xl mt-6 max-sm:hidden">
                  <WeatherCard
                    lat={farms[farmName]?.[0]?.center?.lat}
                    lon={farms[farmName]?.[0]?.center?.lng}
                    lang={i18n.language}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div className="max-sm:hidden  ml-6">
          {loading ? (
            <div className="w-44 h-full ">
              <div className=" w-full h-full flex max-sm:flex-col flex-row gap-4">
                <div className="w-full h-full">
                  <SkeletonTheme baseColor="#ededed" highlightColor="#f8f8f8">
                    <Skeleton width={"100%"} height={"100%"} />
                  </SkeletonTheme>
                </div>
              </div>
            </div>
          ) : (
            <>{userVana && <HomeVanaCard data={userVana} />}</>
          )}
        </div>
      </div>
      <div className="w-full flex flex-row max-sm:flex-col">
        {loading ? (
          <div
            className={`pt-6 w-1/3 max-sm:w-full max-sm:whitespace-normal whitespace-nowrap mr-6 max-sm:bg-none max-sm:px-0 max-sm:mt-0 max-sm:rounded-none bg-white max-sm:bg-transparent h-fit pb-12 max-sm:h-auto max-sm:pb-0 max-sm:shadow-none  px-6 mt-6 rounded-xl shadow-myShadow1`}
          >
            <p className="text-base text-textDark font-semibold ">
              {t("farms")}
            </p>
            <div id="bigselam">
              <p className="text-textLight text-xs selam" id="selam">
                {t("monitor")}
              </p>
            </div>
            <div className="w-full h-32">
              <SkeletonTheme baseColor="#ededed" highlightColor="#f8f8f8">
                <Skeleton width={"100%"} height={"100%"} />
              </SkeletonTheme>
            </div>
          </div>
        ) : (
          <div className="relative z-20 w-1/3 max-sm:w-full max-sm:whitespace-normal whitespace-nowrap mr-6 max-sm:bg-none max-sm:px-0 max-sm:mt-0 max-sm:rounded-none bg-white max-sm:bg-transparent h-fit pb-12 max-sm:h-auto max-sm:pb-0 max-sm:shadow-none  px-6 mt-6 rounded-xl shadow-myShadow1">
            <Farms farms={farms} />
          </div>
        )}
        {/* <Farms farms={farms} /> */}
        <div className="relative z-10 w-2/3 max-sm:w-full max-sm:whitespace-normal  max-sm:bg-none max-sm:px-0 max-sm:mt-0 max-sm:rounded-none bg-white  pl-6 mt-6 rounded-xl  shadow-myShadow1 max-sm:shadow-none  max-sm:bg-transparent pb-6">
          <Fields />
        </div>
      </div>
    </div>
  );
}

export default Home;
